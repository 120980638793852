import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface ILoaderSvgProps {
    color: string;
    height: string;
    strokeWidth: string;
    width: string;
}

export const LoaderSvg = (props: ILoaderSvgProps) =>
    React.createElement(
        'svg',
        {
            width: props.width,
            height: props.height,
            xmlns: 'http://www.w3.org/2000/svg',
            viewBox: '0 0 100 100',
            preserveAspectRatio: 'xMidYMid',
            className: 'lds-rolling',
            'data-testid': 'loader',
            style: { background: 'none' }
        },
        React.createElement(
            'circle',
            {
                cx: '50',
                cy: '50',
                fill: 'none',
                stroke: props.color,
                strokeWidth: props.strokeWidth,
                r: '16',
                strokeDasharray: '75.39822368615503 27.132741228718345',
                transform: 'rotate(120 50 50)'
            },
            React.createElement('animateTransform', {
                attributeName: 'transform',
                type: 'rotate',
                calcMode: 'linear',
                values: '0 50 50;360 50 50',
                keyTimes: '0;1',
                dur: '1s',
                begin: '0s',
                repeatCount: 'indefinite'
            })
        )
    );

export const Loader = () => (
    <Flex align="center" height={1} justify="center" width={1}>
        <LoaderSvg color="#162131" height="200px" strokeWidth="2" width="200px" />
    </Flex>
);
