import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { useTabsContext } from './tabsContext';

const _Tab = styled('div', {
    alignItems: 'center',
    color: '$gray500',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: '$medium',
    height: '100%',
    justifyContent: 'center',
    userSelect: 'none',
    width: '100%',
    variants: {
        selected: {
            true: {
                background: 'white',
                borderRadius: '6px',
                boxShadow: '$sm',
                color: '$gray700'
            }
        }
    }
});

interface ITabProps {
    children: React.ReactNode;
    item: string;
}

export const Tab = (props: ITabProps) => {
    const { selectedItem, onChange } = useTabsContext();

    return (
        <_Tab
            selected={selectedItem === props.item}
            onClick={() => {
                onChange(props.item);
            }}
        >
            {props.children}
        </_Tab>
    );
};
