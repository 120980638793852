import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    FormParametersBroadcastInput,
    FormParametersBroadcastOrganizationQuery
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IUpdateFormParametersBroadcastValues {
    form: FormParametersBroadcastInput;
}

@injectable()
export class FormParametersBroadcastInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    formParametersBroadcastInputDefault(
        form: FormParametersBroadcastOrganizationQuery['organization']['form']
    ): FormParametersBroadcastInput {
        return {
            segmentsIds: form.segments.map((s) => s.segmentId),
            noAccessMessage: form.noAccessMessage || ''
        };
    }

    formParametersBroadcastInputSchema() {
        return yup.object().shape({
            segmentsIds: yup.array().of(yup.number())
        });
    }

    updateFormParametersBroadcastSchema() {
        return yup.object().shape({
            form: this.formParametersBroadcastInputSchema()
        });
    }
}
