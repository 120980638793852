import { AccreditationsSlotFragment } from 'common/src/generated/types';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { AccreditationCategory } from '../v2/accreditationCategory';
import { AccreditationsRightPanelAccreditation } from './accreditationsRightPanelAccreditation';
import {
    AccreditationState,
    useAccreditationsRightPanelContext
} from './accreditationsRightPanelContext';

interface IAccreditationsRightPanelCategoryProps {
    category: AccreditationsSlotFragment['accreditationCategory'];
    state: AccreditationState;
}

export const AccreditationsRightPanelCategory = (props: IAccreditationsRightPanelCategoryProps) => {
    const { assignedAccreditations, wishedAccreditations } = useAccreditationsRightPanelContext();
    const accreditations = React.useMemo(
        () =>
            sortBy(
                (props.state === 'assigned' ? assignedAccreditations : wishedAccreditations).filter(
                    (a) => a.accreditationCategoryId === props.category.id
                ),
                (a) => a.name.toLowerCase()
            ),
        [assignedAccreditations, wishedAccreditations, props.category, props.state]
    );

    return (
        <AccreditationCategory category={props.category}>
            {accreditations.map((accreditation) => (
                <AccreditationsRightPanelAccreditation
                    key={accreditation.id}
                    accreditation={accreditation}
                    state={props.state}
                />
            ))}
        </AccreditationCategory>
    );
};
