import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Item } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu as DropdownMenu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Avatar } from 'common/src/components/avatar/avatar';
import { DelegationsPaths } from 'common/src/delegationsPaths';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    MemberRegistrationUserInfoFragment,
    OrganizationDelegationsSpaceFragment,
    OrganizationMembersSpaceFragment
} from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface ISpaceHeaderProps {
    extraItems?: React.ReactNode;
    logoSrc: string | undefined;
    organization?: OrganizationMembersSpaceFragment | OrganizationDelegationsSpaceFragment;
    userInfo?: MemberRegistrationUserInfoFragment;
}

export const SpaceHeader = ({ extraItems, logoSrc, userInfo }: ISpaceHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const isMobile = useMobileQuery();
    const isDelegation = location.pathname.includes('delegation');

    return (
        <Flex
            align="center"
            justify="between"
            height={64}
            width={1}
            css={{
                color: 'white',
                px: '$6',
                position: 'relative',
                borderBottom: '1px solid $gray200'
            }}
        >
            <Flex
                align="center"
                height={1}
                css={{
                    cursor: isDelegation && logoSrc ? 'pointer' : 'default',
                    margin: isMobile ? 'auto' : 'inherit',
                    '& svg': { height: '50%' }
                }}
                onClick={() => {
                    if (isDelegation && logoSrc) {
                        history.push(DelegationsPaths.DELEGATIONS_LIST(organizationId));
                    }
                }}
            >
                {logoSrc && <img height="100%" width="auto" src={logoSrc} />}
            </Flex>

            {!isMobile && (
                <Dropdown
                    aria-label={translate('menu_utilisateu_40033')}
                    aria-orientation="vertical"
                    isOpen={isDropdownOpen}
                    onStateChange={setIsDropdownOpen}
                >
                    <Trigger>
                        <Flex
                            aria-expanded={isDropdownOpen}
                            aria-label={translate('ouvrir_le_menu_47803')}
                            align="center"
                            justify="end"
                            gap="3"
                            width={300}
                            css={{
                                fontWeight: '$medium',
                                color: '$gray800',
                                fontSize: '$textSm',
                                cursor: 'pointer'
                            }}
                            onClick={() => document.getElementById('user-menu')?.focus()}
                        >
                            <Avatar
                                email={userInfo?.email}
                                name={userInfo?.name}
                                image={userInfo?.picture?.url}
                                size={32}
                            />

                            <Box
                                css={{
                                    maxWidth: 'calc(100% - 66px)'
                                }}
                            >
                                <Box css={{ ellipsis: true }}>
                                    {userInfo?.name || userInfo?.email}
                                </Box>
                            </Box>

                            <div>
                                {isDropdownOpen ? (
                                    <i className="far fa-angle-up" />
                                ) : (
                                    <i className="far fa-angle-down" />
                                )}
                            </div>
                        </Flex>
                    </Trigger>

                    <DropdownMenu
                        id="user-menu"
                        aria-orientation="vertical"
                        placement="bottom-end"
                        width={300}
                        maxHeight={500}
                    >
                        <Item>
                            <Flex align="center" gap="3" css={{ cursor: 'default' }} width={1}>
                                <Avatar
                                    email={userInfo?.email}
                                    name={userInfo?.name}
                                    image={userInfo?.picture?.url}
                                    size={32}
                                />

                                <Box
                                    css={{
                                        width: 'calc(100% - 44px)'
                                    }}
                                >
                                    <Box fontWeight="semiBold" css={{ ellipsis: true }}>
                                        {userInfo?.name || userInfo?.email}
                                    </Box>
                                    {isNonEmptyString(userInfo?.name) && (
                                        <Box color="gray500" css={{ ellipsis: true }}>
                                            {userInfo?.email}
                                        </Box>
                                    )}
                                </Box>
                            </Flex>
                        </Item>

                        <Separator direction="horizontal" color="gray100" />

                        {extraItems}

                        <Item
                            color="red"
                            onClick={() => {
                                signOut();
                            }}
                        >
                            {translate('se_d_connecter_74151')}
                        </Item>
                    </DropdownMenu>
                </Dropdown>
            )}
        </Flex>
    );
};
