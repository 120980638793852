import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../util/isInvalid';
import { IFormInputProps } from '../components/input/commonInputProps';
import { ITextareaProps, Textarea as TextareaComponent } from '../components/textarea';

export const Textarea = ({ name, hint, state, ...rest }: IFormInputProps<ITextareaProps>) => (
    <Field
        name={name}
        render={(fieldProps) => {
            const invalid = isInvalid(fieldProps.meta);

            return (
                <TextareaComponent
                    hint={invalid ? fieldProps.meta.error : hint}
                    name={name}
                    state={invalid ? 'error' : state}
                    value={fieldProps.input.value}
                    onBlur={fieldProps.input.onBlur}
                    onChange={(value) => {
                        fieldProps.input.onChange(value);
                    }}
                    onFocus={fieldProps.input.onFocus}
                    {...rest}
                />
            );
        }}
    />
);
