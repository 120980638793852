import { compact, uniq } from 'lodash-es';
import { DateTime, Duration, Interval } from 'luxon';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const DEV = false;

const exploit = Interval.after(
    DateTime.fromObject({ day: 23, month: 8, year: 2024 }),
    Duration.fromObject({ day: 3 })
);
const midi = (day: number) =>
    Interval.fromDateTimes(
        DateTime.fromObject({ day, month: 8, year: 2024, hour: 7 }),
        DateTime.fromObject({ day, month: 8, year: 2024, hour: day === 25 ? 15 : 16 })
    );
const soir = (day: number) =>
    Interval.fromDateTimes(
        DateTime.fromObject({ day, month: 8, year: 2024, hour: day === 25 ? 15 : 16 }),
        DateTime.fromObject({ day: day + 1, month: 8, year: 2024, hour: 4 })
    );
const day = (day: number) =>
    Interval.after(
        DateTime.fromObject({ day, month: 8, year: 2024 }),
        Duration.fromObject({ day: 1 })
    );
const devInterval = (dStart: number, dEnd: number, hStart: number, hEnd: number) =>
    Interval.fromDateTimes(
        DateTime.fromObject({ day: dStart, month: 8, year: 2024, hour: hStart }),
        DateTime.fromObject({ day: dEnd, month: 8, year: 2024, hour: hEnd })
    );

type Psui =
    DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'][number];

export const ContremarqueVandb = ({ event }: { event: DocumentUserCustomBadgeFragment }) => {
    const vr = event.volunteerRegistration;
    const ui = vr.userInfo;
    let population = ui.fields?.cf22493?.value ?? '';
    let tshirtSize = ui.fields?.cf26370?.value ?? '';

    if (DEV) {
        vr.positionsSlotsUsersInfos = [
            {
                positionCategory: { name: `BOUTIQUE MERCH'` },
                positionSlot: { range: devInterval(24, 25, 17, 3) }
            } as Psui,
            {
                positionCategory: { name: `BOUTIQUE MERCH'` },
                positionSlot: { range: devInterval(25, 25, 8, 11) }
            } as Psui,
            {
                positionCategory: { name: `BRACELETAGE - CONTRÔLE d'ACCES` },
                positionSlot: { range: devInterval(23, 23, 17, 18) }
            } as Psui
        ];
        vr.weezeventParticipantInfo = { ...vr.weezeventParticipantInfo, idBarcode: '872D687F9D25' };
        tshirtSize = 'L';
        population = 'Bénévoles';
    }
    const psuis = vr.positionsSlotsUsersInfos.filter((psui) =>
        psui.positionSlot.range.overlaps(exploit)
    );
    const categories = uniq(psuis.map((psui) => psui.positionCategory.name)).join(', ');
    const days = psuis.reduce(
        ([day1, day2, day3], psui) => [
            day1 || psui.positionSlot.range.overlaps(day(23)),
            day2 || psui.positionSlot.range.overlaps(day(24)),
            day3 || psui.positionSlot.range.overlaps(day(25))
        ],
        [false, false, false]
    );
    const nonExploitDays = compact(
        uniq(
            vr.positionsSlotsUsersInfos.flatMap((psui) => [
                psui.positionSlot.range.start?.day,
                psui.positionSlot.range.end?.day
            ])
        )
    );
    let tshirts = 0;
    if (nonExploitDays.length > 2) {
        tshirts = 2;
    } else if (nonExploitDays.length > 0) {
        tshirts = 1;
    }
    const exo = days.reduce((acc, day) => {
        if (day) {
            return acc + 12;
        } else {
            return acc;
        }
    }, 0);
    const catering = psuis.reduce(
        ([accMidi, accSoir], psui) => {
            const range = psui.positionSlot.range;

            return [
                [
                    accMidi[0] || range.overlaps(midi(23)),
                    accMidi[1] || range.overlaps(midi(24)),
                    accMidi[2] || range.overlaps(midi(25))
                ],
                [
                    accSoir[0] || range.overlaps(soir(23)),
                    accSoir[1] || range.overlaps(soir(24)),
                    accSoir[2] || range.overlaps(soir(25))
                ]
            ];
        },
        [
            [false, false, false],
            [false, false, false]
        ]
    );

    return (
        <>
            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/vandb/Contremarque_1.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    color: '#103725',
                    fontFamily: '$firaSans',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Flex
                    css={{
                        gap: '10px',
                        justifyContent: 'space-between',
                        left: '109px',
                        position: 'absolute',
                        top: '181px',
                        right: '109px'
                    }}
                >
                    <Flex css={{ flexDirection: 'column', gap: '6px' }}>
                        <Box
                            css={{
                                fontFamily: '$badTyp',
                                fontSize: '22px',
                                fontWeight: 500,
                                lineHeight: '28px',
                                textTransform: 'uppercase'
                            }}
                        >
                            {ui.name}
                        </Box>
                        <Box
                            css={{
                                fontSize: '16px',
                                lineHeight: 'normal'
                            }}
                        >
                            <span style={{ fontWeight: 700 }}>{population}</span>
                            {` | ${categories}`}
                        </Box>
                        <Box
                            css={{
                                fontSize: '16px',
                                lineHeight: 'normal'
                            }}
                        >
                            <span style={{ fontWeight: 700 }}>T-shirt :</span>
                            {` ${tshirts} en taille ${tshirtSize || ''}`}
                        </Box>
                        <Box
                            css={{
                                fontSize: '16px',
                                lineHeight: 'normal'
                            }}
                        >
                            <span style={{ fontWeight: 700 }}>Montant EXO :</span>
                            {` ${exo}`}
                        </Box>
                    </Flex>
                    <Flex css={{ alignItems: 'flex-end', flexDirection: 'column', gap: '4px' }}>
                        <Box height={80} width={80}>
                            {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                                <img
                                    height="100%"
                                    src={`https://api.${
                                        CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                    }/barcode/qr?text=${encodeURIComponent(
                                        vr.weezeventParticipantInfo!.idBarcode
                                    )}&color=000000&height=80&width=80`}
                                    width="100%"
                                />
                            )}
                        </Box>
                        <Box css={{ fontSize: '10px' }}>
                            {vr.weezeventParticipantInfo?.idBarcode ?? ''}
                        </Box>
                    </Flex>
                </Flex>
                {catering[0][0] && (
                    <Flex
                        css={{
                            height: '32px',
                            justifyContent: 'center',
                            left: '265px',
                            padding: '4px 10px',
                            position: 'absolute',
                            top: '809px',
                            width: '138px'
                        }}
                    >
                        <img
                            height="100%"
                            src={`https://assets.recrewteer.com/badges/vandb/catering.png`}
                        />
                    </Flex>
                )}
                {catering[0][1] && (
                    <Flex
                        css={{
                            height: '32px',
                            justifyContent: 'center',
                            left: '407px',
                            padding: '4px 10px',
                            position: 'absolute',
                            top: '809px',
                            width: '138px'
                        }}
                    >
                        <img
                            height="100%"
                            src={`https://assets.recrewteer.com/badges/vandb/catering.png`}
                        />
                    </Flex>
                )}
                {catering[0][2] && (
                    <Flex
                        css={{
                            height: '32px',
                            justifyContent: 'center',
                            left: '549px',
                            padding: '4px 10px',
                            position: 'absolute',
                            top: '809px',
                            width: '138px'
                        }}
                    >
                        <img
                            height="100%"
                            src={`https://assets.recrewteer.com/badges/vandb/catering.png`}
                        />
                    </Flex>
                )}
                {catering[1][0] && (
                    <Flex
                        css={{
                            height: '32px',
                            justifyContent: 'center',
                            left: '265px',
                            padding: '4px 10px',
                            position: 'absolute',
                            top: '845px',
                            width: '138px'
                        }}
                    >
                        <img
                            height="100%"
                            src={`https://assets.recrewteer.com/badges/vandb/catering.png`}
                        />
                    </Flex>
                )}
                {catering[1][1] && (
                    <Flex
                        css={{
                            height: '32px',
                            justifyContent: 'center',
                            left: '407px',
                            padding: '4px 10px',
                            position: 'absolute',
                            top: '845px',
                            width: '138px'
                        }}
                    >
                        <img
                            height="100%"
                            src={`https://assets.recrewteer.com/badges/vandb/catering.png`}
                        />
                    </Flex>
                )}
                {catering[1][2] && (
                    <Flex
                        css={{
                            height: '32px',
                            justifyContent: 'center',
                            left: '549px',
                            padding: '4px 10px',
                            position: 'absolute',
                            top: '845px',
                            width: '138px'
                        }}
                    >
                        <img
                            height="100%"
                            src={`https://assets.recrewteer.com/badges/vandb/catering.png`}
                        />
                    </Flex>
                )}
            </Flex>
            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/vandb/Contremarque_2.jpg) no-repeat`,
                    backgroundSize: 'cover'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            />
        </>
    );
};
