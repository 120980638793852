import { Flex, SectioningElement } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface ILeftMenuProps {
    children: React.ReactNode;
}

export const LeftMenu = (props: ILeftMenuProps) => (
    <Flex
        aria-orientation="vertical"
        as={SectioningElement.Nav}
        direction="column"
        gap="2"
        role="menubar"
        width={1}
    >
        {props.children}
    </Flex>
);
