import { CommonEnvVars } from 'common/src/envVars';
import { NodeEnv } from 'common/src/nodeEnv';

export const DOMAIN = window.location.hostname.split('.').slice(1).join('.');

export const API_BASE_URL = `${window.location.protocol}//api.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}/`;

const read = <T = string>(name: string): T => {
    const envVar =
        process.env.NODE_ENV !== NodeEnv.Test
            ? (window as any)[name]
            : (window as any).process.env[name];

    if (envVar !== undefined) {
        return envVar;
    } else {
        throw new Error(`Missing env. var. ${name}`);
    }
};

export const AWS_UPLOADS_BUCKET = read('HEAVENT_AWS_UPLOADS_BUCKET');
export const COGNITO_USER_POOL_ID = read('HEAVENT_COGNITO_USER_POOL_ID');
export const COGNITO_USER_POOL_CLIENT_ID = read('HEAVENT_COGNITO_USER_POOL_CLIENT_ID');
export const COGNITO_DOMAIN_URL = read('HEAVENT_COGNITO_DOMAIN_URL');
export const COGNITO_CLIENT_CALLBACK_URL = read('HEAVENT_COGNITO_CLIENT_CALLBACK_URL');
export const USE_HUBSPOT = read<boolean>('HEAVENT_USE_HUBSPOT');
