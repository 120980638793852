import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { EventInput, EventUpdateInfosQuery, MapLayer, OrganizationInput } from '../generated/types';
import { CountriesService } from '../services/countriesService';
import { TranslationService } from '../services/translationService';
import { WithTranslationService } from '../services/withTranslationService';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';
import { USABLE_LANGUAGES } from '../vo/supportedLanguage';
import { TimeZones } from '../vo/timeZone';
import { OrganizationInputService } from './organizationInput';

export interface ICreateEventOrganizationValues {
    event: EventInput;
    organization: OrganizationInput;
}

export interface ICreateUpdateEventValues {
    event: EventInput;
}

@injectable()
export class EventInputService extends WithTranslationService {
    constructor(
        private countriesService: CountriesService,
        private organizationInputService: OrganizationInputService,
        protected translationService: TranslationService
    ) {
        super(translationService);
    }

    eventInputDefault(event?: EventUpdateInfosQuery['event']): EventInput {
        const countryValues = this.countriesService.getDefaultCountryValues();

        return {
            name: event?.name ?? '',
            startAt: event?.startAt ?? DateTime.invalid('Invalid'),
            endAt: event?.endAt ?? DateTime.invalid('Invalid'),
            languages: event?.languages ?? [countryValues.user.language],
            timeZone: event?.timeZone ?? DateTime.local().zoneName!,
            country: event?.country ?? countryValues.user.country,
            layer: event?.layer ?? MapLayer.Google,
            latitude: event?.latitude ?? 43.6087733,
            longitude: event?.longitude ?? 3.87963579999996,
            emailSenderId: event?.emailSenderId,
            dayStartTime: event?.dayStartTime ?? '00:00',
            tagsIds: event?.tagsIds ?? []
        };
    }

    eventInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.t('nom_de_l_v_nem_58512')),
            startAt: DateTimeSchemaType.required(this.t('la_date_d_but_d_30022')).typeError(
                this.t('la_date_d_but_d_30022')
            ),
            endAt: DateTimeSchemaType.required(this.t('la_date_de_fin_45068'))
                .typeError(this.t('la_date_de_fin_45068'))
                .test('after-start-at', this.t('la_date_de_fin_00429'), function () {
                    return (
                        DateTime.isDateTime(this.parent.startAt) &&
                        this.parent.startAt.isValid &&
                        DateTime.isDateTime(this.parent.endAt) &&
                        this.parent.endAt.isValid &&
                        this.parent.endAt >= this.parent.startAt
                    );
                }),
            languages: yup
                .array()
                .of(yup.string().oneOf(USABLE_LANGUAGES, this.t('les_langues_doi_27712')))
                .required(this.t('les_langues_son_53717'))
                .min(1, this.t('vous_devez_s_le_16610')),
            timeZone: yup
                .string()
                .required(this.t('le_fuseau_horai_37109'))
                .oneOf(TimeZones, this.t('le_fuseau_horai_37109')),
            latitude: yup
                .number()
                .nullable()
                .typeError(this.t('la_latitude_doi_17800'))
                .min(-90, this.t('la_latitude_doi_17800'))
                .max(90, this.t('la_latitude_doi_17800')),
            longitude: yup
                .number()
                .nullable()
                .typeError(this.t('la_longitude_do_28762'))
                .min(-180, this.t('la_longitude_do_28762'))
                .max(180, this.t('la_longitude_do_28762')),
            emailSenderId: yup.number().nullable(),
            dayStartTime: yup.string().required(this.t('l_heure_laque_87748'))
        });
    }

    eventOrganizationInputSchema(termsAccepted: boolean) {
        return yup.object().shape({
            event: this.eventInputSchema(),
            organization: this.organizationInputService.organizationInputSchema(termsAccepted)
        });
    }

    createUpdateEventSchema() {
        return yup.object().shape({
            event: this.eventInputSchema()
        });
    }
}
