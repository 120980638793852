import { OrganizationIdPathParam } from '../pathsTypes';
import { buildPath } from './commonPaths';
import { OrganizationsPaths } from './organizationsPaths';

const PathComponents = {
    COMMUNITY: 'community',
    SECTIONS: {
        DASHBOARD: 'dashboard',
        USERS: 'users'
    }
};

export const CommunityPaths = {
    COMMUNITY: (organizationId: OrganizationIdPathParam) =>
        buildPath([OrganizationsPaths.ORGANIZATION(organizationId), PathComponents.COMMUNITY]),
    COMMUNITY_DASHBOARD: (organizationId: OrganizationIdPathParam) =>
        buildPath([CommunityPaths.COMMUNITY(organizationId), PathComponents.SECTIONS.DASHBOARD]),
    COMMUNITY_USERS: (organizationId: OrganizationIdPathParam) =>
        buildPath([CommunityPaths.COMMUNITY(organizationId), PathComponents.SECTIONS.USERS])
};
