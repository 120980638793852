import { injectable } from 'inversify';
import { Maybe } from 'yup';
import { TranslationService } from './translationService';

@injectable()
export class NumberService {
    constructor(private translationService: TranslationService) {}

    getFormatter(options: Intl.NumberFormatOptions = {}) {
        return Intl.NumberFormat(this.translationService.language.toString().toLowerCase(), {
            notation: options.notation || 'compact',
            style: options.style || 'unit',
            unit: options.unit || 'byte',
            unitDisplay: options.unitDisplay || 'narrow'
        });
    }

    toNumberOrInfinity(number: Maybe<number>): string {
        return number && number !== Infinity ? number.toLocaleString() : '∞';
    }

    static bytesToMegabytes(number: number): string {
        return `${parseFloat((number / 1000000).toPrecision(1))} MB`;
    }

    static bytesToGigabytes(number: number): string {
        return `${parseFloat((number / 1000000000).toPrecision(1))} GB`;
    }
}
