import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { PositionsSlotInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { isValidTime, parseTime } from '../util/time';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';
import { InputService } from './inputService';

export interface ICreatePositionSlotValues {
    slot: PositionsSlotInput;
}

@injectable()
export class PositionsSlotInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    positionsSlotInputDefault(
        startAt: DateTime,
        positionSlot?: PositionsSlotInput
    ): PositionsSlotInput {
        return {
            id: positionSlot?.id,
            name: positionSlot?.name ?? '',
            startDate: positionSlot?.startDate ?? startAt,
            startTime: positionSlot?.startTime ?? '00:00',
            endDate: positionSlot?.endDate ?? startAt,
            endTime: positionSlot?.endTime ?? '00:00',
            resources: positionSlot?.resources ?? 1,
            assignedResources: positionSlot?.assignedResources
        };
    }

    createPositionSlotSchema() {
        return yup.object().shape({
            slot: this.positionsSlotInputSchema()
        });
    }

    positionsSlotInputSchema() {
        return yup.object().shape({
            name: yup.string(),
            startDate: DateTimeSchemaType.required(this.t('la_date_de_d_bu_05030')).typeError(
                this.t('la_date_de_d_bu_05030')
            ),
            startTime: yup.string().required(this.t('heure_de_d_but_90785')),
            endDate: DateTimeSchemaType.required(this.t('la_date_de_fin_32246'))
                .typeError(this.t('la_date_de_fin_32246'))
                .test(
                    'after-start-date',
                    this.t('la_date_de_fin_00429'),
                    (_value, testContext) =>
                        DateTime.isDateTime(testContext.parent.startDate) &&
                        testContext.parent.startDate.isValid &&
                        DateTime.isDateTime(testContext.parent.endDate) &&
                        testContext.parent.endDate.isValid &&
                        testContext.parent.endDate >= testContext.parent.startDate
                ),
            endTime: yup
                .string()
                .required(this.t('l_heure_de_fin_78626'))
                .test('after-start-time', this.t('la_date_de_fin_00429'), (_value, testContext) => {
                    const isStartDatevalid =
                        DateTime.isDateTime(testContext.parent.startDate) &&
                        testContext.parent.startDate.isValid;
                    const isStarTimeValid = isValidTime(testContext.parent.startTime);
                    const isEndDateValid =
                        DateTime.isDateTime(testContext.parent.endDate) &&
                        testContext.parent.endDate.isValid;
                    const isEndTimeValid = isValidTime(testContext.parent.endTime);

                    if (isStartDatevalid && isStarTimeValid && isEndDateValid && isEndTimeValid) {
                        const [startHours, startMinutes] = parseTime(testContext.parent.startTime)!;
                        const [endHours, endMinutes] = parseTime(testContext.parent.endTime)!;
                        const start = testContext.parent.startDate.set({
                            hours: startHours,
                            minutes: startMinutes
                        });
                        const end = testContext.parent.endDate.set({
                            hours: endHours,
                            minutes: endMinutes
                        });

                        return start < end;
                    } else {
                        return false;
                    }
                }),
            resources: yup
                .number()
                .required(this.t('le_nombre_de_me_04870'))
                .typeError(this.t('le_nombre_de_me_04870'))
                .positive(this.t('le_nombre_de_me_04870'))
        });
    }
}
