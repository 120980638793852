import { HeaventEnv } from './heaventEnv';
import { NodeEnv } from './nodeEnv';
import { decodeBase64 } from './util/base64';
import { isNonEmptyString } from './util/string';

interface IAdmin {
    id: number;
    email: string;
}

const heaventSecrets =
    typeof process !== 'undefined' && isNonEmptyString(process?.env?.HEAVENT_SECRETS)
        ? JSON.parse(decodeBase64(process.env.HEAVENT_SECRETS))
        : null;

const read = <T = string>(name: string): T => {
    if (typeof window === 'object' && (window as any)[name] !== undefined) {
        return (window as any)[name];
    } else if (heaventSecrets?.[name] !== undefined) {
        return heaventSecrets[name];
    } else {
        throw new Error(`Missing env. var. ${name}`);
    }
};

export const CommonEnvVars =
    process.env.NODE_ENV === NodeEnv.Test
        ? {
              HEAVENT_ENV: HeaventEnv.Recrewteer,
              ADMINS: [] as IAdmin[],
              EMAIL_FROM: 'matthieu.ravey@weezevent.com',
              HEAVENT_EXTERNAL_DOMAIN: 'recrewteer-test.com',
              LOG_TOKEN: false
          }
        : {
              HEAVENT_ENV: read<HeaventEnv>('HEAVENT_ENV'),
              ADMINS: read<IAdmin[]>('HEAVENT_ADMINS'),
              EMAIL_FROM: read('HEAVENT_EMAIL_FROM'),
              HEAVENT_EXTERNAL_DOMAIN: read('HEAVENT_EXTERNAL_DOMAIN'),
              LOG_TOKEN: read('HEAVENT_LOG_TOKEN')
          };

export function getAdminsEmails() {
    return CommonEnvVars.ADMINS.map(({ email }) => email);
}
