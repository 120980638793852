import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface ILabelProps {
    id?: string;
    children?: React.ReactNode;
    htmlFor?: string;
    isClickable?: boolean;
}

export const Label = ({ id, children, htmlFor, isClickable = true }: ILabelProps) => {
    if (children) {
        return (
            <label
                htmlFor={htmlFor}
                id={id}
                style={{
                    display: 'block',
                    cursor: 'inherit',
                    fontWeight: '$medium',
                    marginBlockEnd: 0
                }}
                onClick={(e) => {
                    if (!isClickable) {
                        e.preventDefault();
                    }
                }}
            >
                <Box color="gray700" fontWeight="medium">
                    {children}
                </Box>
            </label>
        );
    } else {
        return null;
    }
};
