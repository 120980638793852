import { injectable } from 'inversify';
import { SegmentCustomFieldFragment } from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { PossibleColumn, SegmentService } from '../segment';
import { VolunteerDefaultColumns } from './volunteersSegmentsService';

@injectable()
export class AccreditationMembersSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getAccreditationMembersPossibleColumns(
        customFields: SegmentCustomFieldFragment[]
    ): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.Email,
                name: this.t('e_mail_du_membr_69509')
            },
            {
                slug: VolunteerDefaultColumns.InsertedAt,
                name: this.t('date_d_inscript_31369')
            },
            {
                slug: VolunteerDefaultColumns.Name,
                name: this.t('full_name')
            },
            {
                slug: VolunteerDefaultColumns.Positions,
                name: this.t('missions_affect_97811')
            },
            {
                slug: VolunteerDefaultColumns.VolunteerRegistrationState,
                name: this.t('status_d_affect_75769')
            },
            {
                slug: VolunteerDefaultColumns.WishedPositions,
                name: this.t('missions_souhai_23225')
            },
            {
                slug: VolunteerDefaultColumns.Accreditations,
                name: this.t('accr_ditations_33155')
            },
            {
                slug: VolunteerDefaultColumns.AccreditationsSlots,
                name: this.t('cr_neaux_accr_d_70934')
            },
            {
                slug: VolunteerDefaultColumns.AccreditationState,
                name: this.t('statut_d_accr_d_75527')
            },
            {
                slug: VolunteerDefaultColumns.WishedAccreditationSlots,
                name: this.t('accr_ditations_74375')
            },
            ...this.customFieldsToPossibleColumns(customFields)
        ];
    }
}
