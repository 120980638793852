import { injectable } from 'inversify';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

@injectable()
export class SegmentInputService extends InputService {
    constructor(protected translationService: TranslationService) {
        super(translationService);
    }
}
