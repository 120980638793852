import { Blank } from 'common-front/src/components/blank/blank';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import {
    CustomFieldWithConditionFragment,
    EventId,
    FormId,
    FormInfosQuery,
    FormRegisterResult,
    OrganizationId,
    UsersInfoId
} from 'common/src/generated/types';
import { TemplateService } from 'common/src/services/templateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { Fields } from 'common/src/vo/field';
import { DateTime } from 'luxon';
import * as React from 'react';
import { FormSuccessInvitations } from './formSuccessInvitations';

interface IFormSuccessProps {
    customFields: CustomFieldWithConditionFragment[];
    email: string;
    eventId: Emptyable<EventId>;
    form: FormInfosQuery['organization']['form'];
    formId: FormId;
    formRegisterResult: Emptyable<FormRegisterResult>;
    organizationId: OrganizationId;
    userInfoFields: Fields;
}

export const FormSuccess = (props: IFormSuccessProps) => {
    const translate = useTranslate();
    const templateService = useService(TemplateService);
    const message = React.useMemo(() => {
        if (isNonEmptyString(props.form.successMessage)) {
            return templateService.renderText(
                props.form.successMessage,
                {
                    id: -1 as UsersInfoId,
                    email: props.email,
                    fields: props.userInfoFields,
                    registrationDate: DateTime.now()
                },
                null,
                null,
                props.customFields,
                null
            );
        } else {
            return '';
        }
    }, [props.customFields, props.email, props.form.successMessage, props.userInfoFields]);

    return (
        <>
            {isNonEmptyString(props.form.successMessage) ? (
                <RichText text={message} />
            ) : (
                <Box height={250} width={1}>
                    <Blank imageSrc={Svgs.Done} title={translate('merci_70020')} />
                </Box>
            )}

            {props.form.areTeamsAllowed && (
                <FormSuccessInvitations
                    eventId={props.eventId}
                    form={props.form}
                    formId={props.formId}
                    formRegisterResult={props.formRegisterResult}
                    organizationId={props.organizationId}
                />
            )}
        </>
    );
};
