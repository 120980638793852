import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IFormSuccessInvitationEmailProps {
    email: string;
    index: number;

    onChange(email: string): void;
}

export const FormSuccessInvitationEmail = (props: IFormSuccessInvitationEmailProps) => {
    const translate = useTranslate();

    return (
        <Flex direction="column" gap="2">
            <Box font="gray800 textSm semiBold" css={{ textTransform: 'uppercase' }}>
                {translate('invitation_n_1_64964', props.index + 1)}
            </Box>

            <TextInput
                label={translate('adresse_e_mail_60930')}
                isEmail={true}
                value={props.email}
                onChange={props.onChange}
            />
        </Flex>
    );
};
