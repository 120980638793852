let canvas: HTMLCanvasElement | null = null;

interface IGetTextWidthOptions {
    fontSize?: string;
    fontWeight?: string;
}

export function getTextWidth(
    text: string,
    { fontSize, fontWeight }: IGetTextWidthOptions = {}
): number {
    canvas ||= document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    context.font = `${fontWeight || '400'} ${fontSize || '14px'} 'Open Sans`;
    const metrics = context.measureText(text);

    return metrics.width;
}
