import { Alert } from 'common-front/src/designSystem/components/alert';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

interface INoLongerEditableAlertProps {
    message: string;
    title: string;
}

export const NoLongerEditableAlert = ({ message, title }: INoLongerEditableAlertProps) => (
    <Alert color="warning" leftIcon="triangle-exclamation">
        <Flex css={{ fontWeight: '$medium' }} direction="column">
            {title}
        </Flex>

        <Spacer height="1" />

        <Flex css={{ fontWeight: 'normal' }}>{message}</Flex>
    </Alert>
);
