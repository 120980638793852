import { injectable } from 'inversify';
import { SegmentsFolder, SegmentsFolderInput, SegmentType } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface ICreateSegmentFolderValues {
    segmentFolder: SegmentsFolderInput;
}

@injectable()
export class SegmentFolderInputService extends InputService {
    constructor(protected translationService: TranslationService) {
        super(translationService);
    }

    segmentFolderInputDefault(
        segmentType: SegmentType,
        segmentFolder?: Pick<SegmentsFolder, 'name'>
    ): SegmentsFolderInput {
        return {
            name: segmentFolder?.name ?? '',
            segmentType
        };
    }
}
