import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { OrganizationLogoSkeleton } from '../../organizationLogoSkeleton';

export const ProfilesListContainer = styled('div', {
    alignItems: 'center',
    background: '$gray50',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    padding: '$4',
    paddingTop: '$10',
    width: '100%',
    '@tablet': {
        padding: '$10'
    }
});

export const ProfilesListSkeleton = () => (
    <ProfilesListContainer>
        <OrganizationLogoSkeleton />

        <Flex
            direction="column"
            gap="3"
            css={{
                width: '100%',
                '@desktop': {
                    width: '$modalMd'
                }
            }}
        >
            <Skeleton height={23} width={1} borderRadius="$1" />
            <Skeleton height={74} width={1} borderRadius="$2" />
            <Skeleton height={74} width={1} borderRadius="$2" />
            <Skeleton height={74} width={1} borderRadius="$2" />
        </Flex>
    </ProfilesListContainer>
);
