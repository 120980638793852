import { injectable } from 'inversify';
import { Interval, LocaleOptions } from 'luxon';
import * as IntervalUtil from '../util/interval';
import { LocaleFormatType } from '../util/luxon';
import { TranslationService } from './translationService';

export interface IToDisplayStringOptions {
    formats?: {
        dateFormat?: string;
        displayWeekday?: boolean;
        localeFormat?: LocaleFormatType;
        timeFormat?: string;
    };
    hideTime?: boolean;
    hideDate?: boolean;
}

@injectable()
export class IntervalService {
    constructor(private translationService: TranslationService) {}

    toDisplayString(interval: Interval, options: IToDisplayStringOptions = {}): string {
        return IntervalUtil.toDisplayString(interval, {
            ...options,
            language: this.translationService.language
        });
    }

    toLocaleString(
        interval: Interval,
        localeOptions?: LocaleOptions,
        formatOptions?: Intl.DateTimeFormatOptions
    ): string {
        return interval.toLocaleString(formatOptions, localeOptions);
    }
}
