import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { ErrorModal } from '../error/errorModal';

interface IErrorBoundaryContext {
    isError: boolean;

    setIsError(value: boolean, errors?: any[]): void;
}

export const ErrorBoundaryContext = React.createContext<IErrorBoundaryContext>({} as any);

interface IErrorBoundaryContextProviderProps {
    children: React.ReactNode;
}

export const ErrorBoundaryContextProvider = (props: IErrorBoundaryContextProviderProps) => {
    const translate = useTranslate();
    const [isError, _setIsError] = React.useState(false);
    const [messages, setMessages] = React.useState<string[] | undefined>([]);
    const setIsError = React.useCallback(
        (newIsError: boolean, errors?: any[]) => {
            _setIsError(newIsError);
            setMessages(
                (errors || []).flatMap((error) => {
                    if (error.isHeaventError) {
                        return error.message.split('\n');
                    } else if (error.message?.startsWith('db::')) {
                        return [translate(error.message.substring(4))];
                    } else {
                        return [];
                    }
                })
            );
        },
        [_setIsError, setMessages]
    );
    const onClose = React.useCallback(() => {
        _setIsError(false);
        setMessages([]);
    }, [_setIsError, setMessages]);

    return (
        <ErrorBoundaryContext.Provider
            value={{
                isError,
                setIsError
            }}
        >
            {props.children}

            {isError && <ErrorModal content={messages} onClose={onClose} />}
        </ErrorBoundaryContext.Provider>
    );
};
