export function encodeBase64(str: string): string {
    try {
        return Buffer.from(str).toString('base64');
    } catch {
        try {
            return btoa(str);
        } catch {
            return '';
        }
    }
}

export function decodeBase64(str: string): string {
    try {
        return Buffer.from(str, 'base64').toString();
    } catch {
        try {
            return atob(str);
        } catch {
            return '';
        }
    }
}
