import { injectable } from 'inversify';
import { theme } from '../designSystem/components/stitches';
import {
    DesignInput,
    FormParametersDesignQuery,
    OrganizationMembersSpaceDesignQuery
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { Emptyable } from '../util/emptyable';
import { DocumentInputService } from './documentInput';
import { InputService } from './inputService';

export interface IDesignInputValues {
    design: DesignInput;
}

export function designDefaultColors(colors: Emptyable<any>) {
    return {
        gray50: colors?.gray50 ?? theme.colors.gray50.value,
        gray500: colors?.gray500 ?? theme.colors.gray500.value,
        gray700: colors?.gray700 ?? theme.colors.gray700.value,
        gray800: colors?.gray800 ?? theme.colors.gray800.value,
        gray900: colors?.gray900 ?? theme.colors.gray900.value,
        primary700: colors?.primary700 ?? theme.colors.primary700.value,
        primary800: colors?.primary800 ?? theme.colors.primary800.value
    };
}

@injectable()
export class DesignInputService extends InputService {
    constructor(
        private documentInputService: DocumentInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    organizationDesignInputDefault(
        design: OrganizationMembersSpaceDesignQuery['organization']['membersSpaceDesign']
    ): DesignInput {
        return {
            banner: design?.banner ?? this.documentInputService.documentInputDefault(),
            logo: design?.logo ?? this.documentInputService.documentInputDefault(),
            colors: designDefaultColors(design?.colors)
        };
    }

    formParametersDesignInputDefault(
        design: FormParametersDesignQuery['organization']['form']['design']
    ): DesignInput {
        return {
            banner: design?.banner ?? this.documentInputService.documentInputDefault(),
            colors: designDefaultColors(design?.colors)
        };
    }
}
