export function parseParamsIds<T>(
    params: Record<string, any>,
    customParsers: Record<string, (key: string) => any> = {}
): T {
    return Object.fromEntries(
        Object.entries(params).map(([key, value]) => {
            if (key.endsWith('Id') || key === 'id') {
                return [key, parseInt(value, 10)];
            } else if (customParsers[key]) {
                return [key, customParsers[key](value)];
            } else {
                return [key, value];
            }
        })
    ) as T;
}
