import { injectable } from 'inversify';
import {
    ALL_CUSTOMFIELDVARIETY,
    ALL_FIELDTYPE,
    CustomFieldsSegmentsQuery,
    FilterType
} from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { varietyToTranslation } from '../customField';
import { Filter, PossibleColumn, SegmentService } from '../segment';

@injectable()
export class CustomFieldsSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getCustomFieldsFilters(infos: CustomFieldsSegmentsQuery['organization']): Filter[] {
        return [
            {
                color: 'purple',
                slug: 'variety',
                name: this.t('typologie_34041'),
                fieldType: FilterType.Select,
                values: ALL_CUSTOMFIELDVARIETY.map((variety) => ({
                    id: variety,
                    name: varietyToTranslation(this.t.bind(this), variety)
                })),
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'fieldType',
                name: this.t('type_35427'),
                fieldType: FilterType.Select,
                values: ALL_FIELDTYPE.map((fieldType) => ({
                    id: fieldType,
                    name: fieldType
                })),
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'privacy',
                name: this.t('visibilit_46792'),
                fieldType: FilterType.Checkbox,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'customFieldCategory',
                name: this.t('cat_gorie_00291'),
                fieldType: FilterType.Select,
                values: infos.customFieldsCategories.nodes,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'condition',
                name: 'Condition',
                fieldType: FilterType.Select,
                values: infos.customFields.nodes
            }
        ];
    }

    getCustomFieldsPossibleColumns(): PossibleColumn[] {
        return [
            {
                slug: 'name',
                name: this.t('nom_du_champ_48329')
            },
            {
                slug: 'category',
                name: this.t('Category')
            },
            {
                slug: 'variety',
                name: this.t('typologie_34041')
            },
            {
                slug: 'fieldType',
                name: this.t('type_35427')
            },
            {
                slug: 'numberOfTimesUsed',
                name: this.t('nombre_d_utilis_58389')
            },
            {
                slug: 'state',
                name: this.t('status_06428')
            },
            {
                slug: 'values',
                name: this.t('valeurs_34314')
            },
            {
                slug: 'variable',
                name: this.t('variable_pour_l_45711')
            },
            {
                slug: 'createdBy',
                name: this.t('cr_par_41994')
            }
        ];
    }
}
