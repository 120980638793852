import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { AccreditationsSlotId, DelegationAccreditationsQuery } from 'common/src/generated/types';
import { isFuzzyMatch } from 'common/src/util/string';
import * as React from 'react';
import { AccreditationCategories } from '../../../components/accreditations/accreditationCategories';
import { AccreditationSlotMembersPanel } from '../../../components/accreditations/sidebars/accreditationSlotMembersPanel';
import { TextInput } from '../../../designSystem/components/textInput';
import { useHeavent } from '../../../hooks/useHeavent';

interface IDelegationAccreditationsProps {
    delegation: DelegationAccreditationsQuery['organization']['delegation'];
    isEventAdmin?: boolean;

    reload(): void;
}

export const DelegationAccreditations = (props: IDelegationAccreditationsProps) => {
    const { translate } = useHeavent();
    const [searchText, setSearchText] = React.useState('');
    const filteredAccreditations = React.useMemo(
        () =>
            props.delegation.accreditationsSlots.filter(
                (accreditation) =>
                    isFuzzyMatch(accreditation.accreditationSlot.name, searchText) ||
                    isFuzzyMatch(accreditation.accreditationSlot.accreditation.name, searchText) ||
                    isFuzzyMatch(
                        accreditation.accreditationSlot.accreditationCategory.name,
                        searchText
                    )
            ),
        [props.delegation.accreditationsSlots, searchText]
    );

    return (
        <>
            <Box width={320}>
                <TextInput
                    icon="magnifying-glass"
                    placeholder={translate('rechercher_une_65646')}
                    value={searchText}
                    onChange={(newName: string) => {
                        setSearchText(newName);
                    }}
                />
            </Box>

            <Spacer height="6" />

            <AccreditationCategories
                accreditationsSlots={filteredAccreditations.map((da) => da.accreditationSlot)}
                delegationAccreditationsSlots={filteredAccreditations.map((da) => ({
                    ...da.accreditationSlot,
                    assignedResources: da.assignedResources,
                    wishedResources: da.wishedResources,
                    maxResources: da.maxResources
                }))}
                panel={(
                    setIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>,
                    slotId: AccreditationsSlotId
                ) => (
                    <AccreditationSlotMembersPanel
                        accreditationSlotId={slotId}
                        isEventAdmin={props.isEventAdmin}
                        onClose={() => {
                            setIsPanelOpen(false);
                            props.reload();
                        }}
                    />
                )}
                showFillingRate={true}
            />
        </>
    );
};
