import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { useUniqueIds } from '../../../hooks/useUniqueIds';
import { CommonInputProps } from '../input/commonInputProps';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { StyledInputContainer } from '../input/styledInputContainer';
import { getValueToName, parseSelectChildren } from '../richSelect/selectUtil';

const _Select = styled('select', {
    backgroundColor:
        '$white' /* Even though opacity is set to 0, this fixes a bug on Safari where the height isn’t 100%. */,
    cursor: 'pointer',
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
});

const _Div = styled('div', {
    ellipsis: ''
});

export interface ISelectProps
    extends Omit<
            React.SelectHTMLAttributes<HTMLSelectElement>,
            | 'autoComplete'
            | 'onBlur'
            | 'onFocus'
            | 'onKeyDown'
            | 'onChange'
            | 'onRightIconClick'
            | 'value'
        >,
        CommonInputProps<HTMLSelectElement> {
    shouldParseAsBoolean?: boolean;
    shouldParseAsInt?: boolean;
    subtitle?: string;
    value: string | number | readonly string[] | boolean;
}

export const Select = ({
    autoComplete,
    children,
    css,
    label,
    description,
    shouldParseAsInt,
    shouldParseAsBoolean,
    onChange,
    hint,
    icon,
    state,
    value = '',
    ...rest
}: ISelectProps) => {
    const valueToName = React.useMemo(
        () => getValueToName(parseSelectChildren(children)),
        [children]
    );
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={css} direction="column" width={1}>
            <Label htmlFor={inputId}>{label}</Label>

            <Description id={descId}>{description}</Description>

            {(label || description) && <Spacer height="1" />}

            <StyledInputContainer cursor="default" icon={icon} state={state}>
                <Flex
                    align="center"
                    css={{
                        flex: '1',
                        overflow: 'hidden'
                    }}
                    height={1}
                    width={1}
                >
                    <_Div>{valueToName[value.toString()]}</_Div>
                </Flex>

                <Flex
                    css={{
                        color: '$gray500'
                    }}
                >
                    <I icon="chevron-down" />
                </Flex>

                <_Select
                    aria-describedby={description ? descId : undefined}
                    aria-errormessage={state === 'error' ? errorId : undefined}
                    aria-invalid={state === 'error'}
                    autoComplete={autoComplete ?? 'off'}
                    disabled={state === 'disabled'}
                    id={inputId}
                    value={value.toString()}
                    onChange={(e) => {
                        if (shouldParseAsInt) {
                            onChange(parseInt(e.target.value, 10));
                        } else if (shouldParseAsBoolean) {
                            onChange(e.target.value === 'true');
                        } else {
                            onChange(e.target.value);
                        }
                    }}
                    {...rest}
                >
                    {children}
                </_Select>
            </StyledInputContainer>

            <Hint id={errorId} state={state}>
                {hint}
            </Hint>
        </Flex>
    );
};
