import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import * as React from 'react';

interface IFormBoxProps {
    canSelect: boolean;
    children: React.ReactNode;
    isCompleted: boolean;
    isOpen: boolean;
    number: string | number;
    title: string;

    onSelect(): void;
}

export const FormBox = React.forwardRef((props: IFormBoxProps, ref: React.Ref<HTMLDivElement>) => (
    <Flex
        ref={ref}
        direction="column"
        css={{
            background: 'white',
            bd: 'white 1 sm',
            width: '100%',
            '@tablet': {
                width: '$containerTablet'
            },
            '@desktop': {
                width: '$container'
            }
        }}
    >
        <Flex
            align="center"
            gap="4"
            css={{
                borderBottom: props.isOpen ? '1px solid $gray200' : '',
                cursor: props.canSelect ? 'pointer' : 'default',
                padding: '$4',
                '@tablet': {
                    padding: '$4 $6'
                }
            }}
            onClick={() => {
                if (props.canSelect) {
                    props.onSelect();
                }
            }}
        >
            <Flex
                align="center"
                justify="center"
                height={32}
                width={32}
                css={{
                    background: props.isCompleted ? '$success100' : 'white',
                    border: props.isCompleted ? 'none' : '1px solid $gray200',
                    borderRadius: '32px',
                    color: props.isCompleted ? '$success700' : '$gray800',
                    fontSize: '$textMd',
                    fontWeight: '$semiBold'
                }}
            >
                {props.isCompleted ? <I icon="check" /> : props.number}
            </Flex>

            <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                {props.title}
            </Box>
        </Flex>

        {props.isOpen && (
            <Flex
                direction="column"
                css={{
                    padding: '$6 $4',
                    '@tablet': {
                        padding: '$6'
                    },
                    '@desktop': {
                        padding: '$6 70px'
                    }
                }}
            >
                {props.children}
            </Flex>
        )}
    </Flex>
));
