import { injectable } from 'inversify';
import { compact, intersection } from 'lodash-es';
import { DateTime } from 'luxon';
import {
    CustomFieldWithConditionFragment,
    FieldProperty,
    FieldType,
    Phone
} from '../generated/types';
import { CountriesService } from '../services/countriesService';
import { DateTimeService } from '../services/dateTimeService';
import { TranslationService } from '../services/translationService';
import { isNonEmptyArray, toArray } from '../util/array';
import { assertUnreachable } from '../util/assertUnreachable';
import { LocaleFormats } from '../util/luxon';
import { isNonEmptyString } from '../util/string';

type GetValueStringField = Pick<CustomFieldWithConditionFragment, 'fieldType' | 'slug' | 'values'>;

export type Fields = { [slug: string]: any };

export type UserInfo = { fields: Fields };

interface IGetValueStringOptions {
    fileReturnValue?: 'url' | 'name';
    dateFormat?: string;
    datetimeFormat?: string;
}

@injectable()
export class FieldService {
    constructor(
        private countriesService: CountriesService,
        private dateTimeService: DateTimeService,
        private translationService: TranslationService
    ) {}

    getValueString(
        field: GetValueStringField,
        userInfo: UserInfo,
        options: IGetValueStringOptions = {}
    ): string {
        switch (field.fieldType) {
            case FieldType.Text:
            case FieldType.Textarea:
            case FieldType.Time:
            case FieldType.Address:
                return userInfo.fields[field.slug] || '';
            case FieldType.Sex:
                return this.getSexValue(field, userInfo);
            case FieldType.Language:
                return this.getLanguageValue(field, userInfo);
            case FieldType.Nationality:
                return this.getNationalityValue(field, userInfo);
            case FieldType.Country:
                return this.getCountryValue(field, userInfo);
            case FieldType.Select:
                return this.getSelectValue(field, userInfo);
            case FieldType.Checkbox:
            case FieldType.Validation:
                return this.getBooleanValue(field, userInfo);
            case FieldType.Date:
                return this.getDateValue(field, userInfo, options.dateFormat);
            case FieldType.Datetime:
                return this.getDatetimeValue(field, userInfo, options.datetimeFormat);
            case FieldType.File:
                if (options.fileReturnValue === 'name') {
                    return userInfo.fields[field.slug]?.name || '';
                } else {
                    return userInfo.fields[field.slug]?.url || '';
                }
            case FieldType.Phone:
                return this.getPhoneValue(field, userInfo);
            case FieldType.Number:
                return this.getIntValue(field, userInfo);
            default:
                return assertUnreachable(field.fieldType);
        }
    }

    getValue<T>(field: GetValueStringField, userInfo: UserInfo): T | undefined {
        return userInfo.fields[field.slug];
    }

    private getSexValue(field: GetValueStringField, userInfo: UserInfo): string {
        return this.translationService.translate(this.getValue(field, userInfo) ?? '');
    }

    private getLanguageValue(field: GetValueStringField, userInfo: UserInfo): string {
        return this.translationService.translate(this.getValue(field, userInfo) ?? '');
    }

    private getNationalityValue(field: GetValueStringField, userInfo: UserInfo): string {
        return this.countriesService.getNationality(this.getValue(field, userInfo) ?? '') ?? '';
    }

    private getCountryValue(field: GetValueStringField, userInfo: UserInfo): string {
        return this.countriesService.getName(this.getValue(field, userInfo) ?? '') ?? '';
    }

    private getBooleanValue(field: GetValueStringField, userInfo: UserInfo): string {
        const value = this.getValue<boolean>(field, userInfo);

        if (value === true) {
            return this.translationService.translate('oui_54361');
        } else if (value === false) {
            return this.translationService.translate('non_33516');
        } else {
            return '';
        }
    }

    private getDateValue(
        field: GetValueStringField,
        userInfo: UserInfo,
        dateFormat?: string
    ): string {
        let dateValue = this.getValue<DateTime | string>(field, userInfo);

        if (isNonEmptyString(dateValue)) {
            dateValue = DateTime.fromISO(dateValue, { zone: 'utc' });
        }

        return DateTime.isDateTime(dateValue) && dateValue.isValid
            ? isNonEmptyString(dateFormat)
                ? dateValue.toFormat(dateFormat)
                : this.dateTimeService.toLocaleString(dateValue, LocaleFormats.DateOnly.MonthLong)
            : '';
    }

    private getDatetimeValue(
        field: GetValueStringField,
        userInfo: UserInfo,
        datetimeFormat?: string
    ): string {
        let datetimeValue = this.getValue<DateTime | string>(field, userInfo);

        if (isNonEmptyString(datetimeValue)) {
            datetimeValue = DateTime.fromISO(datetimeValue, { zone: 'utc' });
        }

        return DateTime.isDateTime(datetimeValue) && datetimeValue.isValid
            ? isNonEmptyString(datetimeFormat)
                ? datetimeValue.toFormat(datetimeFormat)
                : this.dateTimeService.toLocaleString(datetimeValue, LocaleFormats.DateTime)
            : '';
    }

    private getPhoneValue(field: GetValueStringField, userInfo: UserInfo): string {
        return this.getValue<Phone>(field, userInfo)?.internationalFormat ?? '';
    }

    private getIntValue(field: GetValueStringField, userInfo: UserInfo): string {
        return this.getValue<number>(field, userInfo)?.toString() ?? '';
    }

    private getSelectValue(field: GetValueStringField, userInfo: UserInfo): string {
        const valuesIds = toArray(this.getValue<number | number[]>(field, userInfo) ?? []);

        return compact(
            valuesIds.map((valueId) => field.values.find((v) => v.id === valueId)?.value)
        ).join(', ');
    }
}

const CONDITION_FIELD_TYPES = [
    FieldType.Country,
    FieldType.Language,
    FieldType.Nationality,
    FieldType.Select
];

export function shouldDisplay(
    field: CustomFieldWithConditionFragment,
    values: Fields,
    fields: CustomFieldWithConditionFragment[]
): boolean {
    if (field.fieldProperty === FieldProperty.Custom) {
        if (field.hasCondition && field.conditionCustomField) {
            const parentField = fields.find((f) => f.id === field.conditionCustomField!.id);

            if (parentField) {
                const shouldDisplayParent = shouldDisplay(parentField, values, fields);

                if (shouldDisplayParent) {
                    const conditionFieldValue = values[field.conditionCustomField.slug];

                    if (
                        typeof conditionFieldValue === 'boolean' &&
                        field.conditionCustomField.fieldType === FieldType.Checkbox
                    ) {
                        return conditionFieldValue === field.conditionValue;
                    } else if (
                        CONDITION_FIELD_TYPES.includes(field.conditionCustomField.fieldType) &&
                        isNonEmptyArray(field.conditionValue)
                    ) {
                        return (
                            intersection(toArray(conditionFieldValue), field.conditionValue)
                                .length > 0
                        );
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    } else {
        return true;
    }
}
