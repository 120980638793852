import { injectable } from 'inversify';
import * as yup from 'yup';
import { theme } from '../designSystem/components/stitches';
import { TraceInput } from '../generated/types';
import { TranslationService } from '../services/translationService';

export interface ICreateTraceValues {
    trace: TraceInput;
}

@injectable()
export class TraceInputService {
    constructor(private translationService: TranslationService) {}

    traceInputDefault(trace?: TraceInput): TraceInput {
        return {
            name: trace?.name ?? '',
            color: trace?.color ?? theme.colors.primary700.value,
            points: trace?.points ?? []
        };
    }

    traceInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.translationService.translate('le_nom_du_trac_39859')),
            color: yup
                .string()
                .required(this.translationService.translate('la_couleur_du_t_36646')),
            points: yup
                .array()
                .required(this.translationService.translate('le_trac_doit_c_20895'))
                .min(2, this.translationService.translate('le_trac_doit_c_20895'))
        });
    }

    createTraceSchema() {
        return yup.object().shape({
            trace: this.traceInputSchema()
        });
    }

    updateNameSchema() {
        return yup.object().shape({
            name: yup.string().required(this.translationService.translate('le_nom_du_trac_39859'))
        });
    }
}
