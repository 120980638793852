import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { normalize } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import * as React from 'react';

export function useEnumToOptions(): <T extends {}>(values: T[]) => React.ReactNode {
    const translate = useTranslate();

    return <T extends {}>(values: T[]) => {
        const valuesTranslations = sortBy(
            values.map((value) => {
                const valueString = value as unknown as string;

                return {
                    value: valueString,
                    translation: translate(valueString)
                };
            }),
            ({ translation }) => normalize(translation).toLowerCase()
        );

        return valuesTranslations.map(({ value, translation }, index: number) => (
            <option key={index} value={value}>
                {translation}
            </option>
        ));
    };
}
