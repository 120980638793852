export enum MimeType {
    Csv = 'text/csv',
    Default = 'application/octet-stream',
    Excel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    Gif = 'image/gif',
    Jpeg = 'image/jpeg',
    Pdf = 'application/pdf',
    Png = 'image/png',
    Zip = 'application/zip'
}

export const mimeType = (extension: string) => {
    switch (extension.toLowerCase()) {
        case 'csv':
            return MimeType.Csv;
        case 'xls':
        case 'xlsx':
            return MimeType.Excel;
        case 'gif':
            return MimeType.Gif;
        case 'jpg':
        case 'jpeg':
            return MimeType.Jpeg;
        case 'pdf':
            return MimeType.Pdf;
        case 'png':
            return MimeType.Png;
        case 'zip':
            return MimeType.Zip;
        default:
            return MimeType.Default;
    }
};
