import { FieldSubscription } from 'final-form';
import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

interface IFields {
    [key: string]: FieldRenderProps<any, any>;
}

interface IFieldsProps {
    names: string[];
    subscription?: FieldSubscription;
    fields?: IFields;

    render(fields: IFields): React.ReactNode;
}

export const Fields: React.FunctionComponent<IFieldsProps> = (props: IFieldsProps) => {
    if (props.names.length === 0) {
        return props.render(props.fields!);
    } else {
        const [name, ...otherNames] = props.names;

        return (
            <Field
                name={name}
                render={(fieldProps) => (
                    <Fields
                        fields={{
                            ...(props.fields || {}),
                            [name]: fieldProps
                        }}
                        names={otherNames}
                        render={props.render}
                        subscription={props.subscription}
                    />
                )}
                subscription={props.subscription}
            />
        ) as any;
    }
};
