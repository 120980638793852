import { injectable } from 'inversify';
import * as yup from 'yup';
import { AccreditationsSlotInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';
import { InputService } from './inputService';

export interface ICreateAccreditationSlotValues {
    slot: AccreditationsSlotInput;
}

@injectable()
export class AccreditationsSlotInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    accreditationsSlotInputDefault(): AccreditationsSlotInput {
        return { name: '' };
    }

    createAccreditationSlotSchema() {
        return yup.object().shape({
            slot: this.accreditationsSlotInputSchema()
        });
    }

    accreditationsSlotInputSchema() {
        return yup.object().shape({
            name: yup.string(),
            maxResources: yup.number().nullable(),
            date: DateTimeSchemaType.typeError(this.t('la_date_est_inv_22002'))
        });
    }
}
