import * as React from 'react';

interface ITableContext {
    clickedRowIndex: number | null;

    setClickedRowIndex(index: number | null): void;
}

const TableContext = React.createContext<ITableContext>({} as ITableContext);

interface ITableContextProviderProps {
    children: React.ReactNode;
}

export const TableContextProvider = (props: ITableContextProviderProps) => {
    const [clickedRowIndex, setClickedRowIndex] = React.useState<number | null>(null);

    return (
        <TableContext.Provider
            value={{
                clickedRowIndex,
                setClickedRowIndex
            }}
        >
            {props.children}
        </TableContext.Provider>
    );
};

export function useTableContext(): ITableContext {
    return React.useContext(TableContext);
}
