import { uniqueId } from 'lodash-es';
import * as React from 'react';

export function useUniqueIds() {
    // TODO: Replace with `useId()` when we upgrade to React 18
    const [inputId] = React.useState(() => uniqueId('forminput-'));
    const [labelId] = React.useState(() => uniqueId('forminputlabel-'));
    const [descId] = React.useState(() => uniqueId('forminputdesc-'));
    const [errorId] = React.useState(() => uniqueId('forminputerror-'));

    return { inputId, labelId, descId, errorId };
}
