import { injectable } from 'inversify';
import * as yup from 'yup';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

@injectable()
export class TagInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    associateTagsIdsSchema() {
        return yup.object().shape({
            tagsIds: yup.array().of(yup.number())
        });
    }
}
