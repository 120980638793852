import { injectable } from 'inversify';
import * as yup from 'yup';

@injectable()
export class PositionsCustomFieldInputService {
    positionsCustomFieldInputSchema() {
        return yup.object().shape({
            customFieldId: yup.number().required(),
            conditionValue: yup.mixed().required()
        });
    }
}
