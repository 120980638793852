import { CustomFieldVariety, FieldType } from '../generated/types';
import { TranslateFn } from '../services/translationService';
import { assertUnreachable } from '../util/assertUnreachable';

export const CREATABLE_FIELD_TYPES = [
    FieldType.Checkbox,
    FieldType.Country,
    FieldType.Date,
    FieldType.Datetime,
    FieldType.File,
    FieldType.Language,
    FieldType.Nationality,
    FieldType.Number,
    FieldType.Phone,
    FieldType.Select,
    FieldType.Text,
    FieldType.Textarea,
    FieldType.Time,
    FieldType.Validation
];

export function varietyToTranslation(t: TranslateFn, variety: CustomFieldVariety) {
    switch (variety) {
        case CustomFieldVariety.Position:
            return t('missions_63972');
        case CustomFieldVariety.UserInfo:
            return t('membres_11310');
        default:
            return assertUnreachable(variety);
    }
}
