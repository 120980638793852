import * as React from 'react';
import { Flex } from '../flex';
import { CSS } from '../stitches';

interface IHeaderRowProps {
    css?: CSS;
    children: React.ReactNode;
}

export const HeaderRow = (props: IHeaderRowProps) => (
    <Flex
        align="center"
        className="table-header-row"
        css={{
            background: '$gray50',
            border: '1px solid $gray200',
            fontSize: '$textXs',
            fontWeight: '$medium',
            height: '44px',
            width: '100%',
            ...(props.css as any)
        }}
    >
        {props.children}
    </Flex>
);
