import { Flex } from 'common/src/designSystem/components/flex';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

interface IRightPanelBodyProps {
    children: React.ReactNode;
    css?: CSS;
}

export const RightPanelBody = React.forwardRef(
    (props: IRightPanelBodyProps, ref: React.Ref<HTMLDivElement>) => (
        <Flex
            ref={ref}
            css={{
                flex: '1',
                overflowY: 'auto',
                px: '$6',
                ...(props.css as any)
            }}
            direction="column"
        >
            {props.children}
        </Flex>
    )
);
RightPanelBody.displayName = 'RightPanelBody';
