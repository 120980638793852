import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { theme } from 'common/src/designSystem/components/stitches';
import { scaleLinear } from 'd3-scale';
import { arc } from 'd3-shape';
import * as React from 'react';
import { percentColor } from './progressBar';

const WIDTH = 180;
const SIZE = 40;

interface IProgressGaugeProps {
    count: number;
    isErrorIfOver?: boolean;
    total: number;
}

export const ProgressGauge = (props: IProgressGaugeProps) => {
    const path = React.useMemo(
        () =>
            arc()
                .innerRadius((WIDTH - SIZE) / 2)
                .outerRadius(WIDTH / 2)
                .cornerRadius(20)
                .startAngle(-Math.PI / 2)
                .endAngle(Math.PI / 2),
        []
    );

    const percent = React.useMemo(() => {
        if (props.total === Infinity) {
            return 1;
        } else if (props.total === 0) {
            return Infinity;
        } else {
            return props.count / props.total;
        }
    }, [props.count, props.total]);
    const percentPath = React.useMemo(() => {
        const scale = scaleLinear()
            .domain([0, 1])
            .range([-Math.PI / 2, Math.PI / 2]);

        return arc()
            .innerRadius((WIDTH - SIZE) / 2)
            .outerRadius(WIDTH / 2)
            .cornerRadius(20)
            .startAngle(-Math.PI / 2)
            .endAngle(scale(percent <= 1.0 ? percent : 1.0));
    }, [percent]);

    return (
        <Box
            css={{
                position: 'relative',
                '& svg': {
                    height: '100%',
                    width: '100%'
                }
            }}
            height={WIDTH / 2}
            width={WIDTH}
        >
            <svg>
                <g transform={`translate(90, 90)`}>
                    <path d={path(undefined as any)!} fill={theme.colors.gray100.value} />

                    <path
                        d={percentPath(undefined as any)!}
                        fill={percentColor(percent, props.isErrorIfOver)}
                    />
                </g>
            </svg>

            <Flex
                align="baseline"
                css={{
                    bottom: '0',
                    left: '0',
                    position: 'absolute'
                }}
                gap="2"
                justify="center"
                width={1}
            >
                <Box color="gray900" fontSize="displaySm" fontWeight="semiBold">
                    {props.count}
                </Box>

                <Box color="gray900" fontSize="textLg" fontWeight="medium">
                    /{props.total === Infinity ? '∞' : props.total}
                </Box>
            </Flex>
        </Box>
    );
};
