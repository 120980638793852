import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    ALL_REGISTERDAYSDISPLAY,
    ALL_REGISTERSLOTDISPLAY,
    FormParametersAvailabilitiesInput,
    FormParametersAvailabilitiesQuery,
    RegisterDaysDisplay,
    RegisterSlotDisplay
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IUpdateFormParametersAvailabilitiesValues {
    form: FormParametersAvailabilitiesInput;
}

@injectable()
export class FormParametersAvailabilitiesInputService extends InputService {
    constructor(
        // TODO private customSlotInput: CustomSlotInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    formParametersAvailabilitiesInputDefault(
        form: FormParametersAvailabilitiesQuery['organization']['form']
    ): FormParametersAvailabilitiesInput {
        return {
            slotDisplay: form.slotDisplay || RegisterSlotDisplay.Hide,
            daysDisplay: form.daysDisplay || RegisterDaysDisplay.AllDays,
            customSlotsPeriods: form.customSlotsPeriods,
            slotTitle: form.slotTitle || '',
            slotSubtitle: form.slotSubtitle || ''
        };
    }

    formParametersAvailabilitiesInputSchema() {
        return yup.object().shape({
            slotDisplay: yup
                .string()
                .required(this.t('la_strat_gie_d_34961'))
                .oneOf(ALL_REGISTERSLOTDISPLAY, this.t('la_strat_gie_d_34961')),
            daysDisplay: yup
                .string()
                .required(this.t('la_strat_gie_de_56435'))
                .oneOf(ALL_REGISTERDAYSDISPLAY, this.t('la_strat_gie_de_56435'))
            /* TODO
            customSlots: yup
                .array()
                .of(this.customSlotInput.customSlotInputSchema())
                .when('slotDisplay', {
                    is: RegisterSlotDisplay.Custom,
                    then: yup
                        .array()
                        .of(this.customSlotInput.customSlotInputSchema())
                        .required(this.t('au_moins_un_cr_56018'))
                        .min(1, this.t('au_moins_un_cr_56018'))
                })
             */
        });
    }

    updateFormParametersAvailabilitiesSchema() {
        return yup.object().shape({
            form: this.formParametersAvailabilitiesInputSchema()
        });
    }
}
