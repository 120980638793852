import { loadImage } from 'common-front/src/util/image';
import { OrganizationId } from 'common/src/generated/types';
import * as React from 'react';
import { useOrganizationPublicQuery } from '../generated/graphqlHooks';
import { addCustomTheme } from './addCustomTheme';

interface IOrganizationPublicContext {
    bannerUrl?: string;
    logoUrl?: string;
}

const OrganizationPublicContext = React.createContext<IOrganizationPublicContext>({} as any);

interface IOrganizationPublicContextProviderProps {
    children: React.ReactNode;
    isLoading?: boolean;
    loadDelegationsSpace: boolean;
    loadMembersSpace: boolean;
    organizationId: OrganizationId;
    skeleton: React.ReactNode;
}

export const OrganizationPublicContextProvider = (
    props: IOrganizationPublicContextProviderProps
) => {
    const { data, isLoading } = useOrganizationPublicQuery({
        organizationId: props.organizationId,
        loadDelegationsSpace: props.loadDelegationsSpace,
        loadMembersSpace: props.loadMembersSpace
    });
    const [bannerUrl, setBannerUrl] = React.useState<string | undefined>(undefined);
    const [logoUrl, setLogoUrl] = React.useState<string | undefined>(undefined);
    const [areImagesLoading, setAreImagesLoading] = React.useState(true);

    React.useEffect(() => {
        if (data.organization) {
            addCustomTheme(
                data.organization?.delegationsSpaceDesign?.colors ??
                    data.organization.membersSpaceDesign?.colors ??
                    {}
            );

            (async () => {
                if (data.organization.delegationsSpaceDesign) {
                    await Promise.all([
                        loadImage(data.organization.delegationsSpaceDesign?.banner?.url),
                        loadImage(data.organization.delegationsSpaceDesign?.logo?.url)
                    ]);

                    setBannerUrl(data.organization.delegationsSpaceDesign?.banner?.url);
                    setLogoUrl(data.organization.delegationsSpaceDesign?.logo?.url);
                    setAreImagesLoading(false);
                } else if (data.organization.membersSpaceDesign) {
                    await Promise.all([
                        loadImage(data.organization.membersSpaceDesign?.banner?.url),
                        loadImage(data.organization.membersSpaceDesign?.logo?.url)
                    ]);

                    setBannerUrl(data.organization.membersSpaceDesign?.banner?.url);
                    setLogoUrl(data.organization.membersSpaceDesign?.logo?.url);
                    setAreImagesLoading(false);
                } else {
                    setAreImagesLoading(false);
                }
            })();
        }
    }, [data.organization]);

    return (
        <OrganizationPublicContext.Provider
            value={{
                bannerUrl,
                logoUrl
            }}
        >
            {isLoading || areImagesLoading || props.isLoading === true
                ? props.skeleton
                : props.children}
        </OrganizationPublicContext.Provider>
    );
};

export function useOrganizationPublicContext() {
    return React.useContext(OrganizationPublicContext);
}
