const _flattenObject = (object: any, parent: string | null = null, result: any = {}): any => {
    Object.entries(object).forEach(([key, value]) => {
        const name = parent ? `${parent}.${key}` : key;

        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            _flattenObject(value, name, result);
        } else {
            result[name] = value;
        }
    });

    return result;
};

export function flattenObject(object: any): any {
    return _flattenObject(object);
}
