import { Emptyable } from '../util/emptyable';

export const PRICE = 0.07;

const LENGTH_1_CHARACTERS = [
    ' ',
    '@',
    '£',
    '$',
    '¥',
    'è',
    'é',
    'ù',
    'ì',
    'ò',
    'Ç',
    'Ø',
    'ø',
    'Å',
    'å',
    'Æ',
    'æ',
    'ß',
    'É',
    '!',
    '"',
    '#',
    '¤',
    '%',
    '&',
    `'`,
    '(',
    ')',
    '*',
    '+',
    ',',
    '-',
    '.',
    '/',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    ':',
    ';',
    '<',
    '=',
    '>',
    '?',
    '¡',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'Ä',
    'Ö',
    'Ñ',
    'Ü',
    '§',
    '¿',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'ä',
    'ö',
    'ñ',
    'ü',
    'à'
];

const LENGTH_2_CHARACTERS = ['^', '{', '}', '\\', '|', '[', ']', '~', '€', '\n'];

const ALL_CHARACTERS = LENGTH_1_CHARACTERS.concat(LENGTH_2_CHARACTERS);

export function invalidCharacters(text: string | null | undefined): Set<string> {
    return new Set(Array.from(text ?? '').filter((c: string) => !ALL_CHARACTERS.includes(c)));
}

interface ISizeReturn {
    numberOfCharacters: number;
    numberOfSms: number;
}

export function size(text: Emptyable<string>, customSender: boolean): ISizeReturn {
    const limit = customSender ? 145 : 160;
    const numberOfCharacters = Array.from(text ?? '').reduce((currentSize: number, c: string) => {
        if (LENGTH_1_CHARACTERS.includes(c)) {
            return currentSize + 1;
        } else if (LENGTH_2_CHARACTERS.includes(c)) {
            return currentSize + 2;
        } else {
            return currentSize;
        }
    }, 0);

    if (numberOfCharacters <= limit) {
        return {
            numberOfCharacters,
            numberOfSms: 1
        };
    } else {
        return {
            numberOfCharacters,
            numberOfSms: Math.ceil(numberOfCharacters / 153)
        };
    }
}

export function applyMarkup(cost: number): number {
    return cost * (14 / 9);
}
