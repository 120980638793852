import { Button } from 'common-front/src/designSystem/components/button';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useDesktopQuery } from 'common-front/src/hooks/useDesktopQuery';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormId,
    FormMemberRegistrationFragment,
    MemberRegistrationUserInfoFragment
} from 'common/src/generated/types';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { UpdateFormSidebar } from './updateFormSidebar';

interface IUpdateFormProps {
    backPath: string;
    child: any;
    forms: FormMemberRegistrationFragment[];
    title: string;
    userInfo: MemberRegistrationUserInfoFragment;

    getEditPath(formId: FormId): string;
}

export const UpdateForm = ({
    backPath,
    child,
    forms,
    getEditPath,
    title,
    userInfo
}: IUpdateFormProps) => {
    const { history, translate } = useHeavent();
    const isDesktop = useDesktopQuery();

    const formsList = sortBy(forms, 'name').map((form, index) => (
        <Box
            key={form.id}
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs'
            }}
        >
            <Flex
                color="gray800"
                css={{
                    cursor:
                        window.location.pathname === getEditPath(form.id) ? 'inherit' : 'pointer',
                    fontWeight: '$medium',
                    fontSize: '$textMd',
                    padding: '$4'
                }}
                direction="row"
                gap="2"
                onClick={() => {
                    history.push(getEditPath(form.id));
                }}
            >
                {forms.length > 1 && (
                    <Flex color="gray700" css={{ fontSize: '$textXs' }} align={'center'}>
                        <Switch>
                            <Route
                                path={getEditPath(form.id)}
                                children={<I icon="chevron-right" />}
                            />

                            <Route children={<I icon="chevron-up" />} />
                        </Switch>
                    </Flex>
                )}

                {form.name}
            </Flex>

            <Route
                path={getEditPath(form.id)}
                children={
                    <>
                        <Separator direction="horizontal" />

                        <Box
                            css={{
                                padding: '$6'
                            }}
                        >
                            {child(form, index)}
                        </Box>
                    </>
                }
            />
        </Box>
    ));

    return (
        <>
            {isDesktop ? (
                <Flex css={{ flex: '2' }} direction="column" gap="3">
                    <Flex gap="6">
                        <UpdateFormSidebar
                            backPath={backPath}
                            forms={forms}
                            getEditPath={getEditPath}
                            title={title}
                            userInfo={userInfo}
                        />

                        <Flex css={{ flex: '2' }} direction="column" gap="3">
                            {formsList}
                        </Flex>
                    </Flex>
                </Flex>
            ) : (
                <Flex css={{ height: '100%' }} direction={'column'}>
                    <Box css={{ flex: 1 }}>
                        <Flex direction="column" gap="2" css={{ paddingBottom: '$11' }}>
                            <Button
                                leftIcon="arrow-left"
                                color="invisible"
                                onClick={() => {
                                    history.push(backPath);
                                }}
                            >
                                {translate('retour_92202')}
                            </Button>

                            <Box font="gray900 displayXs semiBold">{title}</Box>

                            <Spacer height="4" />

                            {formsList}
                        </Flex>
                    </Box>
                </Flex>
            )}
        </>
    );
};
