import { injectable } from 'inversify';
import * as yup from 'yup';
import { FormInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface ICreateFormValues {
    form: FormInput;
}

@injectable()
export class FormInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    formInputDefault(): FormInput {
        return {
            name: ''
        };
    }

    formInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.t('le_nom_est_requ_61536'))
        });
    }

    createFormSchema() {
        return yup.object().shape({
            form: this.formInputSchema()
        });
    }
}
