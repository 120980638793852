import {
    EventId,
    Form,
    FormsUsersInfosWishedFragment,
    OrganizationId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { IntervalService } from 'common/src/services/intervalService';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { fullName } from 'common/src/vo/accreditationSlot';
import { sortBy } from 'lodash-es';

interface IWishedPosition {
    name: string;
    range: string;
    path: string;
    form: Pick<Form, 'id' | 'name' | 'positionRanking'>;
    rank: number;
}

export function getWishedPositions(
    formsUsersInfos: FormsUsersInfosWishedFragment['formsUsersInfos'],
    intervalService: IntervalService,
    organizationId: OrganizationId,
    eventId: EventId
): IWishedPosition[] {
    return sortBy(
        formsUsersInfos
            .map((formUserInfo) => {
                const positions = formUserInfo.positions.map((position, positionIndex) => ({
                    name: position.name,
                    range: intervalService.toDisplayString(position.range),
                    path: HeaventPaths.POSITION(organizationId, eventId, position.id),
                    form: formUserInfo.form,
                    rank: positionIndex + 1
                }));
                const categories = formUserInfo.positionsCategories.map(
                    (category, categoryIndex) => ({
                        name: category.name,
                        range: category.range
                            ? intervalService.toDisplayString(category.range)
                            : '-',
                        path: HeaventPaths.POSITION_CATEGORY(organizationId, eventId, category.id),
                        form: formUserInfo.form,
                        rank: categoryIndex + 1
                    })
                );
                const slots = formUserInfo.positionsSlots.map((slot) => ({
                    name: slot.position.name,
                    range: slot.nameOrRange,
                    path: HeaventPaths.POSITION(organizationId, eventId, slot.position.id),
                    form: formUserInfo.form,
                    rank: -1
                }));

                return positions.concat(categories).concat(slots);
            })
            .flat(),
        [(p) => p.form.name, (_p, index) => index]
    );
}

interface IWishedAccreditation {
    name: string;
    subtitle: string;
    path: string;
}

export function getWishedAccreditations(
    formsUsersInfos: FormsUsersInfosWishedFragment['formsUsersInfos'],
    dateTimeService: DateTimeService,
    organizationId: OrganizationId,
    eventId: EventId
): IWishedAccreditation[] {
    return formsUsersInfos.flatMap((formUserInfo) =>
        formUserInfo.accreditationsSlots.map((accreditationSlot) => {
            const slotFullName = fullName(
                dateTimeService,
                accreditationSlot,
                accreditationSlot.accreditation.name,
                { defaultName: '-' }
            );

            return {
                name: accreditationSlot.accreditation.name,
                subtitle: slotFullName,
                path: AccreditationsPaths.ACCREDITATION({
                    organizationId,
                    eventId,
                    accreditationId: accreditationSlot.accreditation.id
                })
            };
        })
    );
}
