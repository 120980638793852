import { CommonEnvVars } from './envVars';
import { assertUnreachable } from './util/assertUnreachable';

export enum HeaventEnv {
    Amicale = 'amicale',
    Mso = 'mso',
    Recrewteer = 'recrewteer'
}

export function heaventEnvDisplayName() {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return 'Basile';
        case HeaventEnv.Mso:
            return 'Vabene';
        case HeaventEnv.Recrewteer:
            return 'Recrewteer';
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
}

export function heaventEnvLogo() {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return 'https://assets.recrewteer.com/logos/amicale.png';
        case HeaventEnv.Mso:
            return 'https://assets.recrewteer.com/logos/vabene.png';
        case HeaventEnv.Recrewteer:
            return 'https://assets.recrewteer.com/logos/recrewteer.png';
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
}

export function heaventEnvTermsUrl() {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return 'https://basile.amicaledesbenevoles.org/';
        case HeaventEnv.Mso:
            return 'https://www.mso.swiss/documents/term-of-use.pdf';
        case HeaventEnv.Recrewteer:
            return 'https://recrewteer.com/cgu/';
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
}

export function heaventEnvSupportEmail() {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
        case HeaventEnv.Mso:
            return CommonEnvVars.EMAIL_FROM;
        case HeaventEnv.Recrewteer:
            return `support@recrewteer.com`;
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
}
