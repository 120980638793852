import * as React from 'react';
import { theme } from '../../designSystem/components/stitches';
import { isNonEmptyString } from '../../util/string';

const getFirstLetters = (str: string, separator: string) =>
    str
        .split(separator)
        .map((part) => {
            if (part.trim().length > 0) {
                return part.trim()[0];
            } else {
                return '';
            }
        })
        .join('')
        .substring(0, 3);

const getInitials = (name: string | null | undefined, email: string | null | undefined) => {
    if (isNonEmptyString(name)) {
        return getFirstLetters(name, ' ');
    } else if (isNonEmptyString(email)) {
        const [beforeAt] = email.split('@');

        return getFirstLetters(beforeAt, '.');
    } else {
        return '';
    }
};

const backgroundColors = [
    theme.colors.success600.value,
    theme.colors.blueGray600.value,
    theme.colors.blueLight600.value,
    theme.colors.purple600.value,
    theme.colors.pink600.value,
    theme.colors.warning600.value
];

export type AvatarSize = 24 | 32 | 40 | 48 | 56 | 64 | 96 | 110 | 160 | 310;

const getFontSize = (avatarSize: AvatarSize) => {
    switch (avatarSize) {
        case 24:
            return 10;
        case 32:
            return 14;
        case 40:
            return 16;
        case 48:
            return 18;
        case 56:
            return 20;
        case 64:
            return 24;
        case 96:
            return 36;
        case 110:
            return 40;
        case 160:
            return 48;
        case 310:
            return 120;
    }
};

interface IAvatarProps {
    border?: string;
    name?: string | null;
    email?: string | null;
    image?: string | null;
    size: AvatarSize;
}

export const Avatar = (props: IAvatarProps) => {
    const [image, setImage] = React.useState(
        isNonEmptyString(props.image) ? props.image : undefined
    );

    React.useEffect(() => {
        if (isNonEmptyString(props.image)) {
            setImage(props.image);
        }
    }, [props.image]);

    if (isNonEmptyString(image)) {
        return (
            <div
                style={{
                    border: props.border,
                    borderRadius: props.size + 'px',
                    height: `${props.size}px`,
                    overflow: 'hidden',
                    width: `${props.size}px`
                }}
            >
                <img
                    alt="Avatar"
                    src={image}
                    style={{
                        height: `${props.size}px`,
                        width: `${props.size}px`
                    }}
                />
            </div>
        );
    } else {
        const initials = getInitials(props.name, props.email);
        const firstLetterCode = initials.charCodeAt(0);

        return (
            <div
                style={{
                    borderRadius: props.size + 'px',
                    height: props.size + 'px',
                    overflow: 'hidden',
                    width: props.size + 'px'
                }}
            >
                <div
                    className="h-avatar-initials"
                    style={{
                        alignItems: 'center',
                        background: backgroundColors[firstLetterCode % backgroundColors.length],
                        color: 'white',
                        display: 'flex',
                        fontSize: `${getFontSize(props.size)}px`,
                        height: '100%',
                        justifyContent: 'center',
                        textTransform: 'uppercase',
                        width: '100%'
                    }}
                >
                    {initials}
                </div>
            </div>
        );
    }
};
