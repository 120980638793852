import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormMissionsOptionsFragment,
    FormMissionsQuery,
    PositionId,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { Fields } from 'common/src/vo/field';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { FormMissionsPositionsCategory } from './formMissionsPositionsCategory';

interface IFormMissionsPositionsProps {
    categories: FormMissionsQuery['event']['positionsCategories'];
    options: FormMissionsOptionsFragment;
    positionsIds: PositionId[];
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfoFields: Fields;

    change(name: string, value: any): void;
}

export const FormMissionsPositions = (props: IFormMissionsPositionsProps) => {
    const categories = React.useMemo(
        () => sortBy(props.categories, (c) => c.name),
        [props.categories]
    );

    return (
        <>
            {categories.map((category, index) => (
                <React.Fragment key={category.id}>
                    {index !== 0 && <Spacer height="6" />}

                    <FormMissionsPositionsCategory
                        category={category}
                        change={props.change}
                        options={props.options}
                        positionsIds={props.positionsIds}
                        prefix={props.prefix}
                        slots={props.slots}
                        userInfoFields={props.userInfoFields}
                    />
                </React.Fragment>
            ))}
        </>
    );
};
