import {
    FormRegisterCustomSlotPeriodFragment,
    FormSlotsQuery,
    RegisterDaysDisplay,
    RegisterSlotDisplay,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import * as React from 'react';
import { FormSlotsCalendar } from './calendar/formSlotsCalendar';
import { FormSlotsCustom } from './custom/formSlotsCustom';
import { FormSlotsDays } from './days/formSlotsDays';
import { FormSlotsSlots } from './slots/formSlotsSlots';

interface IFormSlotsProps {
    currentSlots: VolunteersRegistrationsSlotInput[];
    customSlotsPeriods: FormRegisterCustomSlotPeriodFragment[];
    daysDisplay: RegisterDaysDisplay;
    event: FormSlotsQuery['event'];
    prefix: string;
    slotDisplay: RegisterSlotDisplay;

    change(name: string, value: any): void;
}

export const FormSlots = (props: IFormSlotsProps) => {
    switch (props.slotDisplay) {
        case RegisterSlotDisplay.Display:
            return (
                <FormSlotsSlots
                    max={props.event.endAt}
                    min={props.event.startAt}
                    prefix={props.prefix}
                />
            );
        case RegisterSlotDisplay.DisplayDays:
            return (
                <FormSlotsDays
                    change={props.change}
                    currentSlots={props.currentSlots}
                    daysDisplay={props.daysDisplay}
                    endAt={props.event.endAt}
                    prefix={props.prefix}
                    ranges={props.event.ranges}
                    startAt={props.event.startAt}
                />
            );
        case RegisterSlotDisplay.Calendar:
            return (
                <FormSlotsCalendar
                    change={props.change}
                    currentSlots={props.currentSlots}
                    endAt={props.event.endAt}
                    prefix={props.prefix}
                    startAt={props.event.startAt}
                />
            );
        case RegisterSlotDisplay.Custom:
            return (
                <FormSlotsCustom
                    change={props.change}
                    currentSlots={props.currentSlots}
                    customSlotsPeriods={props.customSlotsPeriods}
                    daysDisplay={props.daysDisplay}
                    event={props.event}
                    prefix={props.prefix}
                />
            );
        case RegisterSlotDisplay.Hide:
            return null;
        default:
            return assertUnreachable(props.slotDisplay);
    }
};
