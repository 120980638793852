import { Empty } from 'common-front/src/components/empty/empty';
import { useUserContext } from 'common-front/src/userContext';
import { Images } from 'common-front/src/util/assets';
import { LocalStorageKeys } from 'common-front/src/util/localStorage';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { EventId, OrganizationId } from 'common/src/generated/types';
import {
    THIRD_PARTY_DOMAINS,
    thirdPartyDomainToOrganizationId
} from 'common/src/thirdPartyDomains';
import { useLocalStorage, useTranslate } from 'common/src/util/dependencies/dependencies';
import { VolunteersPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import Logo from 'svgComponents/logo/logo.svg';

export const Route404 = () => {
    const translate = useTranslate();
    const { user } = useUserContext();
    const localStorage = useLocalStorage();
    let organizationId: OrganizationId | null = null;
    let eventId: EventId | null = null;

    try {
        organizationId = localStorage.getItemInt(
            LocalStorageKeys.ORGANIZATION_ID
        ) as OrganizationId;
        eventId = localStorage.getItemInt(LocalStorageKeys.EVENT_ID) as EventId;
    } catch {
        // do nothing
    }

    if (THIRD_PARTY_DOMAINS.includes(location.host)) {
        organizationId = thirdPartyDomainToOrganizationId[window.location.host];

        return <Empty path={`/v2/organization/${organizationId}/auth`} />;
    } else if (organizationId && eventId) {
        if (user) {
            return <Empty path={VolunteersPaths.ASSIGNMENTS(organizationId, eventId)} />;
        } else {
            return <Empty path={VolunteersPaths.AUTH(organizationId, eventId)} />;
        }
    } else {
        return (
            <Flex
                direction="column"
                align="center"
                justify="center"
                css={{
                    backgroundImage: `url(${Images.Background404})`,
                    backgroundSize: 'cover',
                    height: '100%',
                    width: '100%'
                }}
            >
                <Box css={{ marginBottom: '100px', width: '300px' }}>
                    <Logo />
                </Box>

                <Box css={{ color: 'white', fontSize: '24px' }}>
                    {translate('il_n_y_a_rien_33749')}
                </Box>
            </Flex>
        );
    }
};
