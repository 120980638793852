import { CommonEnvVars } from './envVars';
import { OrganizationId } from './generated/types';
import { HeaventEnv } from './heaventEnv';

export const WWW_PARTAGETAPASSION_COM = 'www.partagetapassion.com';

export const STAFF_INSATIABLES_FR = 'staff.insatiables.fr';

export const CLUB_FRANCE_2024 = 'www.club-france-2024-inscriptions.fr';

export const THIRD_PARTY_DOMAINS = [
    WWW_PARTAGETAPASSION_COM,
    STAFF_INSATIABLES_FR,
    CLUB_FRANCE_2024
];

export const thirdPartyDomainToOrganizationId: { [domain: string]: OrganizationId } = {
    [WWW_PARTAGETAPASSION_COM]: 858 as OrganizationId,
    [STAFF_INSATIABLES_FR]: 1369 as OrganizationId,
    [CLUB_FRANCE_2024]: 1795 as OrganizationId
};

export function displayPartagetapassiongLinks(organizationId: OrganizationId) {
    return (
        CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer &&
        organizationId === thirdPartyDomainToOrganizationId[WWW_PARTAGETAPASSION_COM]
    );
}

export function displayStaffInsatiablesFrLinks(organizationId: OrganizationId) {
    return (
        CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer &&
        organizationId === thirdPartyDomainToOrganizationId[STAFF_INSATIABLES_FR]
    );
}

export function displayClubFrance2024Links(organizationId: OrganizationId) {
    return (
        CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer &&
        organizationId === thirdPartyDomainToOrganizationId[CLUB_FRANCE_2024]
    );
}
