import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    isClubFrance2024,
    isPartagetapassionCom,
    isStaffInsatiablesFr
} from 'common-front/src/thirdPartyDomains';
import { THIRD_PARTY_DOMAINS } from 'common/src/thirdPartyDomains';
import * as React from 'react';
import { AsoProfilesList } from './aso/asoProfilesList';
import { ProfilesList } from './profilesList';

export const ProfilesListRouter = () => {
    const {
        history,
        params: { organizationId }
    } = useHeavent();

    if (isPartagetapassionCom(organizationId)) {
        return <AsoProfilesList />;
    } else if (isStaffInsatiablesFr(organizationId) || isClubFrance2024(organizationId)) {
        return <ProfilesList />;
    } else if (THIRD_PARTY_DOMAINS.includes(location.host)) {
        history.push('/');

        return null;
    } else {
        return <ProfilesList />;
    }
};
