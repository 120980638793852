import { injectable } from 'inversify';
import { parse } from 'papaparse';
import * as yup from 'yup';
import { TranslationService } from '../services/translationService';
import { fileInfos } from '../util/file';
import { MimeType, mimeType } from '../util/mimeType';
import { isNonEmptyString } from '../util/string';
import { InputService } from './inputService';

export const MAX_CSV_SIZE = 20_000_000; // 20 MB. Arbitrary.

@injectable()
export class CsvInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    schema() {
        const required = this.t('le_document_est_86403');

        return yup.object().shape({
            acl: yup.string().required(required),
            key: yup
                .string()
                .required(required)
                .test(
                    'is-a-csv',
                    this.t('le_fichier_n_es_33134'),
                    (key: string) => mimeType(fileInfos(key).extension) === MimeType.Csv
                ),
            name: yup.string().required(required),
            file: yup
                .mixed()
                .test('header-is-valid', this.t('le_fichier_csv_14924'), (file) => {
                    if (!file) {
                        return true;
                    } else {
                        return new Promise((resolve) => {
                            parse<string[]>(file as File, {
                                preview: 1,
                                complete: ({ data, errors }) => {
                                    if (
                                        errors.length ||
                                        data[0].filter((column) => isNonEmptyString(column))
                                            .length === 0
                                    ) {
                                        resolve(false);
                                    } else {
                                        resolve(true);
                                    }
                                }
                            });
                        });
                    }
                })
                .test('is-not-too-big', this.t('le_fichier_est_17522'), (file) =>
                    file ? (file as File).size < MAX_CSV_SIZE : true
                )
        });
    }
}
