import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { ExistingUserRow } from 'common-front/src/users/create/existingUserRow';
import { ExistingUserRowSkeleton } from 'common-front/src/users/create/existingUserRowSkeleton';
import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UsersInfoId } from 'common/src/generated/types';
import { addOrRemove, isNonEmptyArray } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import {
    useParentUsersInfosQuery,
    useUsersInfosDelegationAddMutation
} from '../../../../generated/graphqlHooks';

interface IAddParentUsersInfosProps {
    onClose(): void;
}

export const AddParentUsersInfos = (props: IAddParentUsersInfosProps) => {
    const {
        translate,
        params: { organizationId, delegationId }
    } = useHeavent();
    const { mutate, isLoading: isMutateLoading } = useUsersInfosDelegationAddMutation();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const { data, isLoading } = useParentUsersInfosQuery({
        organizationId,
        delegationId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined
    });
    const [selectedUsersInfosIds, setSelectedUsersInfosIds] = React.useState<UsersInfoId[]>([]);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Box color="gray800" css={{ height: '56px', textAlign: 'center' }}>
                <img alt="Avatars" src={Images.AvatarGroup} height="100%" />
            </Box>

            <Spacer height="6" />

            <Box color="gray800" fontSize="textLg" fontWeight="medium" textAlign="center">
                {translate('ajouter_un_memb_33261')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500" textAlign="center">
                {translate('ajouter_un_ou_d_21477')}
            </Box>

            <Spacer height="6" />

            <TextInput
                icon="magnifying-glass"
                placeholder={translate('rechercher_un_m_05904')}
                value={name}
                onChange={setName}
            />

            <Spacer height="6" />

            <Flex direction="column" css={{ flex: '1', overflowY: 'auto' }}>
                {isLoading ? (
                    <>
                        <ExistingUserRowSkeleton />
                        <ExistingUserRowSkeleton />
                        <ExistingUserRowSkeleton />
                    </>
                ) : (
                    data.organization.delegation.parentUsersInfos.map((userInfo) => {
                        const state = selectedUsersInfosIds.includes(userInfo.id)
                            ? 'checked'
                            : 'unchecked';

                        return (
                            <ExistingUserRow
                                key={userInfo.id}
                                state={state}
                                userInfo={userInfo}
                                onClick={() => {
                                    setSelectedUsersInfosIds(
                                        addOrRemove(
                                            selectedUsersInfosIds,
                                            userInfo.id,
                                            state === 'unchecked'
                                        )
                                    );
                                }}
                            />
                        );
                    })
                )}
            </Flex>

            <Spacer height="7" />

            <Flex align="center" justify="end" gap="3">
                <Button
                    disabled={!isNonEmptyArray(selectedUsersInfosIds)}
                    isLoading={isMutateLoading}
                    onClick={async () => {
                        await mutate({
                            organizationId,
                            delegationId,
                            usersInfosIds: selectedUsersInfosIds
                        });

                        location.reload();
                    }}
                >
                    {translate('ajouter_97327')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
