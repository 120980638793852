import { injectable } from 'inversify';
import * as yup from 'yup';
import { MassAddEventInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IMassAddEventValues {
    massAddEvent: MassAddEventInput;
}

@injectable()
export class MassAddEventInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    massAddEventInputSchema() {
        return yup.object().shape({
            massAddEvent: yup.object().shape({
                eventId: yup
                    .number()
                    .required(this.t('l_v_nement_est_88155'))
                    .typeError(this.t('l_v_nement_est_88155'))
            })
        });
    }

    massAddEventSchema() {
        return yup.object().shape({
            eventId: yup
                .number()
                .required(this.t('l_v_nement_est_88155'))
                .typeError(this.t('l_v_nement_est_88155')),
            usersInfosIds: yup
                .array()
                .of(yup.number())
                .required(this.t('vous_devez_s_le_83608'))
                .min(1, this.t('vous_devez_s_le_83608'))
        });
    }
}
