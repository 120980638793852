import { loadImage } from 'common-front/src/util/image';
import { Box } from 'common/src/designSystem/components/box';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface ISpaceBannerProps {
    bannerUrl?: string;
    isLoading: boolean;
}

export const SpaceBanner = ({ bannerUrl, isLoading }: ISpaceBannerProps) => {
    const [isBannerLoading, setIsBannerLoading] = React.useState(true);

    React.useEffect(() => {
        (async () => {
            if (isNonEmptyString(bannerUrl)) {
                setIsBannerLoading(true);
                await loadImage(bannerUrl);
                setIsBannerLoading(false);
            }
        })();
    }, [bannerUrl]);

    if (isLoading || isNonEmptyString(bannerUrl)) {
        return isBannerLoading ? (
            <Skeleton
                css={{
                    minHeight: '115px',
                    width: '100%',
                    '@tablet': {
                        minHeight: '230px'
                    },
                    '@desktop': {
                        borderBottomLeftRadius: '$2',
                        borderBottomRightRadius: '$2',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        minHeight: '360px',
                        width: '$container'
                    }
                }}
            />
        ) : (
            <Box
                css={{
                    background: `url(${bannerUrl}) no-repeat center`,
                    backgroundSize: 'cover',
                    minHeight: '115px',
                    width: '100%',
                    '@tablet': {
                        minHeight: '230px'
                    },
                    '@desktop': {
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        minHeight: '360px',
                        width: '$container'
                    }
                }}
            />
        );
    } else {
        return null;
    }
};
