import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { CommonEnvVars } from '../envVars';
import {
    OrganizationInfosInput,
    OrganizationInput,
    OrganizationParametersInfosQuery
} from '../generated/types';
import { HeaventEnv } from '../heaventEnv';
import { CountriesService } from '../services/countriesService';
import { TranslationService } from '../services/translationService';
import { isClean } from '../util/email';
import { InputService } from './inputService';
import { PhoneInputService } from './phoneInput';

export interface ICreateOrganizationValues {
    organization: OrganizationInput;
}

export interface IUpdateOrganizationGeneralValues {
    organization: OrganizationInfosInput;
}

@injectable()
export class OrganizationInputService extends InputService {
    constructor(
        private countriesService: CountriesService,
        private phoneInputService: PhoneInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    organizationInputDefault(): OrganizationInput {
        const countryValues = this.countriesService.getDefaultCountryValues();

        return {
            name: '',
            adminFirstName: '',
            adminLastName: '',
            adminPhone: this.phoneInputService.phoneInputDefault(countryValues)
        };
    }

    organizationInputSchema(termsAccepted: boolean) {
        let yupObject = yup.object().shape({
            name: yup.string().required(this.t('le_nom_de_l_org_60618')),
            adminFirstName: yup.string().required(this.t('le_pr_nom_est_r_10935')),
            adminLastName: yup.string().required(this.t('le_nom_est_requ_61536')),
            adminPhone: this.phoneInputService.phoneInputSchema()
        });

        if (!termsAccepted && CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer) {
            yupObject = yupObject.shape({
                termsAccepted: yup
                    .boolean()
                    .oneOf([true], this.t('il_est_n_cessai_83896'))
                    .required(this.t('il_est_n_cessai_83896'))
            });
        }

        return yupObject;
    }

    createOrganizationSchema(termsAccepted: boolean) {
        return yup.object().shape({
            organization: this.organizationInputSchema(termsAccepted)
        });
    }

    organizationInfosInputDefault(
        organization: OrganizationParametersInfosQuery['organization']
    ): OrganizationInfosInput {
        const countryValues = this.countriesService.getDefaultCountryValues();

        return {
            name: organization.name ?? '',
            adminName: organization.adminName ?? '',
            adminEmail: organization.adminEmail ?? '',
            street: organization.street ?? '',
            postalCode: organization.postalCode ?? '',
            city: organization.city ?? '',
            country: organization.country ?? countryValues.user.country,
            timeZone: organization.timeZone ?? DateTime.local().zoneName,
            emailSenderId: organization.emailSenderId,
            exportDateFormat: organization.exportDateFormat,
            exportDatetimeFormat: organization.exportDatetimeFormat,
            exportTimeFormat: organization.exportTimeFormat
        };
    }

    organizationInfosInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.t('le_nom_de_l_org_60618')),
            adminName: yup.string().required(this.t('le_nom_du_respo_43823')),
            adminEmail: yup
                .string()
                .required(this.t('l_e_mail_du_rep_90871'))
                .email(this.t('l_e_mail_du_rep_90871'))
                .test('clean-admin-email', this.t('l_e_mail_du_rep_90871'), function () {
                    return isClean(this.parent.adminEmail);
                }),
            street: yup.string().required(this.t('l_adresse_est_r_40489')),
            postalCode: yup.string().required(this.t('le_code_postal_43189')),
            city: yup.string().required(this.t('la_ville_est_re_35239')),
            country: yup
                .string()
                .oneOf(this.countriesService.countriesCodes)
                .required(this.t('le_pays_est_req_33749')),
            emailSenderId: yup.number().nullable(),
            exportDateFormat: yup.string().required(this.t('le_format_est_r_17097')),
            exportDatetimeFormat: yup.string().required(this.t('le_format_est_r_17097')),
            exportTimeFormat: yup.string().required(this.t('le_format_est_r_17097'))
        });
    }

    updateOrganizationGeneralSchema() {
        return yup.object().shape({
            organization: this.organizationInfosInputSchema()
        });
    }
}
