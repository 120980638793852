import { CommonEnvVars } from '../envVars';
import { DelegationId, EventId, FieldType, FormId, OrganizationId } from '../generated/types';
import { TranslateFn } from '../services/translationService';
import {
    CLUB_FRANCE_2024,
    displayClubFrance2024Links,
    displayPartagetapassiongLinks,
    displayStaffInsatiablesFrLinks,
    STAFF_INSATIABLES_FR,
    WWW_PARTAGETAPASSION_COM
} from '../thirdPartyDomains';
import { Emptyable } from '../util/emptyable';
import { isNonEmptyString } from '../util/string';

export type FormPreviewState = 'opened' | 'closed' | 'blocked';

interface IGetFormLinkOptions {
    organizationId: OrganizationId;
    eventId?: Emptyable<EventId>;
    delegationId?: Emptyable<DelegationId>;
    formId: FormId;
    teamCode?: Emptyable<string>;
}

export function getFormLink({
    organizationId,
    eventId,
    delegationId,
    formId,
    teamCode
}: IGetFormLinkOptions) {
    const path = (() => {
        if (delegationId) {
            return typeof eventId === 'number'
                ? `v2/organization/${organizationId}/event/${eventId}/form/${formId}/delegation/${delegationId}`
                : `v2/organization/${organizationId}/form/${formId}/delegation/${delegationId}`;
        } else {
            const basePath =
                typeof eventId === 'number'
                    ? `v2/organization/${organizationId}/event/${eventId}/form/${formId}`
                    : `v2/organization/${organizationId}/form/${formId}`;

            return isNonEmptyString(teamCode) ? `${basePath}/team/${teamCode}` : basePath;
        }
    })();

    if (displayPartagetapassiongLinks(organizationId)) {
        return `https://${WWW_PARTAGETAPASSION_COM}/${path}`;
    } else if (displayStaffInsatiablesFrLinks(organizationId)) {
        return `https://${STAFF_INSATIABLES_FR}/${path}`;
    } else if (displayClubFrance2024Links(organizationId)) {
        return `https://${CLUB_FRANCE_2024}/${path}`;
    } else {
        return `https://event.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}/${path}`;
    }
}

export function getFormField(id: FormId, t: TranslateFn) {
    return {
        name: t('commentaire_r_12290'),
        slug: `form${id}`,
        fieldType: FieldType.Textarea,
        values: []
    };
}
