import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SpaceBanner } from 'common-front/src/spaces/spaceBanner';
import { UpdateUserForm } from 'common-front/src/users/update/updateUserForm';
import { MembersPaths } from 'common/src/util/membersPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useUpdateMemberInfosQuery } from '../../../generated/graphqlHooks';

export const UpdateMemberLoader = () => {
    const {
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { data, loader } = useUpdateMemberInfosQuery({ organizationId, eventId, userInfoId });
    const forms = React.useMemo(
        () => (data.userInfo?.formsUsersInfos ?? []).map(({ form }) => form),
        [data.userInfo?.formsUsersInfos]
    );

    return (
        loader || (
            <Switch>
                <Route
                    path={MembersPaths.MEMBER_REGISTRATION_EDIT_FORM(
                        ':organizationId',
                        ':userInfoId',
                        ':eventId',
                        ':formId'
                    )}
                    children={
                        <UpdateUserForm
                            banner={
                                <SpaceBanner
                                    bannerUrl={data.organization?.membersSpaceDesign?.banner?.url}
                                    isLoading={false}
                                />
                            }
                            fallbackClosePath={MembersPaths.MEMBER_REGISTRATION_INFORMATION(
                                organizationId,
                                userInfoId,
                                eventId
                            )}
                            customFields={[]}
                            country={data.event.country}
                            formIdToInsertedAt={{}}
                            formIdToTeamCode={{}}
                            forms={forms}
                            showAllAndPrivate={false}
                            showIsFilled={false}
                            userInfo={data.userInfo}
                            getEditUserFormPath={(formId) =>
                                MembersPaths.MEMBER_REGISTRATION_EDIT_FORM(
                                    organizationId,
                                    userInfoId,
                                    eventId,
                                    formId
                                )
                            }
                        />
                    }
                />

                <Route
                    children={
                        <Empty
                            path={MembersPaths.MEMBER_REGISTRATION_EDIT_FORM(
                                organizationId,
                                userInfoId,
                                eventId,
                                data.userInfo.formsUsersInfos[0].form.id
                            )}
                            replace={true}
                        />
                    }
                />
            </Switch>
        )
    );
};
