import { IUseHistoryReturn } from 'common/src/hooks/types';
import { TranslateFn } from 'common/src/services/translationService';
import {
    useHistory,
    useParams,
    UseParamsReturn,
    useTranslate
} from 'common/src/util/dependencies/dependencies';

interface IUseHeaventResult<T> {
    history: IUseHistoryReturn;
    params: T & UseParamsReturn;
    translate: TranslateFn;
}

export function useHeavent<T>(): IUseHeaventResult<T> {
    return {
        history: useHistory(),
        params: useParams(),
        translate: useTranslate()
    };
}
