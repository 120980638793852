import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationsPaths } from 'common/src/delegationsPaths';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { DelegationsSpaceDelegationQuery, DelegationState } from 'common/src/generated/types';
import * as React from 'react';
import { AddParentUsersInfos } from './members/create/addParentUsersInfos';

interface IDelegationSpaceHeader {
    delegation: DelegationsSpaceDelegationQuery['organization']['delegation'];
    name: string;
}

export const DelegationsSpaceHeader = ({ delegation, name }: IDelegationSpaceHeader) => {
    const {
        history,
        translate,
        params: { organizationId, delegationId }
    } = useHeavent();
    const [addParentUsersInfosOpen, setAddParentUsersInfosOpen] = React.useState(false);
    const canEditMembers = delegation.state === DelegationState.Ongoing;

    return (
        <>
            <Flex gap="3">
                <Flex direction="column" gap="1" css={{ flex: '1' }}>
                    <Box font="gray900 displayXs semiBold">
                        {translate('bienvenue_1_42255', name || '')}
                    </Box>

                    <Box color="gray500">
                        {delegation.event
                            ? translate(
                                  'g_rer_votre_d_l_04420',
                                  delegation.name,
                                  delegation.event.name
                              )
                            : translate('g_rer_votre_d_l_49654', delegation.name)}
                    </Box>
                </Flex>

                {canEditMembers && delegation.eventId && delegation.parentId ? (
                    <Dropdown>
                        <Trigger>
                            <Button>{translate('ajouter_un_memb_33261')}</Button>
                        </Trigger>

                        <Menu placement="bottom-end">
                            <ItemIcon
                                icon="user-group"
                                onClick={() => {
                                    setAddParentUsersInfosOpen(true);
                                }}
                            >
                                {translate('ajouter_des_mem_06922')}
                            </ItemIcon>

                            <ItemIcon
                                icon="user-plus"
                                onClick={() => {
                                    history.push(
                                        DelegationsPaths.MEMBER_CREATE({
                                            organizationId,
                                            eventId: delegation.eventId,
                                            delegationId,
                                            formId: delegation.formId
                                        })
                                    );
                                }}
                            >
                                {translate('ajouter_un_nouv_29283')}
                            </ItemIcon>
                        </Menu>
                    </Dropdown>
                ) : canEditMembers ? (
                    <Button
                        onClick={() => {
                            history.push(
                                DelegationsPaths.MEMBER_CREATE({
                                    organizationId,
                                    eventId: delegation.eventId,
                                    delegationId,
                                    formId: delegation.formId
                                })
                            );
                        }}
                    >
                        {translate('ajouter_un_memb_33261')}
                    </Button>
                ) : null}
            </Flex>

            {addParentUsersInfosOpen && (
                <AddParentUsersInfos
                    onClose={() => {
                        setAddParentUsersInfosOpen(false);
                    }}
                />
            )}
        </>
    );
};
