import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Maybe } from 'common/src/generated/types';
import { NumberService } from 'common/src/services/numberService';
import { useService } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IAccreditationsSlotFillingCellProps {
    assignedResources: number;
    firstCellCss: any;
    limitIcon?: React.ReactNode;
    maxResources?: Maybe<number>;
    percent?: number;
    title?: string;
}

export const AccreditationsSlotFillingCell = ({
    assignedResources,
    firstCellCss,
    limitIcon,
    maxResources,
    percent,
    title
}: IAccreditationsSlotFillingCellProps) => {
    const { translate } = useHeavent();
    const numberService = useService(NumberService);

    return (
        <Cell css={{ ...firstCellCss, justifyContent: 'flex-start' }} gap="4">
            <Flex direction="column" gap="2" width={1}>
                {title && <Box fontWeight="semiBold">{title}</Box>}

                <Flex direction="row" gap="6">
                    <Box css={{ flex: '2.5' }}>
                        {translate(
                            '_1_2_membres_04485',
                            assignedResources,
                            numberService.toNumberOrInfinity(maxResources)
                        )}
                    </Box>

                    <Box color="gray400" css={{ flex: 'fit-content', textAlign: 'right' }}>
                        {maxResources !== Infinity && limitIcon && <>[{limitIcon}]</>}
                    </Box>

                    <Box css={{ flex: '3', marginBlock: 'auto' }}>
                        {percent !== undefined && (
                            <ProgressBar isErrorIfOver={true} percent={percent} />
                        )}
                    </Box>
                </Flex>
            </Flex>
        </Cell>
    );
};
