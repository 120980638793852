import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { VolunteersRegistrationsSlotInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';

@injectable()
export class VolunteerRegistrationSlotInputService {
    constructor(private translationService: TranslationService) {}

    volunteersRegistrationsSlotInputDefault(
        eventStartDate?: DateTime
    ): VolunteersRegistrationsSlotInput {
        return {
            startDate: eventStartDate || DateTime.invalid('Invalid'),
            startTime: '',
            endDate: eventStartDate || DateTime.invalid('Invalid'),
            endTime: ''
        };
    }

    volunteersRegistrationsSlotInputSchema(eventStartDate: DateTime, eventEndDate: DateTime) {
        // we add one day to eventEndDate because when we select slots we set the end date to the day after at midnight
        // for exemple if we select the 8th, the slot will be 08-00:00 to 09-00:00
        const finalEventEndDate = eventEndDate.plus({ day: 1 }).endOf('day');

        return yup.object().shape({
            startDate: DateTimeSchemaType.required(
                this.translationService.translate('la_date_de_d_bu_05030')
            )
                .typeError(this.translationService.translate('la_date_de_d_bu_05030'))
                .test(
                    'after-event-start-date',
                    this.translationService.translate(
                        'la_date_de_d_bu_68630',
                        eventStartDate.toLocaleString()
                    ),
                    (value: DateTime) => value >= eventStartDate
                ),
            startTime: yup
                .string()
                .required(this.translationService.translate('heure_de_d_but_90785')),
            endDate: DateTimeSchemaType.required(
                this.translationService.translate('la_date_de_fin_32246')
            )
                .typeError(this.translationService.translate('la_date_de_fin_32246'))
                .test(
                    'after-start-date',
                    this.translationService.translate('la_date_de_fin_00429'),
                    (_value, testContext) =>
                        DateTime.isDateTime(testContext.parent.startDate) &&
                        testContext.parent.startDate.isValid &&
                        DateTime.isDateTime(testContext.parent.endDate) &&
                        testContext.parent.endDate.isValid &&
                        testContext.parent.endDate >= testContext.parent.startDate
                )
                .test(
                    'before-event-end-date',
                    this.translationService.translate(
                        'la_date_de_fin_49125',
                        eventEndDate.toLocaleString()
                    ),
                    (value: DateTime) => value <= finalEventEndDate
                ),
            endTime: yup
                .string()
                .required(this.translationService.translate('l_heure_de_fin_78626'))
        });
    }
}
