import { Interval } from 'luxon';
import {
    FormMissionsOptionsFragment,
    FormRegisterCategoryFragment,
    VolunteersRegistrationsSlotInput
} from '../generated/types';
import { Fields } from './field';
import { canSelectV2 as canSelectPositionV2 } from './position';

export function canSelectV2(
    category: FormRegisterCategoryFragment,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    userFields: Fields,
    options: FormMissionsOptionsFragment
): boolean {
    return (
        !options.hiddenPositionsCategoriesIds.includes(category.id) &&
        (options.displayedPositionsCategoriesIds.length === 0 ||
            options.displayedPositionsCategoriesIds.includes(category.id)) &&
        category.positions.some((position) =>
            canSelectPositionV2(position, userFields, ranges, options)
        )
    );
}
