import { EventIdPathParam, OrganizationIdPathParam } from '../pathsTypes';
import { buildPath } from './commonPaths';
import { OrganizationsPaths } from './organizationsPaths';

const PathComponents = {
    ITEMS: {
        EVENTS: {
            EVENT: 'event',
            EVENTS: 'events'
        }
    }
};

const ItemPaths = {
    EVENTS: PathComponents.ITEMS.EVENTS.EVENTS,
    EVENT: (eventId: EventIdPathParam) => buildPath([PathComponents.ITEMS.EVENTS.EVENT, eventId])
};

export const EventsPaths = {
    EVENT: (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
        buildPath([OrganizationsPaths.ORGANIZATION(organizationId), ItemPaths.EVENT(eventId)])
};
