import { Box } from 'common/src/designSystem/components/box';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IItemProps {
    children: React.ReactNode;
    path: string;

    isActive?(pathname: string): boolean;
}

export const Item = ({ children, isActive: _isActive, path }: IItemProps) => {
    const location = useLocation();
    const isActive = _isActive?.(location.pathname) ?? location.pathname === path;

    return (
        <Box
            css={{
                background: isActive ? '$primary100' : 'transparent',
                borderRadius: '6px',
                color: isActive ? '$primary700' : '$gray500',
                cursor: isActive ? 'default' : 'pointer',
                padding: '$2 $3'
            }}
            to={path}
        >
            {children}
        </Box>
    );
};
