import { injectable } from 'inversify';
import { SegmentCustomFieldFragment } from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { PossibleColumn, SegmentService } from '../segment';
import { VolunteerDefaultColumns } from './volunteersSegmentsService';

@injectable()
export class PositionMembersSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getPositionMembersPossibleColumns(
        customFields: SegmentCustomFieldFragment[]
    ): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.Name,
                name: this.t('full_name')
            },
            {
                slug: VolunteerDefaultColumns.Positions,
                name: this.t('missions_affect_97811')
            },
            ...this.customFieldsToPossibleColumns(customFields)
        ];
    }
}
