import { injectable } from 'inversify';
import { Duration } from 'luxon';
import { TranslationService } from './translationService';

@injectable()
export class DurationService {
    constructor(private translationService: TranslationService) {}

    minutesToHoursMinutesString(minutes: number): string {
        const duration = Duration.fromObject(
            { minutes },
            { locale: this.translationService.language.toString().toLowerCase() }
        ).shiftTo('hours', 'minutes');

        if (duration.minutes === 0) {
            return duration.shiftTo('hours').toHuman({ unitDisplay: 'narrow' });
        } else if (duration.hours === 0) {
            return duration.shiftTo('minutes').toHuman({ unitDisplay: 'narrow' });
        } else {
            return duration.toHuman({ unitDisplay: 'narrow' });
        }
    }
}
