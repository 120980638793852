export function isValidNumber(n: number | null | undefined): boolean {
    return typeof n === 'number' && !isNaN(n);
}

export function toInt(n?: number | string | null): number | null {
    if (typeof n === 'number' && !isNaN(n)) {
        return Math.trunc(n);
    } else if (typeof n === 'string') {
        const intValue = parseInt(n, 10);

        return isNaN(intValue) ? null : intValue;
    } else {
        return null;
    }
}

export function pxToMm(px: number, dpi: number = 96) {
    return Math.round((px * 25.4) / dpi);
}
