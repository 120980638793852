import { useHistory, useParams } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IEmptyProps<State = undefined> {
    path?: string;
    replace?: boolean;
    state?: State;

    onLoad?(): void;
    push?(params: any): string;
}

export const Empty = <State extends {} | undefined>(props: IEmptyProps<State>) => {
    const params = useParams();
    const history = useHistory();
    React.useEffect(() => {
        if (isNonEmptyString(props.path)) {
            if (props.replace) {
                history.replace<State>(props.path, props.state);
            } else {
                history.push<State>(props.path, props.state);
            }
        }

        if (props.push) {
            history.push<State>(props.push(params), props.state);
        }

        if (props.onLoad) {
            props.onLoad();
        }
    }, []);

    return <div />;
};
