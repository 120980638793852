import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { EventId, LoginCodeSource, OrganizationId } from 'common/src/generated/types';
import { debugLog } from 'common/src/util/debug';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths, VolunteersPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Mutations } from '../../generated/graphqlHooks';
import { getUserPool, USER_EMAIL_KEY, USER_SESSION_KEY } from '../../util/aws/cognito';
import { executeQuery } from '../graphql/graphql';

export const MagicLink = () => {
    const { code, source, organizationId, eventId } = useParams<{
        code: string;
        source: LoginCodeSource;
        organizationId?: OrganizationId;
        eventId?: EventId;
    }>();

    React.useEffect(() => {
        const email = localStorage.getItem(USER_EMAIL_KEY);
        const session = localStorage.getItem(USER_SESSION_KEY);

        if (
            isNonEmptyString(email) &&
            isNonEmptyString(session) &&
            isNonEmptyString(code) &&
            isNonEmptyString(source)
        ) {
            localStorage.removeItem(USER_EMAIL_KEY);
            localStorage.removeItem(USER_SESSION_KEY);

            const user = new CognitoUser({
                Username: email,
                Pool: getUserPool()
            });

            (user as any).Session = session;

            user.setAuthenticationFlowType('CUSTOM_AUTH');

            user.sendCustomChallengeAnswer(code, {
                async onSuccess(result: CognitoUserSession) {
                    const token = result.getIdToken().getJwtToken();

                    await executeQuery(Mutations.UserCreate, { token });

                    if (organizationId && eventId && source === LoginCodeSource.VolunteerRegister) {
                        location.href = VolunteersPaths.REGISTER(organizationId, eventId);
                    } else if (
                        organizationId &&
                        eventId &&
                        source === LoginCodeSource.VolunteerAssignments
                    ) {
                        location.href = VolunteersPaths.ASSIGNMENTS(organizationId, eventId);
                    } else if (source === LoginCodeSource.App) {
                        location.href = HeaventPaths.HOME;
                    } else {
                        location.href = '/';
                    }
                },
                onFailure(e: any) {
                    debugLog('Could not sendCustomChallengeAnswer', e);

                    location.href = '/';
                }
            });
        } else {
            location.href = '/';
        }
    }, []);

    return null;
};
