import { isNonEmptyArray } from 'common/src/util/array';
import * as React from 'react';
import { useDelegationAccreditationsQuery } from '../../../generated/graphqlHooks';
import { useHeavent } from '../../../hooks/useHeavent';
import { DelegationAccreditations } from './delegationAccreditations';
import { DelegationAccreditationsBlank } from './delegationAccreditationsBlank';

interface IDelegationAccreditationsLoaderProps {
    isEventAdmin?: boolean;
}

export const DelegationAccreditationsLoader = (props: IDelegationAccreditationsLoaderProps) => {
    const {
        params: { organizationId, delegationId }
    } = useHeavent();

    const { data, loader, reload } = useDelegationAccreditationsQuery({
        organizationId,
        delegationId,
        onlyVisible: !props.isEventAdmin
    });

    if (loader) {
        return loader;
    } else if (!isNonEmptyArray(data.organization?.delegation.accreditationsSlots)) {
        return <DelegationAccreditationsBlank />;
    } else {
        return (
            <DelegationAccreditations
                delegation={data.organization.delegation}
                isEventAdmin={props.isEventAdmin}
                reload={reload}
            />
        );
    }
};
