import { Button } from 'common-front/src/designSystem/components/button';
import { useTabletQuery } from 'common-front/src/hooks/useTabletQuery';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { MemberDocumentsQuery } from 'common/src/generated/types';
import { NumberService } from 'common/src/services/numberService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IDocumentProps {
    document: MemberDocumentsQuery['userInfo']['documents']['nodes'][0];
}

export const Document = (props: IDocumentProps) => {
    const translate = useTranslate();
    const isTablet = useTabletQuery();
    const numberService = useService(NumberService);
    const sizeFormatter = React.useMemo(() => {
        try {
            return numberService.getFormatter();
        } catch {
            return null;
        }
    }, []);

    return (
        <Flex
            align="center"
            gap="4"
            css={{
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs',
                padding: '$4'
            }}
        >
            <Flex
                align="center"
                justify="center"
                height={40}
                width={40}
                css={{
                    background: '$gray100',
                    borderRadius: '$2',
                    color: '$gray700'
                }}
            >
                <I icon="file-lines" />
            </Flex>

            <Flex direction="column" gap="1" css={{ flex: '1' }}>
                <Box font="gray800 textSm medium">{props.document.name}</Box>

                {sizeFormatter && typeof props.document.size === 'number' && (
                    <Box color="gray500">
                        {sizeFormatter.format(props.document.size).replace(/^([\d,.]+)/, '$1 ')}
                    </Box>
                )}
            </Flex>

            <Button
                color="white"
                leftIcon="arrow-down-to-bracket"
                onClick={() => {
                    window.open(props.document.url, '_blank');
                }}
            >
                {isTablet && translate('t_l_charger_03283')}
            </Button>
        </Flex>
    );
};

export const DocumentSkeleton = () => <Skeleton height={78} width={1} borderRadius="$1" />;
