import { Badge } from 'common/src/designSystem/components/badge';
import { fromIconVO } from 'common/src/designSystem/components/i';
import { Icon } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';

export interface IBadgesPlusBadge {
    id: number | string;
    color: string;
    count?: number | string;
    icon: Emptyable<Icon>;
    text: string;
}

interface IBadgesPlusBadgeProps {
    badge: IBadgesPlusBadge;
}

export const BadgesPlusBadge = ({ badge }: IBadgesPlusBadgeProps) => (
    <Badge
        color={badge.color}
        count={badge.count}
        css={{
            cursor: 'inherit'
        }}
        leftIcon={badge.icon ? fromIconVO(badge.icon) : undefined}
        size="md"
    >
        {badge.text}
    </Badge>
);
