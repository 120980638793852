import { CurrentUserQuery } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import { executeQuery } from '../components/graphql/graphql';
import * as Cognito from '../util/aws/cognito';
import userQuery from './user.graphql';

export type CurrentUser = CurrentUserQuery['user'];

interface ICurrentUserResult {
    user: CurrentUser;
}

export async function currentUser(): Promise<ICurrentUserResult> {
    const token = await Cognito.getToken();

    if (isNonEmptyString(token)) {
        const { user } = await executeQuery<CurrentUserQuery>(userQuery, { token });

        return { user };
    } else {
        throw new Error('No token');
    }
}
