import { Blank } from 'common-front/src/components/blank/blank';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { MemberRegistrationQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Document } from '../../../../common/documents/document';

interface IMemberRegistrationDocumentsProps {
    campaigns: MemberRegistrationQuery['organization']['userInfo']['campaigns']['nodes'];
}

export const MemberRegistrationDocuments = ({ campaigns }: IMemberRegistrationDocumentsProps) => {
    const translate = useTranslate();
    return (
        <>
            <Spacer height="6" />

            {isNonEmptyArray(campaigns) ? (
                <>
                    {campaigns.map((campaign) => (
                        <>
                            <Spacer height="4" />

                            {isNonEmptyArray(campaign.documents) && (
                                <>
                                    <Box fontSize="textMd" fontWeight="bold">
                                        {campaign.name}
                                    </Box>

                                    <Spacer height="4" />

                                    {campaign.documents.map((document, index) => (
                                        <React.Fragment key={document.id}>
                                            {index !== 0 && <Spacer height="4" />}
                                            <Document document={document} />
                                        </React.Fragment>
                                    ))}
                                </>
                            )}
                        </>
                    ))}
                </>
            ) : (
                <Blank imageSrc={Svgs.FormsElementsBlank} title={translate('no_documents')} />
            )}
        </>
    );
};
