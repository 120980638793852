import * as React from 'react';
import { CurrentUser } from './vo/user';

export interface IUserContext {
    user: CurrentUser | null;
}

export const UserContext = React.createContext<IUserContext>({
    user: null
});

export function useUserContext(): IUserContext {
    return React.useContext(UserContext);
}
