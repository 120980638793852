import { CommonEnvVars } from '../envVars';
import { isNonEmptyString } from './string';

interface IGetDocumentFullPathOptions {
    lang?: string;
    date?: string;
    endDate?: string;
}

export function getDocumentFullPath(
    link: string,
    extension: 'html' | 'pdf',
    query: IGetDocumentFullPathOptions = {}
) {
    const queryString = Object.entries(query || {})
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

    return `https://api.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}/documents/${link}.${extension}${
        isNonEmptyString(queryString) ? `?${queryString}` : ''
    }`;
}
