import { IFragment } from 'common/src/generated/fragments';
import { IUseQueryReturns } from 'common/src/util/graphql/types';
import * as React from 'react';
import { getToken } from '../../util/aws/cognito';
import { ErrorBoundaryContext } from '../errorBoundary/errorBoundaryContext';
import { Loader } from '../loader/loader';
import { executeQuery } from './graphql';

const loader = <Loader />;

export function useQueryImpl<TResult, TParams extends Record<string, any>>(
    query: string,
    variables: TParams,
    fragments: IFragment[]
): IUseQueryReturns<TResult> {
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState<any>({});
    const [loadIndex, setLoadIndex] = React.useState(0);
    const { setIsError } = React.useContext(ErrorBoundaryContext);
    const variablesString = Object.keys(variables as any)
        .sort()
        .map((key: string) => {
            const value = (variables as any)[key];

            return `"${key}":${JSON.stringify(value)}`;
        })
        .join(',');

    React.useEffect(() => {
        let isCancelled = false;
        setIsLoading(true);

        ((cachedVariablesString: string) => {
            getToken()
                .then((token) =>
                    executeQuery<TResult>(query, {
                        reloadOnNewVersion: true,
                        variables,
                        token,
                        fragments
                    })
                )
                .then(
                    (result: TResult) => {
                        if (!isCancelled && cachedVariablesString === variablesString) {
                            setData(result);
                            setIsError(false);
                            setIsLoading(false);
                        }
                    },
                    () => {
                        if (!isCancelled && cachedVariablesString === variablesString) {
                            setIsError(true);
                        }
                    }
                );
        })(variablesString);

        return () => {
            isCancelled = true;
        };
    }, [query, variablesString, loadIndex]);

    let _loader: any = null;

    if (isLoading) {
        _loader = loader;
    }

    return {
        data,
        isLoading,
        loader: _loader,
        reload() {
            setLoadIndex(loadIndex + 1);
        }
    };
}
