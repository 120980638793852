import * as React from 'react';
import { useDebounce } from './useDebounce';

export function useStateDebounce<T>(
    defaultValue: T,
    time: number = 500
): [state: T, stateDebounced: T, setState: (state: T) => void] {
    const [state, setState] = React.useState<T>(defaultValue);
    const [stateDebounced, setStateDebounced] = React.useState<T>(defaultValue);
    const debounced = useDebounce((newState: T) => {
        setStateDebounced(newState);
    }, time);

    return [
        state,
        stateDebounced,
        (newState: T) => {
            setState(newState);
            debounced(newState);
        }
    ];
}
