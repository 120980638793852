import { UUID, uuidv4 } from 'common/src/util/uuid';
import * as React from 'react';
import { useBodyClick } from '../../../hooks/useBodyClick';
import { useEsc } from '../../../hooks/useEsc';

const H_DROPDOWN_OPEN = 'h-dropdown-open';

export function closeOtherDropdowns(
    element: { dispatchEvent(event: Event): boolean },
    uuid?: UUID
) {
    element.dispatchEvent(
        new CustomEvent(H_DROPDOWN_OPEN, {
            bubbles: true,
            detail: {
                uuid: uuid ?? ''
            }
        })
    );
}

interface IDropdownContext {
    isOpen: boolean;
    referenceElement: HTMLDivElement | null;
    popperElement: HTMLDivElement | null;

    setIsOpen(isOpen: boolean): void;
    setReferenceElement(node: HTMLDivElement): void;
    setPopperElement(node: HTMLDivElement): void;
}

const DropdownContext = React.createContext<IDropdownContext>({} as IDropdownContext);

interface IDropdownProps {
    children: React.ReactNode;
    doNotCloseOnOtherOpen?: boolean;
    isOpen?: boolean;

    onStateChange?(isOpen: boolean): void;
}

export const Dropdown = (props: IDropdownProps) => {
    const uuidRef = React.useRef(uuidv4());
    const [isOpen, setIsOpen] = React.useState(props.isOpen ?? false);
    const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
    const setIsOpenTriggerStateChange = React.useCallback(
        (open: boolean) => {
            setIsOpen(open);

            if (props.onStateChange) {
                props.onStateChange(open);
            }

            if (open === true && referenceElement) {
                closeOtherDropdowns(referenceElement, uuidRef.current);
            }
        },
        [referenceElement, setIsOpen, props.onStateChange]
    );
    const hDropdownOpenHandler = React.useCallback(
        (e: CustomEvent) => {
            if (e.detail.uuid !== uuidRef.current && props.doNotCloseOnOtherOpen !== true) {
                setIsOpenTriggerStateChange(false);
            }
        },
        [setIsOpenTriggerStateChange]
    );

    React.useEffect(() => {
        if (props.isOpen !== isOpen) {
            setIsOpen(props.isOpen ?? false);
        }
    }, [props.isOpen]);

    React.useEffect(() => {
        document.addEventListener(H_DROPDOWN_OPEN, hDropdownOpenHandler as any);

        return () => {
            document.removeEventListener(H_DROPDOWN_OPEN, hDropdownOpenHandler as any);
        };
    }, [hDropdownOpenHandler]);

    useEsc(() => {
        setIsOpenTriggerStateChange(false);
    });
    useBodyClick(() => {
        setIsOpenTriggerStateChange(false);
    });

    return (
        <DropdownContext.Provider
            value={{
                isOpen,
                referenceElement,
                popperElement,
                setIsOpen: setIsOpenTriggerStateChange,
                setReferenceElement,
                setPopperElement
            }}
        >
            {props.children}
        </DropdownContext.Provider>
    );
};

export function useDropdownContext() {
    return React.useContext(DropdownContext);
}
