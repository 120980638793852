import { injectable } from 'inversify';
import * as yup from 'yup';
import { SegmentId, WeezeventRuleInput, WeezeventRuleToEditQuery } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface ICreateUpdateWeezeventRuleValues {
    weezeventRule: WeezeventRuleInput;
}

@injectable()
export class WeezeventRuleInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    weezeventRuleDefault(
        weezeventRule?: WeezeventRuleToEditQuery['event']['weezeventRule']
    ): WeezeventRuleInput {
        return {
            name: weezeventRule?.name ?? '',
            segmentId: weezeventRule?.segmentId ?? (null as any as SegmentId),
            idQuestion: weezeventRule?.idQuestion ?? '',
            value: weezeventRule?.value ?? ''
        };
    }

    weezeventRuleInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.t('le_nom_de_votre_84851')),
            segmentId: yup.number().required(),
            idQuestion: yup.string().required(),
            value: yup.mixed().required()
        });
    }

    createWeezeventRuleSchema() {
        return yup.object().shape({
            weezeventRule: this.weezeventRuleInputSchema()
        });
    }
}
