import { IUseHistoryReturn } from 'common/src/hooks/types';
import { parseParamsIds } from 'common/src/util/params';
import * as H from 'history';
import { useHistory, useLocation, useParams } from 'react-router';

export function useParamsImpl<T>() {
    const params = useParams();

    return parseParamsIds<T>(params);
}

export function useHistoryImpl(): IUseHistoryReturn {
    return useHistory();
}

export function useLocationImpl(): H.Location<any> {
    return useLocation();
}
