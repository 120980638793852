/* tslint:disable:no-console */

import { CommonEnvVars } from 'common/src/envVars';
import { HeaventHeaders } from 'common/src/util/headers';
import * as React from 'react';
import { getToken } from './aws/cognito';

export const LogToken = () => {
    React.useEffect(() => {
        getToken().then((token) => {
            if (CommonEnvVars.LOG_TOKEN === 'true') {
                console.log(`"${HeaventHeaders.UserToken}": "${token || ''}"`);
            }
        });
    }, []);

    return null;
};
