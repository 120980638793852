import { injectable } from 'inversify';
import { SkidataConnectionInfoInput, SkidataConnectionInfosQuery } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface ISkidataConnectionInfoUpsertValues {
    connectionInfos: SkidataConnectionInfoInput;
}

@injectable()
export class SkidataConnectionInfoInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    connectionInfosInputDefault(
        connectionInfo?: SkidataConnectionInfosQuery['organization']['skidataConnectionInfos']
    ): SkidataConnectionInfoInput {
        return {
            url: connectionInfo?.url ?? '',
            issuer: connectionInfo?.issuer ?? '',
            receiver: connectionInfo?.receiver ?? '',
            synchronizedEventsIds: connectionInfo?.synchronizedEventsIds ?? [],
            seasonEventsIds: connectionInfo?.seasonEventsIds ?? [],
            place: connectionInfo?.place ?? ''
        };
    }
}
