import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    CustomBadge,
    DocumentsExportStrategy,
    DocumentType,
    SupportedLanguage
} from 'common/src/generated/types';
import { getDisplayName } from 'common/src/vo/customBadge';
import * as React from 'react';
import { Button } from '../../../designSystem/components/button';
import { Content } from '../../../designSystem/components/tooltip/content';
import { Tooltip } from '../../../designSystem/components/tooltip/tooltip';
import { Trigger } from '../../../designSystem/components/tooltip/trigger';
import { useEventDocumentsExportMutation } from '../../../generated/graphqlHooks';
import { useHeavent } from '../../../hooks/useHeavent';

interface IDelegationDocumentsProps {
    delegationsSpaceCustomBadges: CustomBadge[];
}

export const DelegationDocuments = (props: IDelegationDocumentsProps) => {
    const {
        translate,
        params: { eventId, delegationId }
    } = useHeavent();
    const { mutate, isLoading } = useEventDocumentsExportMutation();

    return (
        <Flex direction="column" gap="2">
            <Box font="gray900 textMd medium">{translate('documents_dispo_46996')}</Box>

            <Flex
                css={{
                    background: 'white',
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    flex: '1',
                    overflow: 'hidden'
                }}
                direction="column"
            >
                {props.delegationsSpaceCustomBadges.map((badge) => (
                    <Flex
                        key={badge}
                        align="center"
                        css={{
                            borderBottom: '1px solid $gray200',
                            padding: '$4 $6',
                            '&:last-child': {
                                borderBottom: 'none'
                            }
                        }}
                        gap="3"
                    >
                        <Flex
                            align="center"
                            css={{
                                background: '$blueGray100',
                                borderRadius: '12px',
                                color: '$blueGray700'
                            }}
                            height={40}
                            justify="center"
                            width={40}
                        >
                            <I icon="file-lines" />
                        </Flex>

                        <Box css={{ flex: '1' }} font="gray900 textSm medium">
                            {getDisplayName(badge)}
                        </Box>

                        <Tooltip>
                            <Trigger>
                                <Box>
                                    <Button
                                        color="white"
                                        isLoading={isLoading}
                                        leftIcon="download"
                                        onClick={() => {
                                            mutate({
                                                eventId,
                                                delegationId,
                                                documentsExport: {
                                                    badgeType: badge,
                                                    documentType: DocumentType.CustomBadge,
                                                    language: SupportedLanguage.Fr,
                                                    segmentsIds: [],
                                                    strategy: DocumentsExportStrategy.OnePerPage
                                                }
                                            });
                                        }}
                                    />
                                </Box>
                            </Trigger>

                            <Content placement="top">{translate('recevoir_les_do_31669')}</Content>
                        </Tooltip>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
};
