import { DateTime, Interval } from 'luxon';

export const exploit = Interval.fromDateTimes(
    DateTime.fromObject({
        year: 2024,
        month: 5,
        day: 17
    }).startOf('day'),
    DateTime.fromObject({
        year: 2024,
        month: 5,
        day: 19
    }).endOf('day')
);
