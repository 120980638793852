/* tslint:disable:no-bitwise */
import { NominalType } from './nominalType';

export type UUID = NominalType<string, 'UUID'>;

function getUuid<T extends string>(): T {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;

        return v.toString(16);
    }) as T;
}

export function uuidv4(): UUID {
    return getUuid<UUID>();
}
