import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface ITitleProps {
    children: React.ReactNode;
}

export const Title = (props: ITitleProps) => (
    <Box css={{ padding: '$2 $3' }} font="gray500 textXs semiBold">
        {props.children}
    </Box>
);
