import { injectable } from 'inversify';
import * as yup from 'yup';
import { EmailSenderToEditQuery, EmailsSenderInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { DocumentInputService } from './documentInput';
import { InputService } from './inputService';

export interface ICreateUpdateEmailSenderValues {
    emailSender: EmailsSenderInput;
}

@injectable()
export class EmailSenderInputService extends InputService {
    constructor(
        translationService: TranslationService,
        private documentInputService: DocumentInputService
    ) {
        super(translationService);
    }

    emailSenderInputDefault(
        emailSender?: EmailSenderToEditQuery['organization']['emailSender']
    ): EmailsSenderInput {
        return {
            header: emailSender?.header ?? this.documentInputService.documentInputDefault(),
            picture: emailSender?.picture ?? this.documentInputService.documentInputDefault(),
            footer: emailSender?.footer ?? this.documentInputService.documentInputDefault(),
            signature: emailSender?.signature ?? '',
            name: emailSender?.name ?? '',
            fromEmail: emailSender?.fromEmail ?? '',
            fromName: emailSender?.fromName ?? '',
            replyTo: emailSender?.replyTo ?? '',
            eventsIds: emailSender?.eventsIds ?? [],
            isOrganizationDefault: emailSender?.isOrganizationDefault ?? false
        };
    }

    emailSenderInputSchema() {
        return yup.object().shape({
            header: this.documentInputService.documentInputSchemaNotRequired(),
            picture: this.documentInputService.documentInputSchemaNotRequired(),
            signature: yup.string(),
            name: yup.string().required(this.t('le_label_est_re_86986')),
            fromEmail: yup.string().required(this.t('l_e_mail_de_l_56279')),
            fromName: yup.string().required(this.t('le_nom_de_l_me_01331')),
            replyTo: yup.string()
        });
    }

    createUpdateEmailSenderSchema() {
        return yup.object().shape({
            emailSender: this.emailSenderInputSchema()
        });
    }
}
