import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import * as React from 'react';
import { PaginationCell } from './pagination/paginationCell';
import { PaginationRow } from './pagination/paginationRow';

interface ITableFiltersProps {
    filters: React.ReactNode;
    headerCells: React.ReactNode;
    limit?: number;
    numberOfPages: number;
    offset: number;
    rows: React.ReactNode;
    showLimits?: boolean;
    showPagination?: boolean;
    title?: React.ReactNode;
    totalCount: number;

    setLimit?(limit: number): void;
    setOffset(offset: number): void;
}

export const TableFilters = ({
    filters,
    headerCells,
    limit,
    numberOfPages,
    offset,
    rows,
    setLimit,
    setOffset,
    showLimits = false,
    showPagination = true,
    title,
    totalCount
}: ITableFiltersProps) => (
    <Box>
        <Flex
            align="center"
            css={{
                background: 'white',
                border: title ? '1px solid $gray200' : 'none',
                borderBottom: 'none',
                borderTopLeftRadius: '$2',
                borderTopRightRadius: '$2',
                padding: title ? '$5 $6' : 'unset'
            }}
            gap="3"
        >
            {title && (
                <Box color="gray900" css={{ flex: '1' }} fontSize="textLg" fontWeight="medium">
                    {title}
                </Box>
            )}

            {filters}
        </Flex>

        <Table>
            <HeaderRow
                css={{
                    borderRadius: title ? '0 !important' : '$2 $2 0 0 !important',
                    borderTop: title ? 'none' : '1px solid $gray200'
                }}
            >
                {headerCells}
            </HeaderRow>

            {rows}

            {(showLimits || showPagination) && totalCount > 0 && (
                <PaginationRow>
                    <PaginationCell
                        limit={limit}
                        numberOfPages={numberOfPages}
                        offset={offset}
                        setLimit={setLimit}
                        setOffset={setOffset}
                        showLimits={showLimits}
                        totalCount={totalCount}
                    />
                </PaginationRow>
            )}
        </Table>
    </Box>
);
