import { Blank } from 'common-front/src/components/blank/blank';
import { KpiCard } from 'common-front/src/designSystem/components/kpiCard';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { MemberRegistrationQuery } from 'common/src/generated/types';
import { DurationService } from 'common/src/services/durationService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { MemberRegistrationAssignment } from './memberRegistrationAssignment';

interface IMemberRegistrationAssignmentsProps {
    userInfo: MemberRegistrationQuery['organization']['userInfo'];
}

export const MemberRegistrationAssignments = ({
    userInfo
}: IMemberRegistrationAssignmentsProps) => {
    const translate = useTranslate();
    const durationService = useService(DurationService);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const psuis = React.useMemo(
        () =>
            sortBy(
                userInfo.volunteerRegistration!.positionsSlotsUsersInfos,
                (psui) => psui.positionSlot.range.start?.toMillis() ?? 0
            ),
        [userInfo]
    );
    const isMobile = useMobileQuery();

    return (
        <Flex direction="column" gap={{ '@initial': '6', '@desktop': '7' }}>
            {isNonEmptyArray(psuis) ? (
                <>
                    <Box font="gray800 textMd semiBold">{translate('statistiques_98073')}</Box>

                    <Flex direction={isMobile ? 'column' : 'row'} gap="6">
                        <KpiCard
                            color="success"
                            count={psuis.length}
                            icon="wand-magic-sparkles"
                            text={translate('nombre_de_missi_80367')}
                        />

                        <KpiCard
                            color="purple"
                            count={durationService.minutesToHoursMinutesString(
                                userInfo.volunteerRegistration!.volunteerMinutes
                            )}
                            icon="clock"
                            text={translate('nombre_d_heures_46954')}
                        />
                    </Flex>
                </>
            ) : (
                <Blank
                    imageSrc={Svgs.FormsElementsBlank}
                    title={translate('aucune_affectat_04761')}
                />
            )}

            <Flex direction="column" gap={{ '@initial': '6', '@desktop': '7' }} width={1}>
                {psuis.map((psui, index) => (
                    <MemberRegistrationAssignment
                        key={index}
                        isSelected={index === selectedIndex}
                        onClick={() => {
                            setSelectedIndex(index);
                        }}
                        positionSlotUserInfo={psui}
                    />
                ))}
            </Flex>
        </Flex>
    );
};
