import * as React from 'react';

interface ITabPanelProps {
    children: React.ReactNode;
    // eslint-disable-next-line react/no-unused-prop-types
    path: string | string[]; // Prop value accessed in `tabs.tsx`.
}

export const TabPanel = (props: ITabPanelProps) => <>{props.children}</>;

TabPanel.displayName = 'TabPanel';
