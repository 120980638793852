import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { IIcon } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { IconBackground } from './iconBackground';

interface IKpiCardProps {
    button?: React.ReactNode;
    color: 'primary' | 'purple' | 'success' | 'warning';
    count: string | number;
    icon: IIcon;
    text: string;
}

export const KpiCard = (props: IKpiCardProps) => (
    <Flex
        css={{
            border: '1px solid $gray200',
            borderRadius: '$2',
            boxShadow: '$xs',
            flex: '1',
            overflow: 'hidden'
        }}
        direction="column"
    >
        <Flex
            css={{
                borderBottom: props.button ? '1px solid $gray200' : 'none',
                padding: '$6'
            }}
            direction="column"
        >
            <IconBackground color={props.color} icon={props.icon} />

            <Spacer height="6" />

            <Box color="gray500">{props.text}</Box>

            <Spacer height="2" />

            <Box color="gray900" fontSize="displayMd" fontWeight="semiBold">
                {props.count}
            </Box>
        </Flex>

        {props.button && (
            <Flex css={{ background: '$gray50', padding: '$4 $6' }} gap="4" justify="end">
                {props.button}
            </Flex>
        )}
    </Flex>
);
