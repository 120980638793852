/// <reference types='google.maps' />
import { MapLayer } from 'common/src/generated/types';

const getTileUrl = (
    tileCoord: google.maps.Point,
    zoom: number,
    layer: string,
    format: string
): string => {
    const queries = [
        'REQUEST=GetTile',
        'SERVICE=WMTS',
        'VERSION=1.0.0',
        'STYLE=normal',
        'TILEMATRIXSET=PM',
        `FORMAT=${encodeURIComponent(format)}`,
        `LAYER=${layer}`,
        `TILEMATRIX=${zoom}`,
        `TILEROW=${tileCoord.y}`,
        `TILECOL=${tileCoord.x}`
    ];

    return `https://wxs.ign.fr/pratique/geoportail/wmts?${queries.join('&')}`;
};

export function getIGNPlanLayer() {
    return new google.maps.ImageMapType({
        getTileUrl(tileCoord: google.maps.Point, zoom: number): string {
            return getTileUrl(tileCoord, zoom, 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2', 'image/png');
        },
        tileSize: new google.maps.Size(256, 256),
        name: MapLayer.IgnPlan,
        maxZoom: 18
    });
}
