import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { TabsContext } from './tabsContext';

const _HorizontalTabs = styled('div', {
    background: '$gray50',
    border: '1px solid $gray100',
    borderRadius: '$2',
    display: 'flex',
    gap: '$2',
    height: '44px',
    minHeight: '44px',
    padding: '$1',
    position: 'relative',
    width: '100%'
});

interface IHorizontalTabsProps {
    children: React.ReactNode;
    selectedItem: string;

    onChange(item: string): void;
}

export const HorizontalTabs = (props: IHorizontalTabsProps) => (
    <TabsContext.Provider
        value={{
            selectedItem: props.selectedItem,
            onChange: props.onChange
        }}
    >
        <_HorizontalTabs>{props.children}</_HorizontalTabs>
    </TabsContext.Provider>
);
