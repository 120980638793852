import * as React from 'react';

interface ITabsContext {
    selectedItem: string;

    onChange(item: string): void;
}

export const TabsContext = React.createContext<ITabsContext>({} as any);

export function useTabsContext() {
    return React.useContext(TabsContext);
}
