import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import {
    CustomFieldWithConditionFragment,
    FormPositionRanking,
    FormRegisterInput,
    RegisterAccreditationDisplay,
    RegisterPositionDisplay,
    RegisterSlotDisplay,
    UserEmail,
    UserInfoEditFragment
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { Emptyable } from '../util/emptyable';
import { InputService } from './inputService';
import { FieldFormElement, UserInfoInputService } from './userInfoInput';
import { VolunteerRegistrationInputService } from './volunteerRegistrationInput';
import { VolunteerRegistrationSlotInputService } from './volunteerRegistrationSlotInput';

export interface IFormRegisterValues {
    formRegister: FormRegisterInput;
}

export interface IFormRegisterEmailValues {
    email: UserEmail;
    formRegister: FormRegisterInput;
}

interface IFormRegisterInputDefaultOptions {
    countryCode: Emptyable<string>;
    eventStartDate?: DateTime;
    forOther: boolean;
    sendNotificationEmail: boolean;
    slotDisplay: RegisterSlotDisplay;
}

@injectable()
export class FormRegisterInputService extends InputService {
    constructor(
        translationService: TranslationService,
        private userInfoInputService: UserInfoInputService,
        private volunteerRegistrationInputService: VolunteerRegistrationInputService,
        private volunteerRegistrationSlotInputService: VolunteerRegistrationSlotInputService
    ) {
        super(translationService);
    }

    formRegisterInputDefault(
        userInfo: Emptyable<UserInfoEditFragment>,
        customFields: CustomFieldWithConditionFragment[],
        options: IFormRegisterInputDefaultOptions
    ): FormRegisterInput {
        return {
            forOther: options.forOther,
            positionsSlotsIds: [],
            positionsIds: [],
            positionsCategoriesIds: [],
            accreditationsSlotsIds: [],
            userInfo: this.userInfoInputService.userInfoUpdateInputDefault(
                userInfo,
                customFields,
                options.countryCode
            ),
            sendNotificationEmail: options.sendNotificationEmail,
            slots:
                options.slotDisplay === RegisterSlotDisplay.Display
                    ? [
                          this.volunteerRegistrationSlotInputService.volunteersRegistrationsSlotInputDefault(
                              options.eventStartDate
                          )
                      ]
                    : []
        };
    }

    formRegisterInformationsSchema(formElements: FieldFormElement[]) {
        return yup.object().shape({
            formRegister: yup.object().shape({
                userInfo: this.userInfoInputService.formUserInfoInputSchema(formElements)
            })
        });
    }

    formRegisterSlotsSchema(
        slotDisplay: RegisterSlotDisplay,
        eventStartDate: DateTime,
        eventEndDate: DateTime
    ) {
        return yup.object().shape({
            formRegister: yup.object().shape({
                ...this.volunteerRegistrationInputService.slotsFields(
                    slotDisplay,
                    eventStartDate,
                    eventEndDate
                )
            })
        });
    }

    formRegisterPositionsSchema(
        positionRanking: FormPositionRanking,
        positionDisplay: RegisterPositionDisplay
    ) {
        return yup.object().shape({
            formRegister: yup.object().shape({
                ...this.volunteerRegistrationInputService.wishedPositionsFields(
                    positionRanking,
                    positionDisplay
                )
            })
        });
    }

    formRegisterAccreditationsSchema(accreditationDisplay: RegisterAccreditationDisplay) {
        return yup.object().shape({
            formRegister: yup.object().shape({
                ...this.volunteerRegistrationInputService.accreditationsFields(accreditationDisplay)
            })
        });
    }

    formRegisterSchema(
        formElements: FieldFormElement[],
        slotDisplay: RegisterSlotDisplay,
        eventStartDate: DateTime,
        eventEndDate: DateTime,
        positionRanking: FormPositionRanking,
        positionDisplay: RegisterPositionDisplay,
        accreditationDisplay: RegisterAccreditationDisplay
    ) {
        return yup.object().shape({
            email: yup
                .string()
                .required(this.t('l_e_mail_est_re_04856'))
                .email(this.t('l_e_mail_est_re_04856')),
            formRegister: yup.object().shape({
                userInfo: this.userInfoInputService.formUserInfoInputSchema(formElements),
                ...this.volunteerRegistrationInputService.slotsFields(
                    slotDisplay,
                    eventStartDate,
                    eventEndDate
                ),
                ...this.volunteerRegistrationInputService.wishedPositionsFields(
                    positionRanking,
                    positionDisplay
                ),
                ...this.volunteerRegistrationInputService.accreditationsFields(accreditationDisplay)
            })
        });
    }
}
