import * as React from 'react';

interface IUsePaginationInfosResult {
    numberOfPages: number;
    totalCount: number;
}

export function usePaginationInfos(infos?: {
    numberOfPages: number;
    totalCount: number;
}): IUsePaginationInfosResult {
    const numberOfPages = React.useMemo(() => infos?.numberOfPages ?? 1, [infos]);
    const totalCount = React.useMemo(() => infos?.totalCount ?? 0, [infos]);

    return { numberOfPages, totalCount };
}
