import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { TranslationService } from 'common/src/services/translationService';
import { DependenciesContextConsumer } from 'common/src/util/dependencies/dependenciesContext';
import { fileInfos } from 'common/src/util/file';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { Loader } from '../loader/loader';
import { IPdf, loadDocument, renderPages, rotateStyles } from './pdf';

const _Controls = styled('div', {
    alignItems: 'center',
    background: '#434c5e',
    borderRadius: '$1',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    variants: {
        controls: {
            true: {
                height: 'calc(100% - 10px)',
                paddingLeft: '10px',
                paddingRight: '10px',
                width: '200px'
            }
        },
        rotate: {
            true: {
                height: '40px',
                justifyContent: 'center',
                marginLeft: '10px',
                width: '40px'
            }
        }
    }
});
const _Range = styled('input', {
    '-webkit-appearance': 'none',
    background: 'transparent',
    marginLeft: '10px',
    marginRight: '10px',
    width: '100%',
    '&::-webkit-slider-thumb': {
        '-webkit-appearance': 'none',
        background: 'white',
        borderRadius: '16px',
        cursor: 'pointer',
        height: '16px',
        marginTop: '-7px',
        width: '16px'
    },
    '&::-webkit-slider-runnable-track': {
        background: 'white',
        borderRadius: '2px',
        cursor: 'pointer',
        height: '2px',
        width: '100%'
    },
    '&:focus::-webkit-slider-runnable-track': {
        background: 'white'
    },
    '&::-moz-range-thumb': {
        background: 'white',
        borderRadius: '16px',
        cursor: 'pointer',
        height: '16px',
        width: '16px'
    },
    '&::-moz-range-track': {
        background: 'white',
        borderRadius: '2px',
        cursor: 'pointer',
        height: '2px',
        width: '100%'
    },
    '&::-ms-thumb': {
        background: 'white',
        borderRadius: '16px',
        cursor: 'pointer',
        height: '16px',
        width: '16px'
    },
    '&:focus': {
        ouline: 'none'
    }
});

enum FileTypes {
    EmptyFile,
    Pdf,
    Image
}

interface IFileViewerProps {
    fileUrl: string;
}

interface IFileViewerState {
    image: any | null;
    isLoading: boolean;
    fileType: FileTypes;
    pdf: IPdf | null;
    rotate: number;
    zoomLevel: number;
}

export class FileViewer extends React.Component<IFileViewerProps, IFileViewerState> {
    private readonly contentRef: React.RefObject<HTMLDivElement>;
    private readonly renderPagesDebounced: () => void;

    constructor(props: IFileViewerProps) {
        super(props);

        let fileType = FileTypes.Image;

        if (typeof this.props.fileUrl !== 'string' || this.props.fileUrl.trim().length === 0) {
            fileType = FileTypes.EmptyFile;
        } else if (fileInfos(this.props.fileUrl).extension === 'pdf') {
            fileType = FileTypes.Pdf;
        }

        this.state = {
            image: null,
            isLoading: true,
            fileType,
            pdf: null,
            rotate: 0,
            zoomLevel: 100
        };

        this.renderPagesDebounced = debounce(() => {
            this.renderPdfPages();
        }, 200);

        this.contentRef = React.createRef();
    }

    componentDidMount() {
        if (this.state.fileType === FileTypes.Pdf) {
            loadDocument(this.props.fileUrl).then((pdf) => {
                this.setState({ pdf });

                this.renderPdfPages();
            });
        } else if (this.state.fileType === FileTypes.Image) {
            this.loaderImage();
        }
    }

    renderPdfPages() {
        if (this.state.fileType === FileTypes.Pdf) {
            renderPages(
                this.state.pdf!,
                this.contentRef.current!,
                this.state.zoomLevel / 100,
                this.state.rotate
            ).then(() => {
                this.setState({
                    isLoading: false
                });
            });
        }
    }

    loaderImage() {
        if (this.state.fileType === FileTypes.Image) {
            const image = new Image();

            image.onload = () => {
                const boundingBox = this.contentRef.current!.getBoundingClientRect();
                const heightRatio = boundingBox.height / image.height;
                const widthRatio = boundingBox.width / image.width;
                const minRatio = Math.min(heightRatio, widthRatio);
                let zoomLevel = 100;

                if (minRatio < 1) {
                    zoomLevel = (minRatio - minRatio * 0.1) * 100;
                }

                this.setState({
                    image,
                    isLoading: false,
                    zoomLevel
                });
            };

            image.onerror = () => {
                this.setState({
                    isLoading: false,
                    fileType: FileTypes.EmptyFile
                });
            };

            image.src = this.props.fileUrl;
        }
    }

    imageHeight(): number {
        return this.state.image.height * (this.state.zoomLevel / 100);
    }

    imageWidth(): number {
        return this.state.image.width * (this.state.zoomLevel / 100);
    }

    zoomMinus() {
        this.setState({
            zoomLevel: Math.max(1, Math.floor((this.state.zoomLevel - 10) / 10) * 10)
        });

        this.renderPagesDebounced();
    }

    zoomPlus() {
        this.setState({
            zoomLevel: Math.ceil((this.state.zoomLevel + 10) / 10) * 10
        });

        this.renderPagesDebounced();
    }

    rangeChange(event: any) {
        this.setState({
            zoomLevel: parseInt(event.target.value, 10)
        });

        this.renderPagesDebounced();
    }

    rotate() {
        this.setState((state: IFileViewerState) => {
            const newRotate = state.rotate + 25;

            return {
                rotate: newRotate >= 100 ? 0 : newRotate
            };
        });

        this.renderPagesDebounced();
    }

    render() {
        return (
            <DependenciesContextConsumer>
                {({ container }) => {
                    const translationService = container.get(TranslationService);

                    return (
                        <Flex
                            css={{ position: 'relative' }}
                            direction="column"
                            height={1}
                            width={1}
                        >
                            <Box
                                ref={this.contentRef}
                                css={{
                                    height: 'calc(100% - 50px)',
                                    overflow: 'auto',
                                    padding: '10px',
                                    width: '100%',
                                    zIndex: '1',
                                    '& > div': {
                                        margin: 'auto',
                                        marginBottom: '20px'
                                    },
                                    '& > div:last-child': {
                                        marginBottom: '0'
                                    }
                                }}
                            />

                            {this.state.fileType === FileTypes.Image &&
                                this.state.isLoading === false && (
                                    <Box
                                        css={{
                                            background: 'white',
                                            height: 'calc(100% - 50px)',
                                            left: '0',
                                            overflow: 'auto',
                                            padding: '10px',
                                            position: 'absolute',
                                            top: '0',
                                            width: '100%',
                                            zIndex: '5',
                                            '& > div:last-child': {
                                                marginBottom: '0'
                                            }
                                        }}
                                    >
                                        <img
                                            src={this.state.image!.src}
                                            style={{
                                                height: `${this.imageHeight()}px`,
                                                width: `${this.imageWidth()}px`,
                                                ...rotateStyles(
                                                    this.state.rotate,
                                                    this.imageHeight(),
                                                    this.imageWidth()
                                                )
                                            }}
                                        />
                                    </Box>
                                )}

                            {this.state.isLoading === true && (
                                <Box
                                    css={{
                                        background: 'white',
                                        height: 'calc(100% - 50px)',
                                        left: '0',
                                        position: 'absolute',
                                        top: '0',
                                        width: '100%',
                                        zIndex: '10'
                                    }}
                                >
                                    <Loader />
                                </Box>
                            )}

                            {this.state.fileType === FileTypes.EmptyFile && (
                                <Flex
                                    align="center"
                                    css={{
                                        background: 'white',
                                        fontSize: '22px',
                                        height: 'calc(100% - 50px)',
                                        left: '0',
                                        position: 'absolute',
                                        top: '0',
                                        width: '100%',
                                        zIndex: '20'
                                    }}
                                    justify="center"
                                >
                                    {translationService.translate('aucun_fichier_77247')}
                                </Flex>
                            )}

                            <Flex
                                align="center"
                                css={{
                                    height: '50px',
                                    width: '100%'
                                }}
                                justify="center"
                            >
                                <_Controls controls={true}>
                                    <i
                                        className="fas fa-minus"
                                        onClick={this.zoomMinus.bind(this)}
                                    />
                                    <_Range
                                        max="200"
                                        min="1"
                                        step="1"
                                        type="range"
                                        value={this.state.zoomLevel}
                                        onChange={this.rangeChange.bind(this)}
                                    />
                                    <i className="fas fa-plus" onClick={this.zoomPlus.bind(this)} />
                                </_Controls>

                                <_Controls rotate={true} onClick={this.rotate.bind(this)}>
                                    <i className="far fa-undo" />
                                </_Controls>
                            </Flex>
                        </Flex>
                    );
                }}
            </DependenciesContextConsumer>
        );
    }
}
