import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IMemberRegistrationAccreditationInfoProps {
    children: React.ReactNode;
    title: string;
}

export const MemberRegistrationAccreditationInfo = ({
    children,
    title
}: IMemberRegistrationAccreditationInfoProps) => (
    <Flex
        direction={{ '@initial': 'column', '@desktop': 'row' }}
        gap={{ '@initial': '1', '@desktop': '6' }}
    >
        <Box
            color="gray500"
            css={{
                width: '100%',
                '@desktop': {
                    width: '200px'
                }
            }}
        >
            {title}
        </Box>

        <Box
            color="gray800"
            css={{
                width: '100%',
                '@desktop': {
                    flex: '1'
                }
            }}
        >
            {children}
        </Box>
    </Flex>
);
