import { PathParam } from '../pathsTypes';

export const buildPath = (components: Array<string | PathParam>) => components.join('/');

export const PathComponents = {
    ACTIONS: {
        ARCHIVE: 'archive',
        AUTH: 'auth',
        EDIT: 'edit',
        CREATE: 'create',
        EXPORT: 'export',
        EXPORT_BASIC: 'export/basic',
        EXPORT_API: 'export/api'
    },
    VIEWS: {
        LIST: 'list'
    },
    SECTIONS: {
        INFORMATION: 'information',
        DASHBOARD: 'dashboard',
        REGISTRATION: 'registration',
        FORMS: 'forms',
        FORM: 'form',
        MEMBER: 'member',
        MEMBERS: 'members'
    }
};

export const ActionPaths = {
    // Access Paths
    SHOW: {
        LIST: (path: string) => buildPath([path, PathComponents.VIEWS.LIST])
    },

    // Action Paths
    ARCHIVE: (path: string) => buildPath([path, PathComponents.ACTIONS.ARCHIVE]),
    CREATE: (path: string) => buildPath([path, PathComponents.ACTIONS.CREATE]),
    EDIT: (path: string) => buildPath([path, PathComponents.ACTIONS.EDIT])
};
