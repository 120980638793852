export function convertToPercentOrPx(size?: number | string): string | undefined {
    if (typeof size === 'string') {
        return size;
    } else if (typeof size === 'number') {
        if (size <= 1) {
            return `${size * 100}%`;
        } else {
            return `${size}px`;
        }
    } else {
        return undefined;
    }
}
