import { Badge } from 'common/src/designSystem/components/badge';
import { EventId, Form, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { useHistory } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { FormEditPath } from 'common/src/util/membersPaths';
import * as React from 'react';

interface IUserFormBadgeProps {
    editDisabled?: boolean;
    eventId: EventId;
    form: Pick<Form, 'id' | 'name'>;
    organizationId: OrganizationId;
    userInfoId?: UsersInfoId;

    editPath?: FormEditPath;
}

export const UserFormBadge = ({
    editDisabled,
    editPath,
    eventId,
    form,
    organizationId,
    userInfoId
}: IUserFormBadgeProps) => {
    const history = useHistory();

    return (
        <Badge
            color="primary"
            cursor={!editDisabled ? 'pointer' : 'default'}
            onContentClick={() => {
                if (!editDisabled) {
                    history.push(
                        editPath
                            ? editPath(organizationId, userInfoId!, eventId, form.id)
                            : HeaventPaths.FORM(organizationId, eventId, form.id)
                    );
                }
            }}
        >
            {form.name}
        </Badge>
    );
};
