import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useEsc } from '../../../hooks/useEsc';

type IRightPanelSize = 'sm' | 'md';

interface IRightPanelProps {
    children: React.ReactNode;
    css?: CSS;
    size: IRightPanelSize;

    onClose(): void;
}

export const RightPanel = (props: IRightPanelProps) => {
    useEsc(props.onClose);

    return createPortal(
        <Flex
            css={{
                background: 'rgba(52, 64, 84, 0.7)',
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
                zIndex: '100',
                ...(props.css as any)
            }}
            onClick={props.onClose}
        >
            <Flex
                css={{
                    background: 'white',
                    height: '100%',
                    position: 'absolute',
                    right: '0',
                    top: '0',
                    width: props.size === 'sm' ? '480px' : '650px'
                }}
                direction="column"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Box
                    color="gray500"
                    css={{
                        cursor: 'pointer',
                        fontSize: '$textMd',
                        position: 'absolute',
                        right: '$space$6',
                        top: '$space$6'
                    }}
                    onClick={props.onClose}
                >
                    <i className="far fa-times" />
                </Box>

                {props.children}
            </Flex>
        </Flex>,
        document.body
    );
};
