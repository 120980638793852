import { injectable } from 'inversify';
import { UsersInfosMassEditInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IMassEditValues {
    massEdit: UsersInfosMassEditInput;
}

@injectable()
export class UsersInfosMassEditInputService extends InputService {
    constructor(protected translationService: TranslationService) {
        super(translationService);
    }
}
