import { injectable } from 'inversify';
import {
    CustomFieldWithConditionFragment,
    FilterType,
    OrganizationId,
    SegmentCustomFieldFragment,
    SegmentId,
    UsersSegmentsQuery
} from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { Filter, PossibleColumn, SegmentService } from '../segment';

@injectable()
export class OrganizationUsersSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getOrganizationUsersFilters(
        organizationId: OrganizationId,
        currentSegmentId: SegmentId,
        organization: UsersSegmentsQuery['organization']
    ): Filter[] {
        const filters: Filter[] = [
            {
                color: 'purple',
                slug: 'event',
                name: this.t('_v_nement_22345'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                organizationId
            },
            {
                color: 'purple',
                slug: 'numberOfEvents',
                name: this.t('nombre_d_v_nem_14214'),
                fieldType: FilterType.Number,
                hideEmpty: true
            }
        ];

        filters.push({
            color: 'purple',
            slug: 'forms',
            name: this.t('formulaires_rem_62495'),
            fieldType: FilterType.Select,
            needLoading: true,
            organizationId
        });

        filters.push({
            color: 'purple',
            slug: 'formsInsertedAt',
            name: this.t('dates_de_rempli_65075'),
            fieldType: FilterType.Date,
            hideEmpty: true,
            needLoading: true,
            organizationId
        });

        filters.push(
            {
                color: 'purple',
                slug: 'registrationDate',
                name: this.t('date_d_inscript_31369'),
                fieldType: FilterType.Date,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'positionName',
                name: this.t('nom_de_la_missi_80169'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'positionCategoryName',
                name: this.t('nom_de_la_cat_g_57279'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'numberOfAssignments',
                name: this.t('nombre_d_affect_31455'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'sameEmail',
                name: this.t('nombre_de_profi_42316'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'segments',
                name: this.t('fait_partie_de_44650'),
                fieldType: FilterType.Select,
                values: organization.segmentsFolders
                    .flatMap((sf) => sf.segments)
                    .filter((s) => s.id !== currentSegmentId),
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'delegation',
                name: this.t('fait_partie_de_32196'),
                fieldType: FilterType.Select,
                needLoading: true,
                organizationId
            },
            {
                color: 'success',
                slug: 'isAdmin',
                name: this.t('est_administrat_15766'),
                fieldType: FilterType.Checkbox
            },
            {
                color: 'success',
                slug: 'isLeader',
                name: this.t('est_responsable_27270'),
                fieldType: FilterType.Checkbox
            },
            {
                color: 'success',
                slug: 'isDelegationLeader',
                name: this.t('est_responsable_87662'),
                fieldType: FilterType.Checkbox
            },
            {
                color: 'success',
                slug: 'comments',
                name: this.t('commentaires_96492'),
                fieldType: FilterType.Text
            }
        );

        filters.push({
            color: 'success',
            slug: 'campaign',
            name: this.t('campagne_97871'),
            fieldType: FilterType.Campaign,
            needLoading: true,
            organizationId
        });

        filters.push({
            color: 'success',
            slug: 'emailBlacklist',
            name: this.t('ne_souhaite_plu_08853'),
            fieldType: FilterType.Checkbox,
            hideEmpty: true
        });

        filters.push({
            color: 'warning',
            slug: 'email',
            name: this.t('Email'),
            fieldType: FilterType.Text
        });

        filters.push(...this.customFieldsToFilters(organization.customFields.nodes));

        return filters;
    }

    getOrganizationUsersPossibleColumns(
        customFields: SegmentCustomFieldFragment[] | CustomFieldWithConditionFragment[]
    ): PossibleColumn[] {
        const possibleColumns: PossibleColumn[] = [
            {
                slug: 'name',
                name: this.t('full_name'),
                isSearchable: true
            },
            {
                slug: 'email',
                name: this.t('e_mail_du_membr_69509'),
                isSearchable: true
            },
            {
                slug: 'id',
                name: this.t('id_51738'),
                isSearchable: true
            },
            {
                slug: 'events',
                name: this.t('_v_nements_53672')
            },
            {
                slug: 'number_of_events',
                name: this.t('nombre_d_v_nem_14214')
            },
            {
                slug: 'forms',
                name: this.t('formulaires_rem_62495')
            },
            {
                slug: 'forms_inserted_at',
                name: this.t('dates_de_rempli_65075')
            },
            {
                slug: 'delegations',
                name: this.t('d_l_gations_78318'),
                isSearchable: true
            },
            {
                slug: 'inserted_at',
                name: this.t('date_d_inscript_31369')
            }
        ];

        return possibleColumns.concat(this.customFieldsToPossibleColumns(customFields));
    }
}
