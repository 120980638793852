import { I } from 'common/src/designSystem/components/i';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const LimitSource = {
    Both: 'both',
    DelegationAccreditation: 'delegationAccreditation',
    Slot: 'slot'
};

interface ILimitSourceAccreditationIconProps {
    limitSource: string;
}

export const LimitSourceAccreditationIcon = ({
    limitSource
}: ILimitSourceAccreditationIconProps) => {
    const translate = useTranslate();

    return limitSource === LimitSource.DelegationAccreditation ? (
        <I icon={'people-group'} title={translate('limite_fix_e_po_87265')} />
    ) : (
        <>
            <I icon={'badge-check'} title={translate('limite_fix_e_po_40573')} />

            {limitSource === LimitSource.Both && (
                <>
                    &nbsp;+&nbsp;
                    <LimitSourceAccreditationIcon
                        limitSource={LimitSource.DelegationAccreditation}
                    />
                </>
            )}
        </>
    );
};
