import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UpdateUserFormFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { randomCode } from 'common/src/util/random';
import * as React from 'react';
import { HorizontalSpacerSeparator } from '../../designSystem/components/separator';
import { TextInput } from '../../designSystem/form/textInput';

interface IUpdateUserTeamCodeProps {
    form: UpdateUserFormFragment;

    change(name: string, value: any): void;
}

export const UpdateUserTeamCode = (props: IUpdateUserTeamCodeProps) => {
    const translate = useTranslate();
    const name = `formKeyToTeamCode.f${props.form.id}`;

    return (
        <>
            <Box font="gray900 textLg medium">Équipe</Box>

            <HorizontalSpacerSeparator height="4" />

            <TextInput
                hint={translate('d_finissez_votr_19710')}
                label={translate('code_quipe_14296')}
                name={name}
                rightIcon="arrows-rotate"
                onRightIconClick={() => {
                    props.change(name, randomCode());
                }}
            />

            <Spacer height="9" />
        </>
    );
};
