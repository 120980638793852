import { Box } from 'common/src/designSystem/components/box';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { noop } from 'lodash-es';
import * as React from 'react';
import { FormBox } from './formBox';

interface IFormNoAccessProps {
    noAccessMessage: string;
}

export const FormNoAccess = (props: IFormNoAccessProps) => {
    const translate = useTranslate();

    return (
        <>
            <Spacer height="4" />

            <FormBox
                canSelect={false}
                isCompleted={false}
                isOpen={true}
                number={2}
                title={translate('acc_s_limit_69177')}
                onSelect={noop}
            >
                {isNonEmptyString(props.noAccessMessage) ? (
                    <RichText text={props.noAccessMessage} />
                ) : (
                    <Box font="gray800 textMd semiBold" textAlign="center" width={1}>
                        {translate('vous_n_avez_pas_58193')}
                    </Box>
                )}
            </FormBox>
        </>
    );
};
