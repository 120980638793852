import { Empty } from 'common-front/src/components/empty/empty';
import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { FormSlots } from 'common-front/src/forms/slots/formSlots';
import {
    useUserInfoSlotsUpdateMutation,
    useUserUpdateSlotsInfosQuery
} from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { IUserUpdateSlotsValues } from 'common-front/src/users/updateSlots/userUpdateSlotsValues';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { RegisterSlotDisplay, UserUpdateSlotsInfosQuery } from 'common/src/generated/types';
import { VolunteerRegistrationInputService } from 'common/src/input/volunteerRegistrationInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { MembersPaths } from 'common/src/util/membersPaths';
import { FormIdPathParam } from 'common/src/util/pathsTypes';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { UpdateForm } from '../../../../common/updateForm';

interface IMemberRegistrationUpdateAvailabilityProps {
    reload(): void;
}

export const MemberRegistrationUpdateAvailability = ({
    reload
}: IMemberRegistrationUpdateAvailabilityProps) => {
    const {
        translate,
        params: { eventId, organizationId, userInfoId }
    } = useHeavent();
    const validateService = useService(ValidateService);
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);

    const { data, loader: queryLoader } = useUserUpdateSlotsInfosQuery({
        organizationId,
        eventId,
        userInfoId
    });
    const { mutate, loader: mutationLoader } = useUserInfoSlotsUpdateMutation();

    const forms = React.useMemo(
        () =>
            (data.organization?.userInfo?.formsUsersInfos ?? []).flatMap((fui) => {
                if (fui.form.slotDisplay !== RegisterSlotDisplay.Hide) {
                    return [fui.form];
                } else {
                    return [];
                }
            }),
        [data?.organization?.userInfo?.formsUsersInfos]
    );
    const editableForms = React.useMemo(
        () => forms.filter((form) => form.isEditableInMemberSpace),
        [forms]
    );
    const initialSelectedSlots = React.useMemo(
        () =>
            forms.map((form) => {
                const formUserInfo = data?.organization.userInfo.formsUsersInfos.find(
                    (fui) => fui.formId === form.id
                );

                return {
                    formId: form.id,
                    slots: volunteerRegistrationInput.volunteersRegistrationsSlotsUpdateInputDefault(
                        formUserInfo?.slots
                    )
                };
            }),
        [forms, data?.organization?.userInfo]
    );

    return (
        queryLoader || (
            <Switch>
                <Route
                    path={MembersPaths.MEMBER_REGISTRATION_AVAILABILITY_EDIT_FORM(
                        ':organizationId',
                        ':userInfoId',
                        ':eventId',
                        ':formId'
                    )}
                    children={
                        <UpdateForm
                            backPath={MembersPaths.MEMBER_REGISTRATION_AVAILABILITY(
                                organizationId,
                                userInfoId,
                                eventId
                            )}
                            child={(
                                currForm: UserUpdateSlotsInfosQuery['event']['forms'][number],
                                index: number
                            ) => (
                                <Form
                                    direction="column"
                                    height={1}
                                    width={1}
                                    initialValues={{
                                        userInfo: { slots: initialSelectedSlots }
                                    }}
                                    onSubmit={async (values: IUserUpdateSlotsValues) => {
                                        await mutate({
                                            eventId,
                                            userId: data.organization.userInfo.userId,
                                            userInfoId,
                                            userInfoSlots: values.userInfo.slots
                                        });

                                        reload();
                                    }}
                                    render={({ form, handleSubmit, values }) => (
                                        <Flex direction="column" gap="5">
                                            <Box>
                                                <FormSlots
                                                    change={form.change}
                                                    currentSlots={
                                                        values.userInfo.slots[index].slots
                                                    }
                                                    customSlotsPeriods={currForm.customSlotsPeriods}
                                                    daysDisplay={currForm.daysDisplay}
                                                    event={data.event}
                                                    prefix={`userInfo.slots[${index}].`}
                                                    slotDisplay={currForm.slotDisplay}
                                                />
                                            </Box>

                                            <Separator direction="horizontal" />

                                            <Button
                                                textAlign="center"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >
                                                {mutationLoader || translate('update')}
                                            </Button>
                                        </Flex>
                                    )}
                                    validate={validateService.validateForForm(
                                        volunteerRegistrationInput.userInfoSlotsSchema(
                                            currForm.slotDisplay,
                                            data.event.startAt,
                                            data.event.endAt
                                        )
                                    )}
                                />
                            )}
                            forms={editableForms}
                            getEditPath={(formId) =>
                                MembersPaths.MEMBER_REGISTRATION_AVAILABILITY_EDIT_FORM(
                                    organizationId,
                                    userInfoId,
                                    eventId,
                                    formId
                                )
                            }
                            title={translate('update_availabilities')}
                            userInfo={data.organization.userInfo}
                        />
                    }
                />

                <Route
                    children={
                        <Empty
                            path={MembersPaths.MEMBER_REGISTRATION_AVAILABILITY_EDIT_FORM(
                                organizationId,
                                userInfoId,
                                eventId,
                                isNonEmptyArray(editableForms)
                                    ? editableForms[0].id
                                    : (-1 as FormIdPathParam)
                            )}
                            replace={true}
                        />
                    }
                />
            </Switch>
        )
    );
};
