import { isNonEmptyString } from 'common/src/util/string';
import { isObject } from 'lodash-es';
import * as React from 'react';
import { Link as _Link, LinkProps } from 'react-router-dom';
import { convertToPercentOrPx } from '../util/unit';

export interface ILinkProps extends LinkProps {
    children: React.ReactNode;
    height?: number | string;
}

export const Link = ({
    children,
    download,
    height,
    onClick,
    style,
    target,
    to,
    ...props
}: ILinkProps) => {
    // Catch any accidentally passed paths for other (sub)domains
    // React Router will not open external URLs as absolute paths in the same tab,
    // only in a new tab. So this catches external URLs and makes sure that they
    // are opened in a new tab.
    // TODO: Review once we upgrade React Router because we will be refactoring linking
    // then anyway. Evidently React Router's `<Link />` is not intended for external
    // navigation, so this component should probably conditionally return a `<Link />`
    // or an `<a />` instead.
    if (
        (isNonEmptyString(to) && to.startsWith('http')) ||
        // @ts-expect-error `to` may be an object or a string, I'm not sure why the `react-router` types don't get that
        (isObject(to) && to.pathname.startsWith('http'))
    ) {
        // @ts-expect-error `to` may be an object or a string, I'm not sure why the `react-router` types don't get that
        const href: string = to.pathname ?? to;
        target = href.startsWith(location.origin) ? target : '_blank';
        to = { pathname: href };
    }

    const css: any = {};

    if (height) {
        css.height = convertToPercentOrPx(height);
    }

    return (
        <_Link
            download={download}
            style={{
                color: 'inherit',
                cursor: 'pointer',
                textDecoration: 'none',
                ...css,
                ...style
            }}
            target={target}
            to={to}
            onClick={onClick}
            {...props}
        >
            {children}
        </_Link>
    );
};
Link.displayName = 'LinkWrapper';
