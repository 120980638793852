import { last, maxBy, minBy, partition, range as lodashRange, sortBy } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import { SupportedLanguage } from '../generated/types';
import { isNonEmptyArray } from './array';
import { LocaleFormats, LocaleFormatType } from './luxon';
import { isNonEmptyString } from './string';

interface IToDisplayStringOptions {
    language?: SupportedLanguage;
    formats?: {
        dateFormat?: string;
        displayWeekday?: boolean;
        localeFormat?: LocaleFormatType;
        timeFormat?: string;
    };
    hideTime?: boolean;
    hideDate?: boolean;
}

export function toDisplayString(interval: Interval, options: IToDisplayStringOptions = {}) {
    const monthLongOptions: any = options.formats?.localeFormat || LocaleFormats.DateOnly.MonthLong;
    const timeOptions: any = LocaleFormats.TimeOnly;
    const localeOptions = options.language
        ? {
              locale: options.language.toString().toLowerCase()
          }
        : {};

    if (options.formats?.displayWeekday) {
        monthLongOptions.weekday = 'long';
    }

    const start = interval.start!;
    const end = interval.end!;
    const startDateString = isNonEmptyString(options.formats?.dateFormat)
        ? start.toFormat(options.formats!.dateFormat)
        : start.toLocaleString(monthLongOptions, localeOptions);
    const startTimeString = isNonEmptyString(options.formats?.timeFormat)
        ? start.toFormat(options.formats!.timeFormat)
        : start.toLocaleString(timeOptions, localeOptions);
    const endDateString = isNonEmptyString(options.formats?.dateFormat)
        ? end.toFormat(options.formats!.dateFormat)
        : end.toLocaleString(monthLongOptions, localeOptions);
    const endTimeString = isNonEmptyString(options.formats?.timeFormat)
        ? end.toFormat(options.formats!.timeFormat)
        : end.toLocaleString(timeOptions, localeOptions);

    if (
        interval.start!.year !== interval.end!.year ||
        interval.start!.month !== interval.end!.month ||
        interval.start!.day !== interval.end!.day
    ) {
        if (options.hideTime) {
            return `${startDateString}–${endDateString}`;
        } else if (options.hideDate) {
            return `${startTimeString}–${endTimeString}`;
        } else {
            return `${startDateString} ${startTimeString}–${endDateString} ${endTimeString}`;
        }
    } else {
        if (options.hideTime) {
            return `${startDateString}`;
        } else if (options.hideDate) {
            return `${startTimeString}–${endTimeString}`;
        } else {
            return `${startDateString} ${startTimeString}–${endTimeString}`;
        }
    }
}

export function getNumberOfDays(interval: Interval): number {
    return Math.ceil(interval.end!.endOf('day').diff(interval.start!.startOf('day'), 'days').days);
}

type SplitRange<T> = T & { range: Interval };

export function splitRangesByDay<T>(intervals: Array<SplitRange<T>>): Array<SplitRange<T>> {
    return intervals.flatMap((interval) => {
        const range = interval.range;
        const numberOfDays = getNumberOfDays(range);

        if (numberOfDays > 1) {
            return lodashRange(0, numberOfDays).map((i) => {
                const date = range.start!.plus({ days: i });
                const start = date.startOf('day').equals(range.start!.startOf('day'))
                    ? range.start!
                    : date.startOf('day');
                const end = date.endOf('day').equals(range.end!.endOf('day'))
                    ? range.end!
                    : date.endOf('day');

                return { ...interval, range: Interval.fromDateTimes(start, end) };
            });
        } else {
            return [interval];
        }
    });
}

// this function assumes that splitRangesByDay has been run before
export function splitRangesMidday<T>(intervals: Array<SplitRange<T>>): Array<SplitRange<T>> {
    return intervals.flatMap((interval) => {
        const range = interval.range;

        if (range.start!.hour < 12 && range.end!.hour >= 12) {
            return [
                {
                    ...interval,
                    range: Interval.fromDateTimes(
                        range.start!,
                        range.start!.set({ hour: 12, minute: 0 })
                    )
                },
                {
                    ...interval,
                    range: Interval.fromDateTimes(
                        range.end!.set({ hour: 12, minute: 0 }),
                        range.end!
                    )
                }
            ];
        } else {
            return [interval];
        }
    });
}

export function splitAt<T>(
    intervals: Array<SplitRange<T>>,
    startAt: DateTime,
    endAt: DateTime,
    startHour: number
): Array<SplitRange<T>> {
    const numberOfDays = Math.ceil(endAt.endOf('day').diff(startAt.startOf('day'), 'days').days);
    const datesToSplitAt = lodashRange(0, numberOfDays).map((i) =>
        startAt.startOf('day').set({ hour: startHour }).plus({ day: i })
    );
    const [othersIntervals, intervalsToSplit] = partition(
        intervals,
        (interval) => interval.range.start!.hour === startHour && interval.range.start!.minute === 0
    );

    return intervalsToSplit
        .flatMap((interval) =>
            interval.range.splitAt(...datesToSplitAt).map((range) => ({
                ...interval,
                range
            }))
        )
        .concat(othersIntervals);
}

export function mergeOverlapping(intervals: Interval[]): Interval[] {
    const validIntervals = intervals.filter((i) => i.isValid);

    if (isNonEmptyArray(validIntervals)) {
        const sortedIntervals = sortBy(validIntervals, (i) => i.start!.toMillis());
        const mergedIntervals = [sortedIntervals[0]];

        for (let i = 1; i < sortedIntervals.length; i++) {
            const latestMergedInterval = last(mergedIntervals)!;
            const interval = sortedIntervals[i];

            if (
                latestMergedInterval.abutsStart(interval) ||
                latestMergedInterval.overlaps(interval)
            ) {
                mergedIntervals[mergedIntervals.length - 1] = latestMergedInterval.union(interval);
            } else {
                mergedIntervals.push(interval);
            }
        }

        return mergedIntervals;
    } else {
        return [];
    }
}

export const DEFAULT_MIN_HOUR = 8;
export const DEFAULT_MAX_HOUR = 20;

interface IIntervalsStatsOptions {
    minHour?: number;
    maxHour?: number;
}

interface IIntervalsStatsResult {
    starts: DateTime[];
    ends: DateTime[];
    startHour: number;
    endHour: number;
    numberOfSlots: number;
    startDate: DateTime;
    endDate: DateTime;
    numberOfDays: number;
}

export function intervalsStats(
    intervals: Interval[],
    options: IIntervalsStatsOptions = {}
): IIntervalsStatsResult {
    const starts = intervals.map((i) => i.start!);
    const minHour = starts.reduce(
        (currentMinHour, start) => Math.min(currentMinHour, start.hour),
        options.minHour ?? DEFAULT_MIN_HOUR
    );
    const startHour = minHour % 2 === 0 ? minHour : minHour - 1;
    const ends = intervals.map((i) => i.end!);
    const maxHour = ends.reduce(
        (currentMaxHour, end) => Math.max(currentMaxHour, end.hour),
        options.maxHour ?? DEFAULT_MAX_HOUR
    );
    const endHour = maxHour % 2 === 0 ? maxHour : maxHour + 1;
    const numberOfSlots = (endHour - startHour) / 2;
    const startDate = minBy(starts, (s) => s.toMillis())!.startOf('day');
    const endDate = maxBy(ends, (e) => e.toMillis())!.endOf('day');
    const numberOfDays = Math.ceil(endDate.diff(startDate, 'days').days);

    return {
        starts,
        ends,
        startHour,
        endHour,
        numberOfSlots,
        startDate,
        endDate,
        numberOfDays
    };
}
