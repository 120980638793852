import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { MembersPaths } from 'common/src/util/membersPaths';
import * as React from 'react';

export const RedirectMembers = () => {
    const {
        params: { organizationId }
    } = useHeavent();

    return <Empty path={MembersPaths.AUTH(organizationId)} />;
};
