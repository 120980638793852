import { Emptyable } from './emptyable';
import {
    DelegationIdPathParam,
    EventIdPathParam,
    FormIdPathParam,
    OrganizationIdPathParam,
    UserInfoIdPathParam
} from './pathsTypes';

export type FormEditPath = (
    organizationId: OrganizationIdPathParam,
    userInfoId: UserInfoIdPathParam,
    eventId: EventIdPathParam,
    formId: FormIdPathParam
) => string;

const ORGANIZATION = (id: OrganizationIdPathParam) => `/v2/organization/${id}`;
const MEMBER = (organizationId: OrganizationIdPathParam, userInfoId: UserInfoIdPathParam) =>
    `${ORGANIZATION(organizationId)}/member/${userInfoId}`;

const MEMBER_REGISTRATION = ({
    organizationId,
    userInfoId,
    eventId
}: {
    organizationId: OrganizationIdPathParam;
    userInfoId: UserInfoIdPathParam;
    eventId?: Emptyable<EventIdPathParam>;
}) =>
    eventId
        ? `${MEMBER(organizationId, userInfoId)}/event/${eventId}`
        : `${MEMBER(organizationId, userInfoId)}/community`;

export const MembersPaths = {
    AUTH(id: OrganizationIdPathParam) {
        return `${ORGANIZATION(id)}/auth`;
    },
    PROFILES(id: OrganizationIdPathParam) {
        return `${ORGANIZATION(id)}/profiles`;
    },
    MEMBER,
    MEMBER_CAMPAIGNS(organizationId: OrganizationIdPathParam, userInfoId: UserInfoIdPathParam) {
        return `${MEMBER(organizationId, userInfoId)}/campaigns`;
    },
    MEMBER_DOCUMENTS(organizationId: OrganizationIdPathParam, userInfoId: UserInfoIdPathParam) {
        return `${MEMBER(organizationId, userInfoId)}/documents`;
    },
    MEMBER_REGISTRATION,
    MEMBER_REGISTRATION_EDIT(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: Emptyable<EventIdPathParam>
    ) {
        return `${MEMBER_REGISTRATION({ organizationId, userInfoId, eventId })}/edit`;
    },
    MEMBER_REGISTRATION_EDIT_FORM(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: Emptyable<EventIdPathParam>,
        formId: FormIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({
            organizationId,
            userInfoId,
            eventId
        })}/edit/form/${formId}`;
    },
    MEMBER_REGISTRATION_INFORMATION(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: Emptyable<EventIdPathParam>
    ) {
        return `${MEMBER_REGISTRATION({ organizationId, userInfoId, eventId })}/information`;
    },
    MEMBER_REGISTRATION_AVAILABILITY(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({ organizationId, userInfoId, eventId })}/availability`;
    },
    MEMBER_REGISTRATION_POSITIONS(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({ organizationId, userInfoId, eventId })}/positions`;
    },
    MEMBER_REGISTRATION_ASSIGNMENTS(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({ organizationId, userInfoId, eventId })}/assignments`;
    },
    MEMBER_REGISTRATION_WISHED_ACCREDITATIONS(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({
            organizationId,
            userInfoId,
            eventId
        })}/desired-accreditations`;
    },
    MEMBER_REGISTRATION_ACCREDITATIONS(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({ organizationId, userInfoId, eventId })}/accreditations`;
    },
    MEMBER_REGISTRATION_DOCUMENTS(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({ organizationId, userInfoId, eventId })}/documents`;
    },
    MEMBER_REGISTRATION_INFORMATION_EDIT(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: Emptyable<EventIdPathParam>
    ) {
        return `${MEMBER_REGISTRATION({ organizationId, userInfoId, eventId })}/information/edit`;
    },
    MEMBER_REGISTRATION_INFORMATION_EDIT_FORM(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: Emptyable<EventIdPathParam>,
        formId: FormIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({
            organizationId,
            userInfoId,
            eventId
        })}/information/edit/${formId}`;
    },
    MEMBER_REGISTRATION_AVAILABILITY_EDIT(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({ organizationId, userInfoId, eventId })}/availability/edit`;
    },
    MEMBER_REGISTRATION_AVAILABILITY_EDIT_FORM(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam,
        formId: FormIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({
            organizationId,
            userInfoId,
            eventId
        })}/availability/edit/${formId}`;
    },
    MEMBER_REGISTRATION_POSITIONS_EDIT(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({ organizationId, userInfoId, eventId })}/positions/edit`;
    },
    MEMBER_REGISTRATION_POSITIONS_EDIT_FORM(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam,
        formId: FormIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({
            organizationId,
            userInfoId,
            eventId
        })}/positions/edit/${formId}`;
    },
    MEMBER_REGISTRATION_WISHED_ACCREDITATIONS_EDIT(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({
            organizationId,
            userInfoId,
            eventId
        })}/desired-accreditations/edit`;
    },
    MEMBER_REGISTRATION_WISHED_ACCREDITATIONS_EDIT_FORM(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        eventId: EventIdPathParam,
        formId: FormIdPathParam
    ) {
        return `${MEMBER_REGISTRATION({
            organizationId,
            userInfoId,
            eventId
        })}/desired-accreditations/edit/${formId}`;
    },
    EVENT_FORM(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        formId: FormIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/event/${eventId}/form/${formId}`;
    },
    ORGANIZATION_FORM(organizationId: OrganizationIdPathParam, formId: FormIdPathParam) {
        return `${ORGANIZATION(organizationId)}/form/${formId}`;
    },
    DELEGATION_FORM(
        organizationId: OrganizationIdPathParam,
        eventId: Emptyable<EventIdPathParam>,
        delegationId: DelegationIdPathParam,
        formId: FormIdPathParam
    ) {
        if (eventId) {
            return `${ORGANIZATION(
                organizationId
            )}/event/${eventId}/form/${formId}/delegation/${delegationId}`;
        } else {
            return `${ORGANIZATION(organizationId)}/form/${formId}/delegation/${delegationId}`;
        }
    },
    TEAM_FORM(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        formId: FormIdPathParam,
        teamCode: string
    ) {
        return `${ORGANIZATION(organizationId)}/event/${eventId}/form/${formId}/team/${teamCode}`;
    }
};
