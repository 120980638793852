import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormMissionsOptionsFragment,
    FormRegisterCategoryFragment,
    PositionId,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import { Fields } from 'common/src/vo/field';
import { canSelectV2 } from 'common/src/vo/position';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { FormMissionDescriptionModal } from '../formMissionDescriptionModal';
import { FormMissionsPositionsPosition } from './formMissionsPositionsPosition';

interface IFormMissionsPositionsCategoryProps {
    category: FormRegisterCategoryFragment;
    options: FormMissionsOptionsFragment;
    positionsIds: PositionId[];
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfoFields: Fields;

    change(name: string, value: any): void;
}

export const FormMissionsPositionsCategory = (props: IFormMissionsPositionsCategoryProps) => {
    const positions = React.useMemo(
        () =>
            sortBy(
                props.category.positions.filter((position) =>
                    canSelectV2(position, props.userInfoFields, props.slots, props.options)
                ),
                (p) => p.name
            ),
        [props.category, props.userInfoFields, props.slots, props.options]
    );
    const [isDescriptionOpen, setIsDescriptionOpen] = React.useState(false);
    const showDescription =
        isNonEmptyString(props.category.description) &&
        props.options.showPositionCategoryDescription;

    return (
        <>
            <Flex align="center" gap="2">
                <Box color="gray900" fontWeight="semiBold">
                    {props.category.name}
                </Box>

                {showDescription && (
                    <Box
                        color="gray700"
                        onClick={() => {
                            setIsDescriptionOpen(true);
                        }}
                    >
                        <I icon="circle-exclamation" />
                    </Box>
                )}
            </Flex>

            <Spacer height="2" />

            {positions.map((position, positionIndex) => (
                <React.Fragment key={position.id}>
                    {positionIndex !== 0 && <Spacer height="3" />}

                    <FormMissionsPositionsPosition
                        change={props.change}
                        options={props.options}
                        position={position}
                        positionsIds={props.positionsIds}
                        prefix={props.prefix}
                    />
                </React.Fragment>
            ))}

            {isDescriptionOpen && (
                <FormMissionDescriptionModal
                    description={props.category.description}
                    name={props.category.name}
                    onClose={() => {
                        setIsDescriptionOpen(false);
                    }}
                />
            )}
        </>
    );
};
