import { OrganizationIdPathParam } from '../pathsTypes';
import { ActionPaths, buildPath } from './commonPaths';
import { DeprecatedOrganizationsPaths } from './deprecated/organizationsPaths';

export const PathComponents = {
    ITEMS: {
        ORGANIZATIONS: {
            ORGANIZATION: 'organization',
            ORGANIZATIONS: 'organizations'
        }
    },
    SECTIONS: {
        SETTINGS: {
            _BASE: 'parameters',
            MEMBERS_SPACE: {
                _BASE: 'members-space',
                DESIGN: 'design',
                INTEGRATION: 'integration',
                EMAIL_TEMPLATES: 'emails',
                SETTINGS: 'settings'
            },
            DELEGATION_SPACE: {
                _BASE: 'delegation-space',
                DESIGN: 'design',
                INTEGRATION: 'integration',
                EMAIL_TEMPLATES: 'emails',
                SETTINGS: 'settings'
            }
        }
    },
    STATES: {
        BLOCKED: 'blocked',
        EXPIRED: 'expired'
    }
};

const ItemPaths = {
    ORGANIZATIONS: PathComponents.ITEMS.ORGANIZATIONS.ORGANIZATIONS,
    ORGANIZATION: (organizationId: OrganizationIdPathParam) =>
        buildPath([PathComponents.ITEMS.ORGANIZATIONS.ORGANIZATION, organizationId])
};

const StatePaths = {
    BLOCKED: (path: string) => buildPath([path, PathComponents.STATES.BLOCKED]),
    EXPIRED: (path: string) => buildPath([path, PathComponents.STATES.EXPIRED])
};

export const OrganizationsPaths = {
    // Access Paths
    ORGANIZATIONS: ItemPaths.ORGANIZATIONS,
    ORGANIZATIONS_LIST: ActionPaths.SHOW.LIST(ItemPaths.ORGANIZATIONS),
    ORGANIZATION: (organizationId: OrganizationIdPathParam) =>
        '/' + ItemPaths.ORGANIZATION(organizationId),

    // Organization Settings
    ORGANIZATION_SETTINGS: (organizationId: OrganizationIdPathParam) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION(organizationId),
            PathComponents.SECTIONS.SETTINGS._BASE
        ]),

    // Members' space
    ORGANIZATION_SETTINGS_MEMBERS_SPACE: (organizationId: OrganizationIdPathParam) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS(organizationId),
            PathComponents.SECTIONS.SETTINGS.MEMBERS_SPACE._BASE
        ]),
    ORGANIZATION_SETTINGS_MEMBERS_SPACE_DESIGN: (organizationId: OrganizationIdPathParam) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE(organizationId),
            PathComponents.SECTIONS.SETTINGS.MEMBERS_SPACE.DESIGN
        ]),
    ORGANIZATION_SETTINGS_MEMBERS_SPACE_INTEGRATION: (organizationId: OrganizationIdPathParam) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE(organizationId),
            PathComponents.SECTIONS.SETTINGS.MEMBERS_SPACE.INTEGRATION
        ]),
    ORGANIZATION_SETTINGS_MEMBERS_SPACE_EMAIL_TEMPLATES: (
        organizationId: OrganizationIdPathParam
    ) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE(organizationId),
            PathComponents.SECTIONS.SETTINGS.MEMBERS_SPACE.EMAIL_TEMPLATES
        ]),
    ORGANIZATION_SETTINGS_MEMBERS_SPACE_SETTINGS: (organizationId: OrganizationIdPathParam) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE(organizationId),
            PathComponents.SECTIONS.SETTINGS.MEMBERS_SPACE.SETTINGS
        ]),

    // Delegation Space
    ORGANIZATION_SETTINGS_DELEGATION_SPACE: (organizationId: OrganizationIdPathParam) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS(organizationId),
            PathComponents.SECTIONS.SETTINGS.DELEGATION_SPACE._BASE
        ]),
    ORGANIZATION_SETTINGS_DELEGATION_SPACE_DESIGN: (organizationId: OrganizationIdPathParam) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE(organizationId),
            PathComponents.SECTIONS.SETTINGS.DELEGATION_SPACE.DESIGN
        ]),
    ORGANIZATION_SETTINGS_DELEGATION_SPACE_INTEGRATION: (organizationId: OrganizationIdPathParam) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE(organizationId),
            PathComponents.SECTIONS.SETTINGS.DELEGATION_SPACE.INTEGRATION
        ]),
    ORGANIZATION_SETTINGS_DELEGATION_SPACE_EMAIL_TEMPLATES: (
        organizationId: OrganizationIdPathParam
    ) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE(organizationId),
            PathComponents.SECTIONS.SETTINGS.DELEGATION_SPACE.EMAIL_TEMPLATES
        ]),
    ORGANIZATION_SETTINGS_DELEGATION_SPACE_SETTINGS: (organizationId: OrganizationIdPathParam) =>
        buildPath([
            OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE(organizationId),
            PathComponents.SECTIONS.SETTINGS.DELEGATION_SPACE.SETTINGS
        ]),

    // Action Paths
    CREATE_ORGANIZATION: '/' + buildPath([ActionPaths.CREATE(ItemPaths.ORGANIZATIONS)]),
    EDIT_ORGANIZATION: (organizationId: OrganizationIdPathParam) =>
        buildPath([ActionPaths.EDIT(OrganizationsPaths.ORGANIZATION(organizationId))]),
    ARCHIVE_ORGANIZATION: (organizationId: OrganizationIdPathParam) =>
        buildPath([ActionPaths.ARCHIVE(OrganizationsPaths.ORGANIZATION(organizationId))]),

    // State Paths
    ORGANIZATION_IS_BLOCKED: (organizationId: OrganizationIdPathParam) =>
        buildPath([StatePaths.BLOCKED(OrganizationsPaths.ORGANIZATION(organizationId))]),
    ORGANIZATION_IS_EXPIRED: (organizationId: OrganizationIdPathParam) =>
        buildPath([StatePaths.EXPIRED(OrganizationsPaths.ORGANIZATION(organizationId))]),

    // Deprecated Paths
    __Deprecated: DeprecatedOrganizationsPaths
};
