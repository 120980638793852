import { ValidationErrors } from 'final-form';
import * as React from 'react';

interface IFormContext {
    errors: ValidationErrors;
    showErrors: boolean;
    submitErrors: ValidationErrors;

    setShowErrors(value: boolean): void;
}

export const FormContext = React.createContext<IFormContext>({} as any);
