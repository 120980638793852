import { Emptyable } from 'common/src/util/emptyable';

export function loadImage(url: Emptyable<string>): Promise<HTMLImageElement | null> {
    if (url) {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.onload = () => {
                resolve(image);
            };

            image.onerror = () => {
                reject(new Error('Could not load image'));
            };

            image.src = url;
        });
    } else {
        return Promise.resolve(null);
    }
}
