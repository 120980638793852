import { Box } from 'common/src/designSystem/components/box';
import { MemberQuery } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { reverse, sortBy } from 'lodash-es';
import * as React from 'react';
import { MemberAssignment } from './memberAssignment';

interface IMemberAssignmentsProps {
    registrations: MemberQuery['userInfo']['volunteersRegistrations'];
}

export const MemberAssignments = ({ registrations }: IMemberAssignmentsProps) => {
    const translate = useTranslate();

    return registrations.length === 0 ? (
        <Box color="gray500" textAlign="center" css={{ padding: '$4' }}>
            {translate('aucun_v_nement_76977')}
        </Box>
    ) : (
        <>
            {reverse(sortBy(registrations, ['event.startAt'])).map((registration) => (
                <MemberAssignment key={registration.event.id} registration={registration} />
            ))}
        </>
    );
};
