import { Separator } from 'common-front/src/designSystem/components/separator';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { toFormElements } from 'common-front/src/vo/customField';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormElementType,
    FormId,
    UpdateUserFormFragment,
    UpdateUserInfosEventQuery,
    UpdateUserInfosOrganizationQuery
} from 'common/src/generated/types';
import { IUpdateUserInfoValues } from 'common/src/input/userInfoInput';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { addOrRemove } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { DateTime } from 'luxon';
import * as React from 'react';
import { UserCreateForm } from '../../components/users/create/userCreateForm';
import { Select } from '../../designSystem/components/select/select';
import { useReferrerContext } from '../../util/referrerContext';
import { UpdateUserTeamCode } from './updateUserTeamCode';
import { UserUpdateLayout } from './userUpdateLayout';

interface IUpdateUserProps {
    banner?: React.ReactNode;
    customFields: UpdateUserInfosOrganizationQuery['organization']['customFields']['nodes'];
    fallbackClosePath: string;
    formIdToInsertedAt: Record<FormId, DateTime>;
    forms: UpdateUserFormFragment[];
    isLoading: boolean;
    showAllAndPrivate: boolean;
    showIsFilled: boolean;
    userInfo: Omit<UpdateUserInfosEventQuery['organization']['userInfo'], 'formsUsersInfos'>;
    values: IUpdateUserInfoValues;

    change(name: string, value: any): void;
    getEditUserFormPath(formId: FormId | 'all' | 'private'): string;
    handleSubmit(): void;
}

export const UpdateUser = ({
    banner,
    change,
    customFields,
    fallbackClosePath,
    formIdToInsertedAt,
    forms,
    getEditUserFormPath,
    handleSubmit,
    isLoading,
    showAllAndPrivate,
    showIsFilled,
    userInfo,
    values
}: IUpdateUserProps) => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const { getReferrerPath } = useReferrerContext();
    const dateTimeService = useService(DateTimeService);
    const privateElements = React.useMemo(
        () =>
            customFields
                .filter((cf) => cf.isPrivate)
                .map((customField) => ({
                    elementType: FormElementType.Field,
                    customField
                })),
        [customFields]
    );
    const allElements = React.useMemo(() => toFormElements(customFields), [customFields]);
    const renderSection = React.useCallback(
        (section: string, index: number) => (
            <>
                {index !== 0 && <Spacer height="7" />}

                <Box font="gray900 textLg medium">{section}</Box>

                <Spacer height="4" />

                <Separator direction="horizontal" />
            </>
        ),
        []
    );

    return (
        <UserUpdateLayout
            banner={banner}
            extraTabs={
                showAllAndPrivate ? (
                    <>
                        <Tab path={getEditUserFormPath('all')}>
                            {translate('tous_les_champs_57939')}
                        </Tab>

                        <Tab path={getEditUserFormPath('private')}>
                            {translate('champs_priv_s_84670')}
                        </Tab>
                    </>
                ) : undefined
            }
            forms={forms}
            getTabPath={getEditUserFormPath}
            isLoading={isLoading}
            title={translate('mise_jour_de_88322', userInfo.nameOrEmail)}
            onCancel={() => {
                history.push(getReferrerPath() ?? fallbackClosePath);
            }}
            onUpdate={handleSubmit}
        >
            {forms.map((form) => {
                const insertedAt = formIdToInsertedAt[form.id];

                return (
                    <TabPanel key={form.id} path={getEditUserFormPath(form.id)}>
                        <Spacer height="7" />

                        {showIsFilled && (
                            <>
                                <Flex
                                    align="center"
                                    css={{
                                        background: '$gray100',
                                        borderRadius: '$2',
                                        padding: '$4'
                                    }}
                                    gap="4"
                                >
                                    <Box css={{ flex: '1' }} font="gray800 textMd semiBold">
                                        {translate('statut_du_formu_08195')}
                                    </Box>

                                    <Box width={320}>
                                        <Select
                                            shouldParseAsBoolean={true}
                                            state={insertedAt ? 'disabled' : 'active'}
                                            value={values.filledFormsIds.includes(form.id)}
                                            onChange={(shouldAdd) => {
                                                change(
                                                    'filledFormsIds',
                                                    addOrRemove(
                                                        values.filledFormsIds,
                                                        form.id,
                                                        shouldAdd
                                                    )
                                                );
                                            }}
                                        >
                                            <option value="true">
                                                {insertedAt
                                                    ? translate(
                                                          'rempli_le_1_29292',
                                                          dateTimeService.toLocaleString(
                                                              insertedAt,
                                                              LocaleFormats.DateTime
                                                          )
                                                      )
                                                    : translate('rempli_78674')}
                                            </option>
                                            <option value="false">
                                                {translate('non_rempli_80877')}
                                            </option>
                                        </Select>
                                    </Box>
                                </Flex>

                                <Spacer height="6" />

                                {form.areTeamsAllowed && (
                                    <UpdateUserTeamCode change={change} form={form} />
                                )}
                            </>
                        )}

                        <UserCreateForm
                            change={change}
                            elements={form.elements}
                            organizationId={organizationId}
                            prefix="userInfo.fields."
                            renderSection={renderSection}
                            values={values.userInfo.fields}
                        />
                    </TabPanel>
                );
            })}

            {showAllAndPrivate && (
                <TabPanel path={getEditUserFormPath('all')}>
                    <Spacer height="7" />

                    <UserCreateForm
                        change={change}
                        elements={allElements}
                        organizationId={organizationId}
                        prefix="userInfo.fields."
                        renderSection={renderSection}
                        values={values.userInfo.fields}
                    />
                </TabPanel>
            )}

            {showAllAndPrivate && (
                <TabPanel path={getEditUserFormPath('private')}>
                    <Spacer height="7" />

                    <UserCreateForm
                        change={change}
                        elements={privateElements}
                        organizationId={organizationId}
                        prefix="userInfo.fields."
                        renderSection={renderSection}
                        values={values.userInfo.fields}
                    />
                </TabPanel>
            )}
        </UserUpdateLayout>
    );
};
