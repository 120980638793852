import { Interval } from 'luxon';
import {
    DelegationAccreditationSlotFragment,
    FormAccreditationsOptionsFragment,
    FormRegisterAccreditationCategoryFragment,
    RegisterAccreditationDisplay,
    VolunteersRegistrationsSlotInput
} from '../generated/types';
import { canSelect as canSelectAccreditation } from './accreditation';
import { Fields } from './field';

export function canSelect(
    category: FormRegisterAccreditationCategoryFragment,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    userFields: Fields,
    options: FormAccreditationsOptionsFragment,
    delegationAccreditationsSlots: DelegationAccreditationSlotFragment[]
): boolean {
    return (
        (options.accreditationDisplay !== RegisterAccreditationDisplay.None &&
            !options.hiddenAccreditationsCategoriesIds.includes(category.id) &&
            (options.displayedAccreditationsCategoriesIds.length === 0 ||
                options.displayedAccreditationsCategoriesIds.includes(category.id)) &&
            category.accreditations.some((accreditation) =>
                canSelectAccreditation(
                    accreditation,
                    ranges,
                    userFields,
                    options,
                    delegationAccreditationsSlots
                )
            )) ||
        delegationAccreditationsSlots.some(
            (da) =>
                da.accreditationCategoryId === category.id &&
                category.accreditations.some((accreditation) =>
                    canSelectAccreditation(
                        accreditation,
                        ranges,
                        userFields,
                        options,
                        delegationAccreditationsSlots
                    )
                )
        )
    );
}
