import { injectable } from 'inversify';
import * as yup from 'yup';
import { DocumentInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { isNonEmptyString } from '../util/string';

export const DOCUMENT_INPUT_KEYS = ['key', 'name', 'acl'];

@injectable()
export class DocumentInputService {
    constructor(private translationService: TranslationService) {}

    documentInputDefault(): DocumentInput {
        return { key: '', name: '', acl: '' };
    }

    documentInputSchema(name?: string) {
        const errorMessage = isNonEmptyString(name)
            ? this.translationService.translate('_1_est_requis_99856', name)
            : this.translationService.translate('le_document_est_86403');

        return yup.object().shape({
            key: yup.string().required(errorMessage),
            name: yup.string().required(errorMessage),
            acl: yup.string().required(errorMessage)
        });
    }

    documentInputSchemaNotRequired() {
        return yup.object().shape({
            key: yup.string(),
            name: yup.string(),
            acl: yup.string()
        });
    }
}
