import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { MembersPaths } from 'common/src/util/membersPaths';
import * as React from 'react';

export const DelegationForm = () => {
    const {
        history,
        params: { organizationId, eventId, delegationId, formId }
    } = useHeavent();

    React.useEffect(() => {
        history.push(MembersPaths.DELEGATION_FORM(organizationId, eventId, delegationId, formId));
    }, []);

    return <div />;
};
