import { SupportedLanguage } from 'common/src/generated/types';
import { fromString } from 'common/src/vo/supportedLanguage';
import { CurrentUser } from '../vo/user';

export function getLanguage(
    user: CurrentUser | null,
    searchParams: URLSearchParams
): SupportedLanguage {
    if (user) {
        return user.language;
    } else {
        if (searchParams.has('lang')) {
            return fromString(searchParams.get('lang')) || SupportedLanguage.En;
        } else {
            return fromString(navigator.language) || SupportedLanguage.En;
        }
    }
}
