import { CommonEnvVars } from '../envVars';
import { OrganizationId } from '../generated/types';
import {
    CLUB_FRANCE_2024,
    displayClubFrance2024Links,
    displayPartagetapassiongLinks,
    displayStaffInsatiablesFrLinks,
    STAFF_INSATIABLES_FR,
    WWW_PARTAGETAPASSION_COM
} from '../thirdPartyDomains';

export function getDelegationLink(organizationId: OrganizationId) {
    const path = `v2/organization/${organizationId}/delegations/auth`;

    if (displayPartagetapassiongLinks(organizationId)) {
        return `https://${WWW_PARTAGETAPASSION_COM}/${path}`;
    } else if (displayStaffInsatiablesFrLinks(organizationId)) {
        return `https://${STAFF_INSATIABLES_FR}/${path}`;
    } else if (displayClubFrance2024Links(organizationId)) {
        return `https://${CLUB_FRANCE_2024}/${path}`;
    } else {
        return `https://event.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}/${path}`;
    }
}

export function getDefaultColumns() {
    return ['name', 'accreditations', 'accreditation_state'];
}
