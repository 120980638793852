import { DesignSytemColor } from './color';

type DesignSystemBorderRadius = '1' | '2' | '3';

type DesignSystemBoxShadow = 'xs' | 'sm' | 'md' | 'mdReverse' | 'lg' | 'xl' | '2xl' | '3xl';

export type DesignSytemBorderColorRadius = `${DesignSytemColor} ${DesignSystemBorderRadius}`;

export type DesignSystemBorder =
    `${DesignSytemColor} ${DesignSystemBorderRadius} ${DesignSystemBoxShadow}`;

type IConvertBorderStringToPropertiesResult = {
    borderColor?: DesignSytemColor;
    borderRadius?: DesignSystemBorderRadius;
    boxShadow?: DesignSystemBoxShadow;
};

export function convertBorderStringToProperties(
    border?: DesignSystemBorder | DesignSytemBorderColorRadius
): IConvertBorderStringToPropertiesResult {
    if (border) {
        const [borderColor, borderRadius, boxShadow] = border.split(' ');

        return { borderColor, borderRadius, boxShadow } as IConvertBorderStringToPropertiesResult;
    } else {
        return {};
    }
}
