import { DateTime, Duration } from 'luxon';
import { DATE_FIELDS, DATE_TIME_FIELDS, DURATION_FIELDS, RANGE_FIELDS } from '../generated/types';
import { parseInterval } from '../util/luxon';

export function parseGraphqlResult(result: any): any {
    if (Array.isArray(result) === true) {
        return result.map((r: any) => {
            if (r !== undefined && r !== null && typeof r === 'object') {
                return parseGraphqlResult(r);
            } else {
                return r;
            }
        });
    } else {
        if (
            typeof result === 'boolean' ||
            typeof result === 'number' ||
            typeof result === 'string'
        ) {
            return result;
        } else {
            const parsedResult: any = {};

            Object.keys(result).forEach((key: string) => {
                const value = result[key];

                if (DATE_FIELDS.includes(key)) {
                    parsedResult[key] = value && DateTime.fromISO(value, { zone: 'UTC' });
                } else if (DATE_TIME_FIELDS.includes(key)) {
                    parsedResult[key] = value && DateTime.fromISO(value, { zone: 'UTC' });
                } else if (RANGE_FIELDS.includes(key)) {
                    if (Array.isArray(value)) {
                        parsedResult[key] = value.map(parseInterval);
                    } else {
                        parsedResult[key] = value && parseInterval(value);
                    }
                } else if (DURATION_FIELDS.includes(key)) {
                    parsedResult[key] = value && Duration.fromISO(value);
                } else if (value !== undefined && value !== null && typeof value === 'object') {
                    parsedResult[key] = parseGraphqlResult(value);
                } else {
                    parsedResult[key] = value;
                }
            });

            return parsedResult;
        }
    }
}
