import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormMissionsOptionsFragment,
    FormRegisterSlotFragment,
    PositionsSlotId
} from 'common/src/generated/types';
import { usePositionSlotIsRenamedRange } from 'common/src/hooks/usePositionSlotIsRenamedRange';
import { addOrRemove } from 'common/src/util/array';
import { overlaps } from 'common/src/vo/positionSlot';
import { noop } from 'lodash-es';
import * as React from 'react';
import { CheckboxText } from '../../../designSystem/components/checkbox';
import { Content } from '../../../designSystem/components/tooltip/content';
import { Tooltip } from '../../../designSystem/components/tooltip/tooltip';
import { Trigger } from '../../../designSystem/components/tooltip/trigger';
import { useHeavent } from '../../../hooks/useHeavent';

interface IFormMissionsSlotsSlotProps {
    allSlots: FormRegisterSlotFragment[];
    index: number;
    options: FormMissionsOptionsFragment;
    positionsSlotsIds: PositionsSlotId[];
    prefix: string;
    slot: FormRegisterSlotFragment;

    change(name: string, value: any): void;
}

export const FormMissionsSlotsSlot = (props: IFormMissionsSlotsSlotProps) => {
    const { translate } = useHeavent();
    const { rangeString, finalName, isRenamed } = usePositionSlotIsRenamedRange(props.slot);
    const isOverlapping = React.useMemo(() => {
        if (props.options.willAutoAssign) {
            const selectedPositionsSlots = props.allSlots.filter((s) =>
                props.positionsSlotsIds.includes(s.id)
            );

            return overlaps(props.slot, selectedPositionsSlots);
        } else {
            return false;
        }
    }, [props.options.willAutoAssign, props.allSlots, props.positionsSlotsIds]);

    return (
        <React.Fragment key={props.slot.id}>
            {props.index !== 0 && <Spacer height="3" />}

            {isOverlapping ? (
                <Tooltip>
                    <Trigger>
                        <Box>
                            <CheckboxText
                                disabled={true}
                                state={
                                    props.positionsSlotsIds.includes(props.slot.id)
                                        ? 'checked'
                                        : 'unchecked'
                                }
                                subtext={isRenamed ? rangeString : undefined}
                                onClick={noop}
                            >
                                {finalName}
                            </CheckboxText>
                        </Box>
                    </Trigger>

                    <Content placement="top-start">{translate('ce_cr_neau_en_c_55775')}</Content>
                </Tooltip>
            ) : (
                <CheckboxText
                    state={
                        props.positionsSlotsIds.includes(props.slot.id) ? 'checked' : 'unchecked'
                    }
                    subtext={isRenamed ? rangeString : undefined}
                    onClick={(state) => {
                        props.change(
                            `${props.prefix}positionsSlotsIds`,
                            addOrRemove(props.positionsSlotsIds, props.slot.id, state === 'checked')
                        );
                    }}
                >
                    {finalName}
                </CheckboxText>
            )}
        </React.Fragment>
    );
};
