import { UserField } from 'common-front/src/components/users/show/userField';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { localDatabase } from 'common-front/src/localDatabase';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormElementType,
    FormId,
    FormMemberRegistrationFragment,
    FormUserOverlayFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { UserInfo, shouldDisplay } from 'common/src/vo/field';
import { getFormField } from 'common/src/vo/form';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useReferrerContext } from '../../util/referrerContext';

export type FormUserOverlay = Omit<FormUserOverlayFragment, 'id'> & {
    id: FormId | 'all';
};

interface IUserInformationFormProps {
    filledAt?: DateTime;
    form: FormUserOverlay | FormMemberRegistrationFragment;
    showIsFilled: boolean;
    userInfo: UserInfo;

    getEditUserFormPath(formId: FormId | 'all'): string;
}

export const UserInformationForm = (props: IUserInformationFormProps) => {
    const { history, translate } = useHeavent();
    const { setReferrerPath } = useReferrerContext();
    const dateTimeService = useService(DateTimeService);
    const [isOpen, setIsOpen] = React.useState(true);
    const toggleIsOpen = React.useCallback(async () => {
        const open = !isOpen;

        setIsOpen(open);

        await localDatabase.forms.put({
            id: props.form.id,
            isOpenInUserOverlay: open
        });
    }, [isOpen, setIsOpen]);
    const customFields = React.useMemo(
        () =>
            props.form.elements.flatMap((element) => {
                if (element.elementType === FormElementType.Field) {
                    return [element.customField!];
                } else {
                    return [];
                }
            }),
        [props.form]
    );
    const formField = props.form.id !== 'all' ? getFormField(props.form.id, translate) : null;

    React.useEffect(() => {
        (async () => {
            setIsOpen((await localDatabase.forms.get(props.form.id))?.isOpenInUserOverlay ?? true);
        })();
    }, []);

    return (
        <Flex
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs'
            }}
            direction="column"
            width={1}
        >
            <Flex
                align="center"
                css={{ cursor: 'pointer', padding: '$5 $6', userSelect: 'none' }}
                gap="4"
                onClick={toggleIsOpen}
            >
                <Box>
                    <I icon={isOpen ? 'chevron-up' : 'chevron-down'} />
                </Box>

                <Flex align="start" css={{ flex: '1' }} direction="column" gap="1">
                    <Box>{props.form.name}</Box>

                    {props.showIsFilled ? (
                        props.filledAt ? (
                            <Badge color="success" size="sm">
                                {translate(
                                    'rempli_le_1_29292',
                                    dateTimeService.toLocaleString(
                                        props.filledAt.toLocal(),
                                        LocaleFormats.DateTime
                                    )
                                )}
                            </Badge>
                        ) : (
                            <Badge color="error" size="sm">
                                {translate('non_rempli_80877')}
                            </Badge>
                        )
                    ) : null}
                </Flex>

                {(props.form as FormMemberRegistrationFragment).isEditableInMemberSpace !==
                    false && (
                    <Button
                        color="white"
                        size="sm"
                        onClick={() => {
                            setReferrerPath();
                            history.push(props.getEditUserFormPath(props.form.id));
                        }}
                    >
                        {translate('_diter_62574')}
                    </Button>
                )}
            </Flex>

            {isOpen && (
                <Flex css={{ borderTop: '1px solid $gray200', padding: '$6' }} direction="column">
                    {props.form.elements.map((element, index) => {
                        switch (element.elementType) {
                            case FormElementType.Field: {
                                const display = shouldDisplay(
                                    element.customField!,
                                    props.userInfo.fields,
                                    customFields
                                );

                                return display ? (
                                    <React.Fragment key={element.id}>
                                        <UserField
                                            field={element.customField!}
                                            userInfo={props.userInfo}
                                        />

                                        {index !== props.form.elements.length - 1 && (
                                            <Spacer height="6" />
                                        )}
                                    </React.Fragment>
                                ) : null;
                            }
                            case FormElementType.Section:
                                return (
                                    <React.Fragment key={element.id}>
                                        {index !== 0 && <Spacer height="2" />}

                                        <Box color="gray800" fontWeight="semiBold">
                                            {element.section}
                                        </Box>

                                        {index !== props.form.elements.length - 1 && (
                                            <Spacer height="4" />
                                        )}
                                    </React.Fragment>
                                );
                            case FormElementType.Text:
                                return null;
                            default:
                                return assertUnreachable(element.elementType);
                        }
                    })}

                    {formField && isNonEmptyString(props.userInfo.fields[formField.slug]) && (
                        <>
                            <Spacer height="6" />

                            <Box color="gray800" fontWeight="semiBold">
                                {translate('inscription_en_49989')}
                            </Box>

                            <Spacer height="4" />

                            <UserField field={formField} userInfo={props.userInfo} />
                        </>
                    )}
                </Flex>
            )}
        </Flex>
    );
};
