import { Box } from 'common/src/designSystem/components/box';
import {
    AccreditationsSlotId,
    FormRegisterAccreditationFragment,
    FormRegisterAccreditationSlotFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { fullName } from 'common/src/vo/accreditationSlot';
import * as React from 'react';
import { IRadioState, RadioText } from '../../../designSystem/components/radio';

interface IFormAccreditationsSlotsRadioProps {
    accreditation: FormRegisterAccreditationFragment;
    accreditationsSlotsIds: AccreditationsSlotId[];
    prefix: string;
    slots: FormRegisterAccreditationSlotFragment[];

    change(name: string, value: any): void;
}

export const FormAccreditationsSlotsRadio = (props: IFormAccreditationsSlotsRadioProps) => {
    const dateTimeService = useService(DateTimeService);
    const slotsIds = React.useMemo(() => props.slots.map((s) => s.id), [props.slots]);

    return (
        <>
            {props.slots.map((slot) => (
                <RadioText
                    key={slot.id}
                    state={props.accreditationsSlotsIds.includes(slot.id) ? 'checked' : 'unchecked'}
                    onClick={(state: IRadioState) => {
                        const newSlotsIds = [
                            ...props.accreditationsSlotsIds.filter((id) => !slotsIds.includes(id)),
                            ...(state === 'checked' ? [slot.id] : [])
                        ];

                        props.change(`${props.prefix}accreditationsSlotsIds`, newSlotsIds);
                    }}
                >
                    <Box css={{ textTransform: 'capitalize' }}>
                        {fullName(dateTimeService, slot, props.accreditation.name, {
                            format: LocaleFormats.DateOnly.WeekdayLongMonthLong
                        })}
                    </Box>
                </RadioText>
            ))}
        </>
    );
};
