import { Button } from 'common-front/src/designSystem/components/button';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { MemberAccreditationFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { MemberRegistrationAccreditationInfo } from './memberRegistrationAccreditationInfo';

interface IMemberRegistrationAccreditationProps {
    isSelected: boolean;
    accreditationUserInfo: MemberAccreditationFragment;

    onClick: any;
}

export const MemberRegistrationAccreditation = ({
    isSelected,
    accreditationUserInfo,
    onClick
}: IMemberRegistrationAccreditationProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const accreditation = accreditationUserInfo.accreditation;
    const accreditationSlot = accreditationUserInfo.accreditationSlot;
    const isMobile = useMobileQuery();

    return (
        <Flex
            direction="column"
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs'
            }}
        >
            <Flex
                css={{
                    borderBottom: accreditation.hasSlots ? '1px solid $gray200' : 'unset',
                    padding: '$4'
                }}
                justify={'between'}
            >
                <Box font="gray900 textLg medium" css={{ lineHeight: '2em' }}>
                    {accreditationSlot.name || accreditation.name}
                </Box>

                {isMobile && (
                    <Button
                        color="white"
                        leftIcon={isSelected ? 'chevron-up' : 'chevron-down'}
                        onClick={onClick}
                    />
                )}
            </Flex>

            {(!isMobile || isSelected) && accreditation.hasSlots && (
                <Flex
                    direction="column"
                    gap={{ '@initial': '6', '@desktop': '7' }}
                    css={{ padding: '$4 $6' }}
                >
                    {accreditationSlot.date && (
                        <MemberRegistrationAccreditationInfo title={translate('Datetime')}>
                            {dateTimeService.toLocaleString(
                                accreditationSlot.date,
                                LocaleFormats.DateTime
                            )}
                        </MemberRegistrationAccreditationInfo>
                    )}

                    {isNonEmptyString(accreditation.description) && (
                        <MemberRegistrationAccreditationInfo title={translate('description_58935')}>
                            <RichText text={accreditation.description} />
                        </MemberRegistrationAccreditationInfo>
                    )}
                </Flex>
            )}
        </Flex>
    );
};
