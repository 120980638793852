import * as React from 'react';
import { useTooltipContext } from './tooltip';

interface ITriggerProps {
    children: React.ReactNode;
}

export const Trigger = (props: ITriggerProps) => {
    const { setIsVisible, setReferenceElement } = useTooltipContext();
    const child = React.cloneElement(React.Children.only(props.children as any), {
        ref(ref: HTMLDivElement) {
            setReferenceElement(ref);
        },
        onMouseEnter() {
            setIsVisible(true);
        },
        onMouseLeave() {
            setIsVisible(false);
        },
        onFocus() {
            setIsVisible(true);
        },
        onBlur() {
            setIsVisible(false);
        }
    });

    return <>{child}</>;
};
