import { Emptyable } from './emptyable';
import { isNonEmptyString } from './string';

const HOURS_MINUTES_REGEX = /^\d{2}:\d{2}$/;
const HOURS_MINUTES_SECONDS_REGEX = /^\d{2}:\d{2}:\d{2}$/;

export function isValidTime(time?: string | null): boolean {
    return (
        isNonEmptyString(time) &&
        (time.match(HOURS_MINUTES_REGEX) !== null ||
            time.match(HOURS_MINUTES_SECONDS_REGEX) !== null)
    );
}

export function parseTime(time: Emptyable<string>): [number, number] | null {
    if (isValidTime(time)) {
        const [startHours, startMinutes] = time!.split(':');

        return [parseInt(startHours, 10), parseInt(startMinutes, 10)];
    } else {
        return null;
    }
}
