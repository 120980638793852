import { createTheme } from 'common/src/designSystem/components/stitches';
import { designDefaultColors } from 'common/src/input/designInput';

export function addCustomTheme(colors: any) {
    const organizationTheme = createTheme({
        colors: {
            ...designDefaultColors(colors)
        }
    });

    document.getElementById('heavent-react-hook')?.classList.add(organizationTheme.toString());
}
