import { injectable } from 'inversify';
import * as yup from 'yup';
import { CommentInput } from '../generated/types';
import { TranslationService } from '../services/translationService';

export interface ICreateCommentValues {
    comment: CommentInput;
}

@injectable()
export class CommentInputService {
    constructor(private translationService: TranslationService) {}

    commentInputDefault(): CommentInput {
        return { content: '' };
    }

    commentInputSchema() {
        return yup.object().shape({
            content: yup
                .string()
                .required(this.translationService.translate('le_commentaire_33393'))
        });
    }

    createCommentSchema() {
        return yup.object().shape({
            comment: this.commentInputSchema()
        });
    }
}
