import { DelegationId, EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Empty } from './components/empty/empty';
import { useUserContext } from './userContext';

interface IRenderComponentProps {
    children: React.ReactNode;
    shouldHaveUser: boolean;

    getRedirectPath(params: {
        delegationId?: DelegationId;
        eventId?: EventId;
        organizationId?: OrganizationId;
        userInfoId?: UsersInfoId;
    }): string;
}

export const Authenticate = (props: IRenderComponentProps) => {
    const params = useParams();
    const { user } = useUserContext();

    // if we have a user and we should have one
    // or we don't have a user and we shouldn't have one
    // then we render the component
    // otherwise we redirect
    if ((user && props.shouldHaveUser) || (!user && !props.shouldHaveUser)) {
        return <>{props.children}</>;
    } else {
        return <Empty path={props.getRedirectPath(params)} />;
    }
};
