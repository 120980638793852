import * as React from 'react';
import { Field } from 'react-final-form';
import { DateInput as DateInputComponent } from '../../components/date/dateInput';
import { IDatetimeInputProps } from '../../components/date/datetimeInput';
import { IFormInputProps } from '../../components/input/commonInputProps';

export const DateInput = ({ name, hint, state, ...rest }: IFormInputProps<IDatetimeInputProps>) => (
    <Field
        name={name}
        render={(fieldProps) => {
            const invalid =
                (fieldProps.meta.data?.forceDisplayError && fieldProps.meta.invalid) ||
                (fieldProps.meta.touched && fieldProps.meta.invalid);

            return (
                <DateInputComponent
                    hint={invalid ? fieldProps.meta.error : hint}
                    name={name}
                    state={invalid ? 'error' : state}
                    value={fieldProps.input.value}
                    onBlur={fieldProps.input.onBlur}
                    onChange={fieldProps.input.onChange}
                    onFocus={fieldProps.input.onFocus}
                    {...rest}
                />
            );
        }}
    />
);
