import { range, uniqWith } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { styled } from '../../../designSystem/components/stitches';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { BadgeRes2024Parking } from './badgeRes2024Parking';

const BorderedBox = styled('div', {
    alignItems: 'center',
    border: '1px solid',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '4px 6px'
});

export const ContremarqueRes2024 = ({ event }: { event: DocumentUserCustomBadgeFragment }) => {
    const vr = event.volunteerRegistration;
    const ui = vr.userInfo;
    const montage = vr.accreditationsUsersInfos.some((aui) => aui.accreditation.id === 2872);
    const demontage = vr.accreditationsUsersInfos.some((aui) => aui.accreditation.id === 2873);
    const dates = uniqWith(
        vr.accreditationsUsersInfos.flatMap((aui) => {
            if (
                aui.accreditationSlot.date &&
                range(21, 26).includes(aui.accreditationSlot.date.day)
            ) {
                return aui.accreditationSlot.date;
            } else {
                return [];
            }
        }),
        (dt1, dt2) => +dt1 === +dt2
    ).sort((dt1, dt2) => (dt1 < dt2 ? -1 : dt1 > dt2 ? 1 : 0));

    return (
        <>
            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/res/2024/Contremarque.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$rubik',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Flex
                    css={{
                        alignItems: 'center',
                        gap: '8px',
                        justifyContent: 'space-between',
                        left: '80px',
                        position: 'absolute',
                        right: '80px',
                        top: '322px'
                    }}
                >
                    <Flex css={{ flexDirection: 'column', gap: '8px' }}>
                        <Box
                            css={{
                                fontSize: '24px',
                                fontWeight: 600,
                                lineHeight: '28px',
                                textTransform: 'uppercase'
                            }}
                        >
                            <Box>{ui.firstName}</Box>
                            <Box>{ui.lastName}</Box>
                        </Box>
                        <Box
                            css={{
                                color: '#67BC9B',
                                fontSize: '24px',
                                fontWeight: 600,
                                lineHeight: '28px',
                                textTransform: 'capitalize'
                            }}
                        >
                            {vr.delegation?.name ?? ''}
                        </Box>
                    </Flex>
                    <Flex align="center" css={{ gap: '7px' }} direction="column">
                        <Box height={90} width={90}>
                            {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                                <img
                                    height="100%"
                                    src={`https://api.${
                                        CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                    }/barcode/qr?text=${encodeURIComponent(
                                        vr.weezeventParticipantInfo!.idBarcode
                                    )}&color=000000&height=90&width=90`}
                                    width="100%"
                                />
                            )}
                        </Box>
                        <Box
                            css={{
                                fontSize: '10px',
                                lineHeight: 'normal',
                                textTransform: 'uppercase'
                            }}
                        >
                            {vr.weezeventParticipantInfo?.idBarcode ?? ''}
                        </Box>
                    </Flex>
                </Flex>
                <Flex
                    css={{
                        gap: '6px',
                        left: '80px',
                        position: 'absolute',
                        top: '641px'
                    }}
                >
                    {montage && (
                        <BorderedBox
                            css={{
                                fontSize: '10px',
                                fontWeight: 700,
                                lineHeight: 'normal',
                                minHeight: '42px',
                                textTransform: 'uppercase'
                            }}
                        >
                            Montage
                        </BorderedBox>
                    )}
                    {dates.map((date, i) => (
                        <BorderedBox key={i}>
                            <Box css={{ fontSize: '14px', fontWeight: 700, lineHeight: '20px' }}>
                                {date.day}
                            </Box>
                            <Box
                                style={{
                                    fontSize: '10px',
                                    lineHeight: 'normal',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {date.setLocale('fr-FR').monthLong}
                            </Box>
                        </BorderedBox>
                    ))}
                    {demontage && (
                        <BorderedBox
                            css={{
                                fontSize: '10px',
                                fontWeight: 700,
                                lineHeight: 'normal',
                                minHeight: '42px',
                                textTransform: 'uppercase'
                            }}
                        >
                            Démontage
                        </BorderedBox>
                    )}
                </Flex>
            </Flex>
            <BadgeRes2024Parking event={event} />
        </>
    );
};
