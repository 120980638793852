import { injectable } from 'inversify';
import * as yup from 'yup';
import { WeezeventConnectionInfoInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IWeezeventConnectionValues {
    weezevent: WeezeventConnectionInfoInput;
}

@injectable()
export class WeezeventInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    connectionInfosDefault(): WeezeventConnectionInfoInput {
        return {
            apiKey: '',
            email: '',
            password: '',
            idEvent: '',
            idTicket: ''
        };
    }

    connectionStep1Schema() {
        return yup.object().shape({
            weezevent: yup.object().shape({
                ...this.connectionStep1Fields()
            })
        });
    }

    connectionStep2Schema() {
        return yup.object().shape({
            weezevent: yup.object().shape({
                ...this.connectionStep2Fields()
            })
        });
    }

    connectionStep3Schema() {
        return yup.object().shape({
            weezevent: yup.object().shape({
                ...this.connectionStep3Fields()
            })
        });
    }

    connectionInfosSchema() {
        return yup.object().shape({
            ...this.connectionStep1Fields(),
            ...this.connectionStep2Fields(),
            ...this.connectionStep3Fields()
        });
    }

    private connectionStep1Fields() {
        return {
            apiKey: yup.string().required(this.t('la_cl_d_api_es_67678')),
            email: yup.string().required(this.t('l_identifiant_e_85021')),
            password: yup.string().required(this.t('le_mot_de_passe_14773'))
        };
    }

    private connectionStep2Fields() {
        return {
            idEvent: yup.string().required(this.t('vous_devez_s_le_61092'))
        };
    }

    private connectionStep3Fields() {
        return {
            idTicket: yup.string().required(this.t('vous_devez_s_le_26033'))
        };
    }
}
