import { injectable } from 'inversify';
import * as yup from 'yup';
import { AccreditationState, VolunteerRegistrationState } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

@injectable()
export class MassStateUpdateInputService extends InputService {
    constructor(protected translationService: TranslationService) {
        super(translationService);
    }

    massStateUpdateSchema() {
        return yup.object().shape({
            state: yup
                .string()
                .required(this.t('le_statut_est_r_13164'))
                .oneOf(
                    [
                        VolunteerRegistrationState.NotApplicable,
                        VolunteerRegistrationState.Refused,
                        VolunteerRegistrationState.WaitingAssignment
                    ],
                    this.t('le_statut_est_r_13164')
                ),
            usersInfosIds: yup
                .array()
                .of(yup.number())
                .required(this.t('vous_devez_s_le_83608'))
                .min(1, this.t('vous_devez_s_le_83608'))
        });
    }

    massAccreditationStateUpdateSchema() {
        return yup.object().shape({
            state: yup
                .string()
                .required(this.t('le_statut_est_r_13164'))
                .oneOf(
                    [AccreditationState.NotApplicable, AccreditationState.WaitingAccreditation],
                    this.t('le_statut_est_r_13164')
                ),
            usersInfosIds: yup
                .array()
                .of(yup.number())
                .required(this.t('vous_devez_s_le_83608'))
                .min(1, this.t('vous_devez_s_le_83608'))
        });
    }
}
