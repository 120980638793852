import { injectable } from 'inversify';
import * as yup from 'yup';
import { TranslationService } from '../services/translationService';
import { AuthInputService } from './authInput';

export interface ISignUpValues {
    email: string;
    code: string;
    password: string;
}

@injectable()
export class SignUpInputService extends AuthInputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    step1Schema() {
        return yup.object().shape({
            email: this.email(),
            password: this.passwordRules()
        });
    }

    step2Schema() {
        return yup.object().shape({
            email: this.email(),
            code: this.code(),
            password: this.passwordRules()
        });
    }
}
