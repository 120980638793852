import * as React from 'react';
import { useDropdownContext } from './dropdown';

interface ITriggerProps {
    children: React.ReactNode;
}

export const Trigger = (props: ITriggerProps) => {
    const { isOpen, setIsOpen, setReferenceElement } = useDropdownContext();
    const children = props.children as any;
    const handler = (e: React.MouseEvent | React.KeyboardEvent) => {
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();

        setIsOpen(!isOpen);

        children.props.onClick?.(e);
    };
    const child = React.cloneElement(React.Children.only(children), {
        ref(ref: HTMLDivElement) {
            setReferenceElement(ref);
        },
        onClick(e) {
            handler(e);
        },
        onKeyPress(e) {
            handler(e);
        },
        tabIndex: 0
    });

    return <>{child}</>;
};
