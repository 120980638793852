import { IStorage } from 'common/src/hooks/types';

export function useLocalStorageImpl(): IStorage {
    try {
        return {
            clear: window.localStorage.clear.bind(window.localStorage),
            setItem: window.localStorage.setItem.bind(window.localStorage),
            getItem: window.localStorage.getItem.bind(window.localStorage),
            removeItem: window.localStorage.removeItem.bind(window.localStorage),
            key: window.localStorage.key.bind(window.localStorage),
            get length() {
                return window.localStorage.length;
            },
            getItemInt(key: string) {
                const i = parseInt(window.window.localStorage.getItem(key)!, 10);

                if (isNaN(i)) {
                    return null;
                } else {
                    return i;
                }
            }
        };
    } catch {
        return {
            clear() {
                // do nothing
            },
            setItem() {
                // do nothing
            },
            getItem(): string | null {
                return null;
            },
            removeItem() {
                // do nothing
            },
            key(): string | null {
                return null;
            },
            get length() {
                return 0;
            },
            getItemInt(): number | null {
                return null;
            }
        };
    }
}
