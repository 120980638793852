import { useDesktopQuery } from 'common-front/src/hooks/useDesktopQuery';
import { useTabletQuery } from 'common-front/src/hooks/useTabletQuery';
import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

interface IAsoAuthContainerProps {
    children: React.ReactNode;
}

export const AsoAuthContainer = (props: IAsoAuthContainerProps) => {
    const isDesktop = useDesktopQuery();
    const isTablet = useTabletQuery();
    const gridtemplatecolumns = isDesktop ? '720px 1fr' : '1fr';

    return (
        <Grid gridtemplatecolumns={gridtemplatecolumns} gridtemplaterows="1fr" height={1} width={1}>
            {isDesktop && (
                <Box
                    css={{
                        background: `url(${Images.Aso.HomeBackground}) no-repeat`,
                        backgroundSize: 'cover'
                    }}
                />
            )}

            <Flex direction="column" align="center" css={{ overflow: 'hidden' }}>
                <Spacer height="12" />

                <Box
                    height={isDesktop ? 160 : isTablet ? 133 : 96}
                    width={isDesktop ? 383 : isTablet ? 272 : 231}
                >
                    <img src={Images.Aso.Logo} height="100%" width="100%" />
                </Box>

                <Spacer height={{ '@initial': '10', '@tablet': '13' }} />

                {props.children}
            </Flex>
        </Grid>
    );
};
