import { injectable } from 'inversify';
import { camelCase } from 'lodash-es';
import { parse } from 'papaparse';
import * as yup from 'yup';
import { DocumentInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { isNonEmptyString } from '../util/string';
import { CsvInputService } from './csvInput';
import { DocumentInputService } from './documentInput';
import { InputService } from './inputService';

export type DelegationsImportInput = {
    csv: DocumentInput;
};

export const DELEGATION_IMPORT_COLUMNS_DEFAULT = {
    Name: 'name',
    LeadersEmails: 'leadersEmails',
    LeadersFirstNames: 'leadersFirstnames',
    LeadersLastNames: 'leadersLastnames',
    FormId: 'formId',
    Deadline: 'deadline',
    MaxResources: 'maxResources',
    PrivateNote: 'privateNote',
    Tags: 'tags'
};

export const DELEGATION_IMPORT_COLUMNS_DEFAULT_VALUES = Object.values(
    DELEGATION_IMPORT_COLUMNS_DEFAULT
);

const hasEmptyColumnHeaders = (data: string[][]) =>
    data[0].filter((column) => !isNonEmptyString(column)).length > 0;

const hasMissingDefaultColumnHeaders = (data: string[][]) =>
    !DELEGATION_IMPORT_COLUMNS_DEFAULT_VALUES.reduce(
        (acc, column) => acc && data[0].map(camelCase).includes(column),
        true
    );

const hasUnexpectedColumnHeaders = (data: string[][]) =>
    data[0].filter((column) => !DELEGATION_IMPORT_COLUMNS_DEFAULT_VALUES.includes(column)).length >
    0;

@injectable()
export class DelegationsImportInputService extends InputService {
    constructor(
        private documentInputService: DocumentInputService,
        private csvInputService: CsvInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    default(): { input: DelegationsImportInput } {
        return {
            input: {
                csv: this.documentInputService.documentInputDefault()
            }
        };
    }

    schema() {
        return yup.object().shape({
            input: yup.object().shape({
                csv: yup.object().shape({
                    acl: yup.reach(this.csvInputService.schema(), 'acl'),
                    key: yup.reach(this.csvInputService.schema(), 'key'),
                    name: yup.reach(this.csvInputService.schema(), 'name'),
                    file: (
                        yup.reach(this.csvInputService.schema(), 'file') as yup.MixedSchema
                    ).test({
                        name: 'header-is-complete-for-delegations',
                        test: (file, { createError, path }) => {
                            if (!file) {
                                return true;
                            } else {
                                return new Promise((resolve) => {
                                    parse<string[]>(file as File, {
                                        preview: 1,
                                        complete: ({ data, errors }) => {
                                            if (errors.length || hasEmptyColumnHeaders(data)) {
                                                resolve(
                                                    createError({
                                                        message: this.t('le_fichier_csv_14924'),
                                                        path
                                                    })
                                                );
                                            } else if (hasMissingDefaultColumnHeaders(data)) {
                                                resolve(
                                                    createError({
                                                        message: this.t(
                                                            'l_en_t_te_du_fi_79224',
                                                            this.translationService.formatListValues(
                                                                DELEGATION_IMPORT_COLUMNS_DEFAULT_VALUES
                                                            )
                                                        ),
                                                        path
                                                    })
                                                );
                                            } else if (hasUnexpectedColumnHeaders(data)) {
                                                resolve(
                                                    createError({
                                                        message: this.t(
                                                            'the_csv_file_he_91455',
                                                            this.translationService.formatListValues(
                                                                data[0].filter(
                                                                    (columnName) =>
                                                                        !DELEGATION_IMPORT_COLUMNS_DEFAULT_VALUES.includes(
                                                                            columnName
                                                                        )
                                                                )
                                                            )
                                                        ),
                                                        path
                                                    })
                                                );
                                            } else {
                                                resolve(true);
                                            }
                                        }
                                    });
                                });
                            }
                        }
                    })
                })
            })
        });
    }
}
