import { Map } from 'common-front/src/components/map/map';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { MemberAssignmentFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { extractCoordinates } from 'common/src/util/map';
import { isNonEmptyString } from 'common/src/util/string';
import { hasAddress, hasAddressOrCoordinates, hasCoordinates } from 'common/src/vo/position';
import * as React from 'react';
import { MemberRegistrationAssignmentInfo } from './memberRegistrationAssignmentInfo';

interface IMemberRegistrationAssignmentProps {
    isSelected: boolean;
    positionSlotUserInfo: MemberAssignmentFragment;

    onClick: any;
}

export const MemberRegistrationAssignment = ({
    isSelected,
    positionSlotUserInfo,
    onClick
}: IMemberRegistrationAssignmentProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const leaders = React.useMemo(
        () =>
            positionSlotUserInfo.position.category.leaders.concat(
                positionSlotUserInfo.position.leaders
            ),
        [positionSlotUserInfo]
    );
    const position = positionSlotUserInfo.position;
    const positionSlot = positionSlotUserInfo.positionSlot;
    const isMobile = useMobileQuery();

    return (
        <Flex
            direction="column"
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs'
            }}
        >
            <Flex
                css={{
                    borderBottom: '1px solid $gray200',
                    padding: '$4'
                }}
                justify={'between'}
            >
                <Box font="gray900 textLg medium" css={{ lineHeight: '2em' }}>
                    {position.name}
                </Box>

                {isMobile && (
                    <Button
                        color="white"
                        leftIcon={isSelected ? 'chevron-up' : 'chevron-down'}
                        onClick={onClick}
                    />
                )}
            </Flex>

            {(!isMobile || isSelected) && (
                <Flex
                    direction="column"
                    gap={{ '@initial': '6', '@desktop': '7' }}
                    css={{ padding: '$4 $6' }}
                >
                    <MemberRegistrationAssignmentInfo title={translate('date_et_heure_d_79687')}>
                        {dateTimeService.toLocaleString(
                            positionSlot.range.start!,
                            LocaleFormats.DateTime
                        )}
                    </MemberRegistrationAssignmentInfo>

                    <MemberRegistrationAssignmentInfo title={translate('date_et_heure_d_10450')}>
                        {dateTimeService.toLocaleString(
                            positionSlot.range.end!,
                            LocaleFormats.DateTime
                        )}
                    </MemberRegistrationAssignmentInfo>

                    {leaders.map(({ userInfo }, index) => (
                        <MemberRegistrationAssignmentInfo
                            key={index}
                            title={translate('reponsable_82458')}
                        >
                            {isNonEmptyString(userInfo.name) && (
                                <>
                                    {userInfo.name}
                                    <br />
                                </>
                            )}

                            {organizationId !== 1504 && (
                                <>
                                    {userInfo.email}

                                    {userInfo.phone && (
                                        <>
                                            <br />
                                            {userInfo.phone.internationalFormat}
                                        </>
                                    )}
                                </>
                            )}
                        </MemberRegistrationAssignmentInfo>
                    ))}

                    {isNonEmptyString(position.description) && (
                        <MemberRegistrationAssignmentInfo title={translate('description_58935')}>
                            <RichText text={position.description} />
                        </MemberRegistrationAssignmentInfo>
                    )}

                    {hasAddressOrCoordinates(position) && (
                        <MemberRegistrationAssignmentInfo title={translate('localisation_38416')}>
                            <Flex direction="column" gap="2">
                                {hasCoordinates(position) && (
                                    <Box
                                        height={180}
                                        width={1}
                                        css={{ borderRadius: '$1', overflow: 'hidden' }}
                                    >
                                        <Map
                                            point={extractCoordinates(position)}
                                            layer={position.layer}
                                            initialZoom={position.zoom}
                                            traces={position.traces}
                                        />
                                    </Box>
                                )}

                                {hasAddress(position) && <Box>{position.address}</Box>}
                            </Flex>
                        </MemberRegistrationAssignmentInfo>
                    )}
                </Flex>
            )}
        </Flex>
    );
};
