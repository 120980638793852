import * as React from 'react';
import { Box } from '../designSystem/components/box';
import { Flex } from '../designSystem/components/flex';
import { styled } from '../designSystem/components/stitches';
import { Avatar, AvatarSize } from './avatar/avatar';

const _Flex = styled(Flex, {
    gap: '$3',
    variants: {
        avatarOnTop: {
            true: {
                flexDirection: 'column',
                gap: '$2'
            },
            false: {
                alignItems: 'center',
                flexDirection: 'row'
            }
        }
    }
});

interface IAvatarNameEmailProps {
    disabled?: boolean;
    userInfo: { email: string; picture?: any; name: string };
    hasName: boolean;
    badge?: React.ReactNode;
    size?: AvatarSize;
}

export const AvatarNameEmail = (props: IAvatarNameEmailProps) => (
    <_Flex avatarOnTop={{ '@initial': !!props.badge, '@tablet': false }} width={1}>
        <Avatar
            email={props.userInfo.email}
            image={props.userInfo.picture?.url}
            name={props.hasName ? props.userInfo.name : null}
            size={props.size || 40}
        />

        <Flex css={{ flex: '1', overflow: 'hidden' }} direction="column" justify="center">
            <Flex align="center" gap="2">
                <Box
                    color={props.disabled ? 'gray300' : 'gray800'}
                    css={{ ellipsis: '' }}
                    fontWeight="medium"
                    title={props.hasName ? props.userInfo.name : props.userInfo.email}
                >
                    {props.hasName ? props.userInfo.name : props.userInfo.email}
                </Box>

                <Box css={{ flexShrink: 0 }}>{props.badge}</Box>
            </Flex>

            {props.hasName && (
                <Box
                    color={props.disabled ? 'gray300' : 'gray500'}
                    css={{ ellipsis: '' }}
                    fontSize="textSm"
                    title={props.userInfo.email}
                >
                    {props.userInfo.email}
                </Box>
            )}
        </Flex>
    </_Flex>
);
