import { Box } from 'common/src/designSystem/components/box';
import { Flex, SectioningElement } from 'common/src/designSystem/components/flex';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

interface ITabListProps {
    center?: boolean;
    children: React.ReactNode;
    css?: CSS;
}

export const TabList = (props: ITabListProps) => (
    <Flex
        aria-orientation="horizontal"
        as={SectioningElement.Nav}
        css={{
            borderBottom: '1px solid $gray200',
            ...(props.css as any)
        }}
        role="tablist"
        width={1}
    >
        {props.center && (
            <>
                <Box
                    css={{
                        flex: '1'
                    }}
                />
            </>
        )}

        {props.children}

        <Box
            css={{
                flex: '1'
            }}
        />
    </Flex>
);
