import * as React from 'react';

const IS_MOBILE_MEDIA_QUERY = window.matchMedia('(max-width: 1200px)');

interface IMediaQueryContext {
    isMobile: boolean;
}

const MediaQueryContext = React.createContext<IMediaQueryContext>({
    isMobile: false
});

export const MediaQueryProvider = (props: { children: React.ReactNode }) => {
    const [isMobile, setIsMobile] = React.useState(false);

    React.useEffect(() => {
        setIsMobile(IS_MOBILE_MEDIA_QUERY.matches);

        IS_MOBILE_MEDIA_QUERY.addListener((e) => {
            setIsMobile(e.matches);
        });
    }, []);

    return (
        <MediaQueryContext.Provider value={{ isMobile }}>
            {props.children}
        </MediaQueryContext.Provider>
    );
};
