import { injectable } from 'inversify';
import { TranslationService } from '../services/translationService';
import { WithTranslationService } from '../services/withTranslationService';

@injectable()
export abstract class InputService extends WithTranslationService {
    constructor(protected translationService: TranslationService) {
        super(translationService);
    }

    oneOfMessage(fieldName: string, fieldValues: string[]) {
        return this.t(
            '_1_doit_tre_70659',
            fieldName,
            this.translationService.formatListValues(fieldValues.map((v) => this.t(v)))
        );
    }
}
