import { injectable } from 'inversify';
import * as yup from 'yup';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface ISignInValues {
    email: string;
}

export interface ISignInPasswordValues {
    email: string;
    password: string;
}

export interface ICodeValues {
    code: string;
}

export interface IFormEmailValues {
    email: string;
    emailConfirm: string;
    code?: string;
}

export function has16Chars(password?: string) {
    return typeof password === 'string' && password.length >= 16;
}

export function hasNumber(password?: string) {
    return typeof password === 'string' && password.match(/\d/) !== null;
}

export function hasUpper(password?: string) {
    return (
        typeof password === 'string' &&
        password.match(/[A-Z]/) !== null &&
        password.match(/[a-z]/) !== null
    );
}

export function hasSpecial(password?: string) {
    return typeof password === 'string' && password.match(/[^A-Za-z0-9]/) !== null;
}

@injectable()
export class AuthInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    signInSchema() {
        return yup.object().shape({
            email: this.email()
        });
    }

    signInPasswordSchema() {
        return yup.object().shape({
            email: this.email(),
            password: this.password()
        });
    }

    codeSchema() {
        return yup.object().shape({
            code: this.code()
        });
    }

    emailSchema() {
        return yup.object().shape({
            email: this.email()
        });
    }

    emailConfirmSchema() {
        return yup.object().shape({
            email: this.email(),
            emailConfirm: this.emailConfirm()
        });
    }

    emailCodeSchema() {
        return yup.object().shape({
            email: this.email(),
            code: this.code()
        });
    }

    protected email() {
        return yup
            .string()
            .required(this.t('l_e_mail_est_re_04856'))
            .email(this.t('l_e_mail_est_re_04856'));
    }

    protected emailConfirm() {
        return yup
            .string()
            .required(this.t('la_confirmation_53449'))
            .email(this.t('la_confirmation_53449'))
            .test('same-as-email', this.t('l_e_mail_et_la_57164'), function () {
                return this.parent.email === this.parent.emailConfirm;
            });
    }

    protected code() {
        return yup
            .string()
            .required(this.t('le_code_est_req_77618'))
            .length(6, this.t('le_code_doit_co_37160'));
    }

    protected password() {
        return yup.string().required(this.t('le_mot_de_passe_14773'));
    }

    protected passwordRules() {
        return yup
            .string()
            .required(this.t('le_mot_de_passe_24842'))
            .test('valid-password', this.t('le_mot_de_passe_24842'), function () {
                return (
                    has16Chars(this.parent.password) &&
                    hasNumber(this.parent.password) &&
                    hasUpper(this.parent.password) &&
                    hasSpecial(this.parent.password)
                );
            });
    }
}
