export function toArray<T>(elem: T | T[]): T[] {
    if (Array.isArray(elem)) {
        return elem;
    } else {
        return [elem];
    }
}

export function isNonEmptyArray<T>(array: T[] | null | undefined): array is T[] {
    return Array.isArray(array) && array.length > 0;
}

export function isEmptyArray<T>(array: T[] | null | undefined): boolean {
    return !isNonEmptyArray(array);
}

export function addOrRemove<T>(values: T[], value: T, shouldAdd: boolean): T[] {
    const set = new Set<T>(values);

    if (shouldAdd) {
        set.add(value);
    } else {
        set.delete(value);
    }

    return Array.from(set);
}

export function toggle<T>(values: T[], value: T): T[] {
    const set = new Set<T>(values);

    if (set.has(value)) {
        set.delete(value);
    } else {
        set.add(value);
    }

    return Array.from(set);
}

export function reorder<T>(array: T[], startIndex: number, endIndex: number): T[] {
    const result = [...array];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export function removeAtIndex<T>(array: T[], index: number): T[] {
    return array.filter((_, i) => i !== index);
}

export function intercalate<T>(array: T[], separator: T): T[] {
    return array.flatMap((v, index) => {
        if (index !== 0) {
            return [separator, v];
        } else {
            return [v];
        }
    });
}
