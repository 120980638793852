import { uniq } from 'lodash-es';
import { Fragments, IFragment } from '../generated/fragments';

function _allFragments(fragments: IFragment[]): string[] {
    return fragments.flatMap((fragment) =>
        [fragment.code].concat(
            _allFragments(fragment.fragments.map((nestedFragment) => Fragments[nestedFragment]))
        )
    );
}

export function allFragments(fragments: IFragment[]): string {
    return uniq(_allFragments(fragments)).join(' ');
}
