import { injectable } from 'inversify';
import * as yup from 'yup';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

@injectable()
export class AccreditationsCustomFieldInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    accreditationsCustomFieldInputSchema() {
        return yup.object().shape({
            customFieldId: yup.number().required(this.t('le_champ_person_80074')),
            conditionValue: yup.mixed().required(this.t('la_condition_es_66568'))
        });
    }
}
