import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CSS, styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { OrganizationLogoSkeleton } from '../organizationLogoSkeleton';

export const AuthContainer = styled('div', {
    alignItems: 'center',
    background: '$gray50',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    padding: '$4',
    paddingTop: '$10',
    width: '100%',
    '@tablet': {
        padding: '$10'
    }
});

export const authInnerCss: CSS = {
    bd: true,
    background: 'white',
    padding: '$7 $4',
    width: '100%',
    '@tablet': {
        padding: '$7'
    },
    '@desktop': {
        width: '$modalMd'
    }
};

export const AuthSkeleton = () => (
    <AuthContainer>
        <OrganizationLogoSkeleton />

        <Flex direction="column" css={authInnerCss}>
            <Skeleton height={28} width={1} borderRadius="$1" />

            <Spacer height="1" />

            <Skeleton height={42} width={1} borderRadius="$1" />

            <Spacer height="7" />

            <Skeleton height={38} width={1} borderRadius="$1" />

            <Spacer height="6" />

            <Skeleton height={40} width={1} borderRadius="$1" />
        </Flex>
    </AuthContainer>
);
