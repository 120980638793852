import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { AccreditationsCategory } from 'common/src/generated/types';
import * as React from 'react';

interface IAccreditationCategoryProps<T> {
    category: Pick<AccreditationsCategory, 'id' | 'name'> & T;
    children: React.ReactNode;
}

export const AccreditationCategory = <T extends {}>(props: IAccreditationCategoryProps<T>) => (
    <Flex key={props.category.id} direction="column" gap="2">
        <Box font="gray800 textMd medium">{props.category.name}</Box>

        <Flex direction="column" gap="3">
            {props.children}
        </Flex>
    </Flex>
);
