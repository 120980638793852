import * as React from 'react';

export function useBodyClick(fn: () => void, deps?: React.DependencyList) {
    const bodyClick = React.useCallback(fn, deps || []);

    React.useEffect(() => {
        document.addEventListener('click', bodyClick, false);

        return () => {
            document.removeEventListener('click', bodyClick, false);
        };
    }, deps || []);
}
