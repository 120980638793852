import { injectable } from 'inversify';
import { TranslationService } from './translationService';

@injectable()
export abstract class WithTranslationService {
    constructor(protected translationService: TranslationService) {}

    protected t(key: string, ...args: any[]): string {
        return this.translationService.translate(key, ...args);
    }
}
