import { Button } from 'common-front/src/designSystem/components/button';
import { useDesktopQuery } from 'common-front/src/hooks/useDesktopQuery';
import { copy } from 'common-front/src/util/commands';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    FormId,
    FormInfosQuery,
    FormRegisterResult,
    OrganizationId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { getFormLink } from 'common/src/vo/form';
import { produce } from 'immer';
import * as React from 'react';
import { useEmailFormInviteSendMutation } from '../../../../generated/graphqlHooks';
import { FormSuccessInvitationEmail } from './formSuccessInvitationEmail';

interface IFormSuccessInvitationsProps {
    eventId: Emptyable<EventId>;
    form: FormInfosQuery['organization']['form'];
    formId: FormId;
    formRegisterResult: Emptyable<FormRegisterResult>;
    organizationId: OrganizationId;
}

export const FormSuccessInvitations = (props: IFormSuccessInvitationsProps) => {
    const translate = useTranslate();
    const { mutate: emailFormInviteSend, isLoading: isEmailFormInviteSendLoading } =
        useEmailFormInviteSendMutation();
    const [isLoading, setIsLoading] = React.useState(false);
    const link = getFormLink({
        organizationId: props.organizationId,
        eventId: props.eventId,
        formId: props.formId,
        teamCode: props.formRegisterResult?.teamCode
    });
    const [emails, setEmails] = React.useState(['']);
    const isAddHidden = props.form.maxTeamMembers && emails.length >= props.form.maxTeamMembers - 1;
    const isDesktop = useDesktopQuery();
    const onFormInvite = React.useCallback(async () => {
        await emailFormInviteSend({
            organizationId: props.organizationId,
            eventId: props.eventId!,
            formId: props.formId,
            teamCode: props.formRegisterResult?.teamCode ?? '',
            emails
        });
    }, [emails, emailFormInviteSend, props.formRegisterResult]);

    return (
        <Flex
            direction={{ '@initial': 'column', '@desktop': 'row' }}
            gap="3"
            css={{
                background: '$gray50',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                padding: '$6'
            }}
        >
            <Box color="gray800" fontSize="textXl">
                <I icon="circle-exclamation" />
            </Box>

            <Flex direction="column" width={1}>
                <Box font="gray800 textMd semiBold">{translate('vous_souhaitez_73288')}</Box>

                <Spacer height="1" />

                <Box color="gray800">{translate('inviter_vos_qu_82288')}</Box>

                <Spacer height="5" />

                <Box font="gray800 textSm semiBold">{translate('lien_du_formula_51477')}</Box>

                <Spacer height="2" />

                <Flex direction={{ '@initial': 'column', '@desktop': 'row' }} gap="2" width={1}>
                    <Flex
                        align="center"
                        height={40}
                        css={{
                            background: 'white',
                            border: '1px solid $gray300',
                            borderRadius: '$1',
                            color: '$gray800',
                            flex: isDesktop ? '1' : undefined,
                            px: '$3'
                        }}
                    >
                        <Box width={1} css={{ ellipsis: true }}>
                            {link}
                        </Box>
                    </Flex>

                    <Flex>
                        <Button
                            leftIcon="copy"
                            isLoading={isLoading}
                            onClick={() => {
                                setIsLoading(true);
                                copy(link);

                                setTimeout(() => {
                                    setIsLoading(false);
                                }, 500);
                            }}
                        >
                            {translate('copier_le_lien_54974')}
                        </Button>
                    </Flex>
                </Flex>

                <Spacer height="6" />

                <Box font="gray800 textSm semiBold">{translate('invitations_32350')}</Box>

                <Spacer height="2" />

                <Flex
                    direction="column"
                    gap="4"
                    css={{
                        background: 'white',
                        border: '1px solid $gray200',
                        borderRadius: '$2',
                        boxShadow: '$xs',
                        padding: '$6'
                    }}
                >
                    {emails.map((email, index) => (
                        <FormSuccessInvitationEmail
                            key={index}
                            email={email}
                            index={index}
                            onChange={(newEmail) => {
                                setEmails(
                                    produce((currentEmails) => {
                                        currentEmails[index] = newEmail;
                                    })
                                );
                            }}
                        />
                    ))}

                    {!isAddHidden && (
                        <Box
                            color="primary700"
                            css={{ cursor: 'pointer' }}
                            onClick={() => {
                                setEmails(emails.concat(['']));
                            }}
                        >
                            + {translate('ajouter_une_inv_21322')}
                        </Box>
                    )}
                </Flex>

                <Spacer height="3" />

                <Flex>
                    <Button isLoading={isEmailFormInviteSendLoading} onClick={onFormInvite}>
                        {translate('envoyer_les_inv_50560')}
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};
