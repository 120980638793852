import { injectable } from 'inversify';
import * as yup from 'yup';
import { CustomFieldId, FilesImportInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { ArchiveInputService } from './archiveInput';
import { DocumentInputService } from './documentInput';
import { InputService } from './inputService';

@injectable()
export class FilesImportInputService extends InputService {
    constructor(
        private documentInputService: DocumentInputService,
        private archiveInputService: ArchiveInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    default(): { input: FilesImportInput } {
        return {
            input: {
                archive: this.documentInputService.documentInputDefault(),
                fieldId: -1 as CustomFieldId
            }
        };
    }

    schema() {
        return yup.object().shape({
            input: yup.object().shape({
                archive: this.archiveInputService.schema(),
                fieldId: yup
                    .string()
                    .required(this.t('le_champ_person_80074'))
                    .typeError(this.t('le_champ_person_80074'))
            })
        });
    }
}
