import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import { LocaleFormatType } from '../util/luxon';
import { TranslationService } from './translationService';

@injectable()
export class DateTimeService {
    constructor(private translationService: TranslationService) {}

    now(): DateTime {
        return DateTime.utc();
    }

    invalid() {
        return DateTime.invalid('Invalid');
    }

    toLocaleString(dateTime: DateTime, format: LocaleFormatType) {
        return dateTime.toLocaleString(format, {
            locale: this.translationService.language.toString().toLowerCase()
        });
    }
}
