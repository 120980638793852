import * as React from 'react';

export function useEsc(fn: () => void, deps?: React.DependencyList) {
    const escKeydown = React.useCallback((e: KeyboardEvent) => {
        if (e.key === 'Esc' || e.key === 'Escape') {
            fn();
        }
    }, deps || []);

    React.useEffect(() => {
        document.addEventListener('keydown', escKeydown, false);

        return () => {
            document.removeEventListener('keydown', escKeydown, false);
        };
    }, deps || []);
}
