import { injectable } from 'inversify';
import * as yup from 'yup';
import { PhoneInput } from '../generated/types';
import { CountriesService, IDefaultCountryValues } from '../services/countriesService';
import { TranslationService } from '../services/translationService';

export const PHONE_INPUT_KEYS = ['country', 'code', 'number'];

@injectable()
export class PhoneInputService {
    constructor(
        private countriesService: CountriesService,
        private translationService: TranslationService
    ) {}

    basePhoneInputDefault(): PhoneInput {
        return { country: '', code: '', number: '' };
    }

    phoneInputDefault(countryValues: IDefaultCountryValues): PhoneInput {
        return {
            ...this.basePhoneInputDefault(),
            ...countryValues.phone
        };
    }

    phoneInputSchema(): yup.Schema<PhoneInput> {
        return yup.object().shape({
            country: yup
                .string()
                .required(this.translationService.translate('le_num_ro_de_t_76885'))
                .oneOf(
                    this.countriesService.countriesCodes,
                    this.translationService.translate('le_num_ro_de_t_76885')
                ),
            code: yup
                .string()
                .required(this.translationService.translate('le_num_ro_de_t_76885'))
                .oneOf(
                    this.countriesService.phoneCodes,
                    this.translationService.translate('le_num_ro_de_t_76885')
                ),
            number: yup.string().required(this.translationService.translate('le_num_ro_de_t_76885'))
        });
    }

    phoneInputSchemaNotRequired() {
        return yup.object().nullable().shape({
            country: yup.string(),
            code: yup.string(),
            number: yup.string()
        });
    }
}
