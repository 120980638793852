import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { toArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Button } from './button';
import { Modal } from './modal';

interface IDeleteModalProps {
    text: string;
    subtext: string | string[];
    buttonText: string;

    onDelete(): Promise<any>;
    onClose(): void;
}

export const DeleteModal = (props: IDeleteModalProps) => {
    const translate = useTranslate();
    const [isLoading, setIsLoading] = React.useState(false);
    const onDelete = React.useCallback(async () => {
        setIsLoading(true);
        await props.onDelete();
        setIsLoading(false);
    }, [props.onDelete, isLoading, setIsLoading]);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Flex justify="center">
                <Flex
                    align="center"
                    css={{
                        background: '$error100',
                        borderRadius: '48px',
                        color: '$error600',
                        height: '48px',
                        fontSize: '20px',
                        width: '48px'
                    }}
                    justify="center"
                >
                    <I icon="circle-exclamation" iconStyle="light" />
                </Flex>
            </Flex>

            <Spacer height="5" />

            <Box color="gray900" fontSize="textLg" textAlign="center">
                {props.text}
            </Box>

            {toArray(props.subtext).map((subtext, index) => (
                <React.Fragment key={index}>
                    <Spacer height="2" />

                    <Box color="gray500" textAlign="center">
                        {subtext}
                    </Box>
                </React.Fragment>
            ))}

            <Spacer height="7" />

            <Flex gap="3" justify="end">
                <Button color="error" isLoading={isLoading} onClick={onDelete}>
                    {props.buttonText}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
