import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { AsoAuthContainer } from './asoAuthContainer';

export const AsoAuthSkeleton = () => (
    <AsoAuthContainer>
        <Flex direction="column" width="$modalMd">
            <Skeleton height={28} width={1} borderRadius="$1" />

            <Spacer height="1" />

            <Skeleton height={42} width={1} borderRadius="$1" />

            <Spacer height="7" />

            <Skeleton height={38} width={1} borderRadius="$1" />

            <Spacer height="6" />

            <Skeleton height={40} width={1} borderRadius="$1" />
        </Flex>
    </AsoAuthContainer>
);
