import { injectable } from 'inversify';
import { EventState, FilterType, OrganizationId } from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { Filter, SegmentService } from '../segment';

@injectable()
export class EventsSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getEventsFilters(organizationId: OrganizationId): Filter[] {
        return [
            {
                color: 'warning',
                slug: 'name',
                name: this.t('nom_de_l_v_nem_08711'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'startAt',
                name: this.t('date_de_d_but_43380'),
                fieldType: FilterType.Date,
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'endAt',
                name: this.t('date_de_fin_86920'),

                fieldType: FilterType.Date,
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'state',
                name: this.t('status_06428'),
                fieldType: FilterType.Select,
                values: [
                    {
                        id: EventState.Ongoing,
                        name: this.t(EventState.Ongoing)
                    },
                    {
                        id: EventState.Done,
                        name: this.t(EventState.Done)
                    }
                ],
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'tag',
                name: this.t('Tag'),
                fieldType: FilterType.Select,
                needLoading: true,
                organizationId
            }
        ];
    }
}
