import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationsPaths } from 'common/src/delegationsPaths';
import * as React from 'react';

export const RedirectDelegations = () => {
    const {
        params: { organizationId }
    } = useHeavent();

    return <Empty path={DelegationsPaths.AUTH(organizationId)} />;
};
