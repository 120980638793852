import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormMissionsOptionsFragment,
    FormMissionsQuery,
    PositionsCategoryId
} from 'common/src/generated/types';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { FormMissionCategoriesCategory } from './formMissionCategoriesCategory';

interface IFormMissionsCategoriesProps {
    categories: FormMissionsQuery['event']['positionsCategories'];
    options: FormMissionsOptionsFragment;
    positionsCategoriesIds: PositionsCategoryId[];
    prefix: string;

    change(name: string, value: any): void;
}

export const FormMissionsCategories = (props: IFormMissionsCategoriesProps) => {
    const categories = React.useMemo(
        () => sortBy(props.categories, (c) => c.name),
        [props.categories]
    );

    return (
        <>
            {categories.map((category, index) => (
                <React.Fragment key={category.id}>
                    {index !== 0 && <Spacer height="3" />}

                    <FormMissionCategoriesCategory
                        category={category}
                        change={props.change}
                        options={props.options}
                        positionsCategoriesIds={props.positionsCategoriesIds}
                        prefix={props.prefix}
                    />
                </React.Fragment>
            ))}
        </>
    );
};
