import '0_reflect-metadata';
import { Authenticate } from 'common-front/src/authenticate';
import { Empty } from 'common-front/src/components/empty/empty';
import { ErrorBoundaryContextProvider } from 'common-front/src/components/errorBoundary/errorBoundaryContext';
import { MagicLink } from 'common-front/src/components/magicLink/magicLink';
import { MainLoader } from 'common-front/src/components/mainLoader/mainLoader';
import { MediaQueryProvider } from 'common-front/src/components/mediaQuery/mediaQueryContext';
import { UserContext } from 'common-front/src/userContext';
import { FrontDependenciesProvider } from 'common-front/src/util/dependencies/frontDependenciesProvider';
import { LogToken } from 'common-front/src/util/logToken';
import { ReferrerContextProvider } from 'common-front/src/util/referrerContext';
import { DelegationsPaths } from 'common/src/delegationsPaths';
import { Box } from 'common/src/designSystem/components/box';
import { getEmbedPath, HeaventPaths, VolunteersPaths } from 'common/src/util/heaventPaths';
import { MembersPaths } from 'common/src/util/membersPaths';
import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { DelegationForm } from './delegationForm';
import { RedirectDelegations } from './redirectDelegations';
import { RedirectMembers } from './redirectMembers';
import { Route404 } from './route404';
import { AuthRouter } from './v2/auth/authRouter';
import { Delegation } from './v2/delegations/delegation';
import { DelegationsList } from './v2/delegations/list/delegationsList';
import { Form } from './v2/forms/register/form';
import { MemberCampaignsList } from './v2/members/campaigns/memberCampaignsList';
import { MemberDocumentsList } from './v2/members/documents/memberDocumentsList';
import { Member } from './v2/members/member';
import { MemberSpace } from './v2/members/registrations/memberSpace';
import { UpdateMemberLoader } from './v2/members/update/updateMemberLoader';
import { ProfilesListRouter } from './v2/profiles/list/profilesListRouter';

const Authenticated = (props: { children: React.ReactNode; isEmbed: boolean }) => (
    <Authenticate
        shouldHaveUser={true}
        getRedirectPath={({ organizationId, eventId, userInfoId, delegationId }) => {
            if (
                typeof organizationId === 'number' &&
                typeof eventId === 'number' &&
                !userInfoId &&
                !delegationId
            ) {
                return getEmbedPath(props.isEmbed, VolunteersPaths.AUTH(organizationId, eventId));
            } else if (
                location.pathname.includes('delegation') &&
                typeof organizationId === 'number'
            ) {
                return DelegationsPaths.AUTH(organizationId);
            } else if (
                location.pathname.startsWith('/v2/organization') &&
                typeof organizationId === 'number'
            ) {
                return MembersPaths.AUTH(organizationId);
            } else {
                return HeaventPaths.HOME;
            }
        }}
    >
        {props.children}
    </Authenticate>
);

const Unauthenticated = (props: { children: React.ReactNode; isEmbed: boolean }) => (
    <Authenticate
        shouldHaveUser={false}
        getRedirectPath={({ organizationId, eventId }) => {
            if (typeof organizationId === 'number' && typeof eventId === 'number') {
                return getEmbedPath(
                    props.isEmbed,
                    VolunteersPaths.ASSIGNMENTS(organizationId, eventId)
                );
            } else if (
                location.pathname.includes('delegations') &&
                typeof organizationId === 'number'
            ) {
                return DelegationsPaths.DELEGATIONS_LIST(organizationId);
            } else if (
                location.pathname.startsWith('/v2/organization') &&
                typeof organizationId === 'number'
            ) {
                return MembersPaths.PROFILES(organizationId);
            } else {
                return HeaventPaths.HOME;
            }
        }}
    >
        {props.children}
    </Authenticate>
);

interface INormalRouterProps {
    isEmbed: boolean;
}

const NormalRouter = (props: INormalRouterProps) => (
    <Switch>
        {/* V2 */}
        <Route
            path={MembersPaths.TEAM_FORM(':organizationId', ':eventId', ':formId', ':teamCode')}
            children={<Form />}
        />

        <Route
            path={[
                `/v2${DelegationsPaths.DELEGATION({
                    organizationId: ':organizationId',
                    eventId: ':eventId',
                    delegationId: ':delegationId'
                })}/form/:formId`,
                `${DelegationsPaths.DELEGATION({
                    organizationId: ':organizationId',
                    eventId: ':eventId',
                    delegationId: ':delegationId'
                })}/form/:formId`
            ]}
            children={<DelegationForm />}
        />

        <Route
            path={[
                MembersPaths.DELEGATION_FORM(':organizationId', null, ':delegationId', ':formId'),
                MembersPaths.DELEGATION_FORM(
                    ':organizationId',
                    ':eventId',
                    ':delegationId',
                    ':formId'
                )
            ]}
            children={<Form />}
        />

        <Route
            path={MembersPaths.EVENT_FORM(':organizationId', ':eventId', ':formId')}
            children={<Form />}
        />

        <Route
            path={MembersPaths.ORGANIZATION_FORM(':organizationId', ':formId')}
            children={<Form />}
        />

        <Route
            path={DelegationsPaths.AUTH(':organizationId')}
            children={
                <Unauthenticated isEmbed={false}>
                    <AuthRouter loadDelegationsSpace={true} loadMembersSpace={false} />
                </Unauthenticated>
            }
        />

        <Route
            path={DelegationsPaths.DELEGATIONS_LIST(':organizationId')}
            children={
                <Authenticated isEmbed={false}>
                    <DelegationsList />
                </Authenticated>
            }
        />

        <Route
            path={[
                DelegationsPaths.DELEGATION({
                    delegationId: ':delegationId',
                    organizationId: ':organizationId'
                }),
                DelegationsPaths.DELEGATION({
                    delegationId: ':delegationId',
                    eventId: ':eventId',
                    organizationId: ':organizationId'
                })
            ]}
            children={
                <Authenticated isEmbed={false}>
                    <Delegation />
                </Authenticated>
            }
        />

        <Route
            path={`/v2/organization/:organizationId/delegations`}
            children={<RedirectDelegations />}
        />

        <Route
            path={MembersPaths.AUTH(':organizationId')}
            children={
                <Unauthenticated isEmbed={false}>
                    <AuthRouter loadDelegationsSpace={false} loadMembersSpace={true} />
                </Unauthenticated>
            }
        />

        <Route
            path={MembersPaths.PROFILES(':organizationId')}
            children={
                <Authenticated isEmbed={false}>
                    <ProfilesListRouter />
                </Authenticated>
            }
        />

        <Route
            path={MembersPaths.MEMBER_CAMPAIGNS(':organizationId', ':userInfoId')}
            children={
                <Authenticated isEmbed={false}>
                    <MemberCampaignsList />
                </Authenticated>
            }
        />

        <Route
            path={MembersPaths.MEMBER_DOCUMENTS(':organizationId', ':userInfoId')}
            children={
                <Authenticated isEmbed={false}>
                    <MemberDocumentsList />
                </Authenticated>
            }
        />

        <Route
            path={[
                MembersPaths.MEMBER_REGISTRATION_EDIT(':organizationId', ':userInfoId', null),
                MembersPaths.MEMBER_REGISTRATION_EDIT(':organizationId', ':userInfoId', ':eventId')
            ]}
            children={<UpdateMemberLoader />}
        />

        <Route
            path={[
                MembersPaths.MEMBER_REGISTRATION({
                    organizationId: ':organizationId',
                    userInfoId: ':userInfoId'
                }),
                MembersPaths.MEMBER_REGISTRATION({
                    organizationId: ':organizationId',
                    userInfoId: ':userInfoId',
                    eventId: ':eventId'
                })
            ]}
            children={
                <Authenticated isEmbed={false}>
                    <MemberSpace />
                </Authenticated>
            }
        />

        <Route
            path={MembersPaths.MEMBER(':organizationId', ':userInfoId')}
            children={
                <Authenticated isEmbed={false}>
                    <Member />
                </Authenticated>
            }
        />

        <Route path={`/v2/organization/:organizationId`} children={<RedirectMembers />} />

        {/* V1 */}

        <Route
            path={getEmbedPath(props.isEmbed, VolunteersPaths.AUTH(':organizationId', ':eventId'))}
            children={<Empty push={({ organizationId }) => MembersPaths.AUTH(organizationId)} />}
        />

        <Route
            path={getEmbedPath(
                props.isEmbed,
                VolunteersPaths.REGISTER(':organizationId', ':eventId')
            )}
            children={<Empty push={({ organizationId }) => MembersPaths.AUTH(organizationId)} />}
        />

        <Route children={<Route404 />} />
    </Switch>
);

const Volunteers = () => (
    <Box
        css={{
            color: '$gray800',
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            '@desktop': {
                backgroundColor: '#f9fafb'
            }
        }}
    >
        <BrowserRouter>
            <Switch>
                <Route
                    path={VolunteersPaths.MAGIC_LINK(
                        ':code',
                        ':source',
                        ':organizationId',
                        ':eventId'
                    )}
                    children={<MagicLink />}
                />

                <Route path="/embed" children={<NormalRouter isEmbed={true} />} />

                <Route children={<NormalRouter isEmbed={false} />} />
            </Switch>
        </BrowserRouter>
    </Box>
);

render(
    <React.StrictMode>
        <MainLoader
            render={(userContext) => (
                <UserContext.Provider value={userContext}>
                    <FrontDependenciesProvider>
                        <MediaQueryProvider>
                            <ErrorBoundaryContextProvider>
                                <ReferrerContextProvider>
                                    <LogToken />

                                    <Volunteers />
                                </ReferrerContextProvider>
                            </ErrorBoundaryContextProvider>
                        </MediaQueryProvider>
                    </FrontDependenciesProvider>
                </UserContext.Provider>
            )}
        />
    </React.StrictMode>,
    document.getElementById('heavent-react-hook')
);
