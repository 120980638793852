import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { useOrganizationPublicContext } from './organizationPublicContext';

export const OrganizationLogo = () => {
    const { logoUrl } = useOrganizationPublicContext();

    return logoUrl ? (
        <Flex
            justify="center"
            width={1}
            css={{
                marginBottom: '$9',
                '@tablet': {
                    marginBottom: '$10'
                }
            }}
        >
            <Box
                height={120}
                width={120}
                css={{
                    background: `url(${logoUrl})`,
                    backgroundSize: 'cover'
                }}
            />
        </Flex>
    ) : null;
};
