import { OrganizationId } from 'common/src/generated/types';
import { getS3Url } from 'common/src/vo/document';
import * as React from 'react';
import { AWS_UPLOADS_BUCKET } from '../../../consts';
import { uploadFile } from '../../../util/file';
import { FileS3InputState, IFileS3InputProps } from '../file/fileS3Input';
import { FileS3InputValue } from '../file/fileS3InputValue';
import { Image } from './image';
import { ImageLoader } from './imageLoader';
import { ImageUploader } from './imageUploader';

export interface IImageInputProps
    extends Omit<IFileS3InputProps, 'format' | 'acl' | 'accept' | 'onChange'> {
    allowCrop?: boolean;
    accept?: string;
    hint?: string;
    organizationId : OrganizationId

    onChange(value: FileS3InputValue | null): void;
}

export const ImageInput = ({
    allowCrop,
    value,
    onChange: _onChange,
    label,
    hint,
    organizationId,
    ...rest
}: IImageInputProps) => {
    const [s3State, setS3State] = React.useState(
        value ? FileS3InputState.File : FileS3InputState.Uploader
    );
    const onChange = async (file: File) => {
        setS3State(FileS3InputState.Uploading);

        try {
            const doc = await uploadFile(organizationId, file, 'public-read');

            _onChange(doc);
            setS3State(FileS3InputState.File);
        } catch {
            setS3State(FileS3InputState.Uploader);
        }
    };

    return s3State === FileS3InputState.File && value ? (
        <Image
            hint={hint}
            label={label}
            url={getS3Url(value, AWS_UPLOADS_BUCKET)}
            onDelete={() => {
                _onChange(null);
                setS3State(FileS3InputState.Uploader);
            }}
        />
    ) : s3State === FileS3InputState.Uploading ? (
        <ImageLoader hint={hint} label={label} />
    ) : (
        <ImageUploader
            allowCrop={allowCrop}
            hint={hint}
            label={label}
            onChange={onChange}
            {...rest}
        />
    );
};
