import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isEqual, sortBy, without } from 'lodash-es';
import * as React from 'react';
import { Dropdown } from '../dropdown/dropdown';
import { Menu } from '../dropdown/menu';
import { Trigger } from '../dropdown/trigger';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { StyledInputContainer } from '../input/styledInputContainer';
import { ISelectProps } from '../select/select';
import { TextInput } from '../textInput';
import { RichSelectGroup } from './richSelectGroup';
import { RichSelectItem } from './richSelectItem';
import { getValueToName, parseSelectChildren } from './selectUtil';

type BaseRichSelectProps = {
    createText?: string;
    isCreateVisible?: boolean;
    isSearchVisible?: boolean;
    isSelectAllVisible?: boolean;
    renderOnPortal?: boolean;
    searchElement?: React.ReactNode;
    searchPlaceholder?: string;
    triggerElem?: React.ReactNode;

    onCreateClick?(): void;
    onClick?(value: number, isSelected: boolean): void;
};

export type IRichSelectProps<T> = {
    values: T[];

    onChange(values: T[]): void;
} & BaseRichSelectProps &
    Omit<ISelectProps, 'onChange' | 'value'>;

export const RichSelect = <T extends {}>({
    children,
    values,
    onChange,
    label,
    css,
    triggerElem,
    icon,
    state,
    renderOnPortal,
    isSearchVisible,
    searchElement,
    isCreateVisible,
    isSelectAllVisible,
    createText,
    searchPlaceholder,
    onCreateClick,
    onClick,
    placeholder,
    multiple,
    hint,
    description
}: IRichSelectProps<T>) => {
    const translate = useTranslate();
    const textInputRef = React.useCallback(
        (node: HTMLInputElement) => node?.focus({ preventScroll: true }),
        []
    );
    const [isOpen, setIsOpen] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const parsedChildren = React.useMemo(() => parseSelectChildren<T>(children), [children]);
    const allValues = React.useMemo(
        () => parsedChildren.flatMap((c) => (c.type === 'option' ? [c.value] : [])),
        [parsedChildren]
    );
    const displayedChildren = React.useMemo(() => {
        const s = (search || '').trim().toLowerCase();

        return parsedChildren
            .filter((c) => s === '' || c.type === 'group' || c.text.toLowerCase().includes(s))
            .filter((c, index, array) => {
                const nextChild = array[index + 1];

                return c.type === 'option' || nextChild?.type === 'option';
            });
    }, [parsedChildren, search]);
    const valueToName = React.useMemo(() => getValueToName(parsedChildren), [parsedChildren]);
    const isAllSelected = React.useMemo(
        () => isEqual(sortBy(values), sortBy(allValues)),
        [values, allValues]
    );
    const onSelectAllClick = React.useCallback(() => {
        if (isAllSelected) {
            onChange([]);
        } else {
            onChange(allValues);
        }
    }, [onChange, allValues, isAllSelected]);

    React.useEffect(() => {
        if (!isOpen) {
            setSearch('');
        }
    }, [isOpen]);

    return (
        <Flex css={css} direction="column" width={1}>
            <Label>{label}</Label>

            <Description>{description}</Description>

            {(label || description) && <Spacer height="1" />}

            <Dropdown isOpen={isOpen} onStateChange={(newIsOpen) => setIsOpen(newIsOpen)}>
                <Trigger>
                    {triggerElem || (
                        <StyledInputContainer
                            css={{
                                maxHeight: '200px',
                                overflowY: 'auto',
                                padding: '$2 $3',
                                userSelect: 'none'
                            }}
                            cursor="pointer"
                            height="fluid"
                            icon={icon}
                            rightIcon={isOpen ? 'chevron-up' : 'chevron-down'}
                            state={state}
                        >
                            {isNonEmptyArray(values) ? (
                                multiple ? (
                                    <Flex
                                        css={{ flex: '1', overflowY: 'auto' }}
                                        gap="2"
                                        height={1}
                                        wrap="wrap"
                                    >
                                        {values.map((value: any, index) => (
                                            <Badge
                                                key={index}
                                                ellipsis={true}
                                                rightIcon="xmark"
                                                onRightIconClick={(e) => {
                                                    e.stopPropagation();
                                                    e.nativeEvent.stopImmediatePropagation();

                                                    onChange(without(values, value));
                                                }}
                                            >
                                                {valueToName[value]}
                                            </Badge>
                                        ))}
                                    </Flex>
                                ) : (
                                    <Box color="gray800" css={{ flex: '1' }}>
                                        {valueToName[values[0] as any]}
                                    </Box>
                                )
                            ) : (
                                <Box color="gray500" css={{ flex: '1' }}>
                                    {placeholder}
                                </Box>
                            )}
                        </StyledInputContainer>
                    )}
                </Trigger>

                <Menu
                    css={{ padding: '0' }}
                    placement="bottom"
                    renderOnPortal={renderOnPortal}
                    width="match"
                >
                    <Flex direction="column" width={1}>
                        <Flex css={{ padding: '$3' }} direction="column" width={1}>
                            {isSearchVisible && (
                                <>
                                    {searchElement ? (
                                        searchElement
                                    ) : (
                                        <TextInput
                                            ref={textInputRef}
                                            icon="magnifying-glass"
                                            placeholder={
                                                searchPlaceholder || translate('rechercher_50038')
                                            }
                                            state="search"
                                            value={search}
                                            onChange={setSearch}
                                        />
                                    )}

                                    <Spacer height="3" />
                                </>
                            )}

                            <Box css={{ maxHeight: '250px', overflowY: 'auto' }}>
                                {displayedChildren.map((child, index) => {
                                    if (child.type === 'group') {
                                        return (
                                            <RichSelectGroup key={index} index={index}>
                                                {child.text}
                                            </RichSelectGroup>
                                        );
                                    } else {
                                        const value = child.value as any;
                                        const isSelected = child.selected ?? values.includes(value);

                                        return (
                                            <RichSelectItem
                                                key={index}
                                                isCheckbox={multiple === true}
                                                isSelected={isSelected}
                                                value={child.value}
                                                onClick={() => {
                                                    if (onClick) {
                                                        onClick(value, isSelected);
                                                    } else if (multiple) {
                                                        onChange(
                                                            isSelected
                                                                ? without(values, value)
                                                                : values.concat(value)
                                                        );
                                                    } else {
                                                        onChange(isSelected ? [] : [value]);
                                                        setIsOpen(false);
                                                    }
                                                }}
                                            >
                                                {child.text}
                                            </RichSelectItem>
                                        );
                                    }
                                })}
                            </Box>
                        </Flex>

                        {isCreateVisible && (
                            <Flex
                                css={{
                                    background: '$gray50',
                                    boxShadow:
                                        '0px -1px 3px rgba(16, 24, 40, 0.1), 0px -1px 2px rgba(16, 24, 40, 0.06)',
                                    color: '$primary700',
                                    cursor: 'pointer',
                                    padding: '$3 $4',
                                    userSelect: 'none'
                                }}
                                width={1}
                                onClick={() => {
                                    onCreateClick?.();

                                    setIsOpen(false);
                                }}
                            >
                                + {createText || translate('cr_er_82895')}
                            </Flex>
                        )}

                        {isSelectAllVisible && (
                            <Flex
                                css={{
                                    background: '$gray50',
                                    boxShadow:
                                        '0px -1px 3px rgba(16, 24, 40, 0.1), 0px -1px 2px rgba(16, 24, 40, 0.06)',
                                    color: '$primary700',
                                    cursor: 'pointer',
                                    padding: '$3 $4',
                                    userSelect: 'none'
                                }}
                                width={1}
                                onClick={onSelectAllClick}
                            >
                                {isAllSelected
                                    ? translate('tout_d_s_lectio_37372')
                                    : translate('tout_s_lectionn_48027')}
                            </Flex>
                        )}
                    </Flex>
                </Menu>
            </Dropdown>

            <Hint state={state}>{hint}</Hint>
        </Flex>
    );
};
