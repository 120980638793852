import { useParams } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { OrganizationLogo } from '../organizationLogo';
import { OrganizationPublicContextProvider } from '../organizationPublicContext';
import { AuthForm } from './authForm';
import { AuthContainer, authInnerCss, AuthSkeleton } from './authSkeleton';

interface IAuthProps {
    loadDelegationsSpace: boolean;
    loadMembersSpace: boolean;
}

export const Auth = (props: IAuthProps) => {
    const { organizationId } = useParams();

    return (
        <OrganizationPublicContextProvider
            loadDelegationsSpace={props.loadDelegationsSpace}
            loadMembersSpace={props.loadMembersSpace}
            organizationId={organizationId}
            skeleton={<AuthSkeleton />}
        >
            <AuthContainer>
                <OrganizationLogo />

                <AuthForm organizationId={organizationId} css={authInnerCss} />
            </AuthContainer>
        </OrganizationPublicContextProvider>
    );
};
