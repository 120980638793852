import {
    commonServicesContainerModule,
    inputsContainerModule,
    vosContainerModule
} from 'common/src/commonContainer';
import { TranslationService } from 'common/src/services/translationService';
import { fromString } from 'common/src/vo/supportedLanguage';
import { Container } from 'inversify';

export function getCommonFrontContainer(): Container {
    const container = new Container();
    const HEAVENT_TRANSLATIONS: { [key: string]: string } =
        (window as any).HEAVENT_TRANSLATIONS || {};
    const language = (window as any).HEAVENT_LANGUAGE;

    if (Object.keys(HEAVENT_TRANSLATIONS).length === 0) {
        throw new Error(`window.HEAVENT_TRANSLATIONS does not exist`);
    }

    if (!fromString(language)) {
        throw new Error(`window.HEAVENT_LANGUAGE is not valid`);
    }

    container
        .bind<TranslationService>(TranslationService)
        .toConstantValue(new TranslationService(fromString(language)!, HEAVENT_TRANSLATIONS));

    container.load(commonServicesContainerModule, inputsContainerModule, vosContainerModule);

    return container;
}
