import { Flex } from 'common/src/designSystem/components/flex';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

interface IAccreditationsCategoriesProps {
    children: React.ReactNode;
    css?: CSS;
}

export const AccreditationsCategories = (props: IAccreditationsCategoriesProps) => (
    <Flex
        css={{
            background: '$gray50',
            overflowX: 'auto',
            padding: '$6',
            ...(props.css as any)
        }}
        direction="column"
        gap="4"
        height={1}
        width={1}
    >
        {props.children}
    </Flex>
);
