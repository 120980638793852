import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useUniqueIds } from '../../../hooks/useUniqueIds';
import { ITextInputProps } from '../input/commonInputProps';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { StyledInputContainer } from '../input/styledInputContainer';

export const TimeInput = ({
    value: _value,
    css,
    label,
    description,
    hint,
    icon,
    onChange,
    state,
    ...rest
}: Omit<ITextInputProps, 'type'>) => {
    const value = isNonEmptyString(_value) && _value.length > 5 ? _value.substring(0, 5) : _value;
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={css} direction="column" width={1}>
            <Label htmlFor={inputId}>{label}</Label>

            <Description id={descId}>{description}</Description>

            {(label || description) && <Spacer height="1" />}

            <StyledInputContainer cursor="default" icon={icon} state={state}>
                <Box
                    css={{
                        flex: '1',
                        height: '100%',
                        '& input': {
                            background: 'transparent',
                            border: 'none',
                            color: '$gray800',
                            height: '100%',
                            outline: 'none',
                            width: '100%'
                        }
                    }}
                >
                    <input
                        aria-describedby={description ? descId : undefined}
                        aria-errormessage={state === 'error' ? errorId : undefined}
                        aria-invalid={state === 'error'}
                        disabled={state === 'disabled'}
                        id={inputId}
                        type="time"
                        value={value}
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                        {...rest}
                    />
                </Box>
            </StyledInputContainer>

            <Hint id={errorId} state={state}>
                {hint}
            </Hint>
        </Flex>
    );
};
