import * as yup from 'yup';
import { CustomSlotInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export class CustomSlotInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    customSlotInputDefault(): CustomSlotInput {
        return {
            name: '',
            startTime: '',
            endTime: ''
        };
    }

    customSlotInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.translationService.translate('le_nom_du_cr_ne_97703')),
            startTime: yup
                .string()
                .required(this.translationService.translate('le_d_but_du_cr_40745')),
            endTime: yup
                .string()
                .required(this.translationService.translate('la_fin_du_cr_ne_90351'))
        });
    }
}
