import { injectable } from 'inversify';
import * as yup from 'yup';
import { ALL_MASSASSIGNSTRATEGY, MassConditionsInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IMassConditionsValues {
    massConditions: MassConditionsInput;
}

@injectable()
export class MassConditionsInputService extends InputService {
    constructor(protected translationService: TranslationService) {
        super(translationService);
    }

    massConditionsStep1Schema() {
        return yup.object().shape({
            massConditions: yup.object().shape({
                ...this.massConditionsStep1Fields()
            })
        });
    }

    massConditionsStep2Schema() {
        return yup.object().shape({
            massConditions: yup.object().shape({
                ...this.massConditionsStep2Fields()
            })
        });
    }

    massConditionsSchema() {
        return yup.object().shape({
            ...this.massConditionsStep1Fields(),
            ...this.massConditionsStep2Fields()
        });
    }

    private massConditionsStep1Fields() {
        return {
            strategy: yup
                .string()
                .required(this.t('la_strat_gie_es_82287'))
                .oneOf(ALL_MASSASSIGNSTRATEGY, this.t('la_strat_gie_es_82287'))
        };
    }

    private massConditionsStep2Fields() {
        return {
            customFieldId: yup.number().required(this.t('le_champ_person_80074')),
            value: yup.mixed().required(this.t('la_valeur_est_r_70136'))
        };
    }
}
