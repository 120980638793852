import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { theme } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { LoaderSvg } from '../../../components/loader/loader';

interface IImageLoaderProps {
    label: React.ReactNode;
    hint?: string;
}

export const ImageLoader = (props: IImageLoaderProps) => (
    <Flex gap="4" width={1}>
        <Flex
            align="center"
            css={{
                background: '$gray50',
                border: '1px solid $gray200',
                borderRadius: '$1',
                cursor: 'pointer'
            }}
            height={126}
            justify="center"
            width={126}
        >
            <Flex height={64} width={64}>
                <LoaderSvg
                    color={theme.colors.gray900.value}
                    height="64px"
                    strokeWidth="4"
                    width="64px"
                />
            </Flex>
        </Flex>

        <Flex direction="column">
            <Box color="gray700" fontWeight="medium">
                {props.label}
            </Box>

            {props.hint && (
                <>
                    <Spacer height="1" />

                    <Box color="gray400">{props.hint}</Box>
                </>
            )}
        </Flex>
    </Flex>
);
