import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { getRecrewteerPrimaryColors } from '../designSystem/components/stitches';
import {
    ALL_ICON,
    ConditionsOperator,
    Icon,
    MapLayer,
    PositionInput,
    PositionsCategoryId,
    PositionToEditQuery
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { DEFAULT_ZOOM } from '../util/map';
import { InputService } from './inputService';
import { PositionsCustomFieldInputService } from './positionsCustomFieldInput';
import { PositionsSlotInputService } from './positionsSlotInput';

export interface ICreateUpdatePositionValues {
    position: PositionInput;
}

interface IPositionInputDefaultOptions {
    layer: MapLayer;
    startAt: DateTime;
    positionCategoryId?: PositionsCategoryId;
    latitude?: number;
    longitude?: number;
}

@injectable()
export class PositionInputService extends InputService {
    constructor(
        private positionsCustomFieldInputService: PositionsCustomFieldInputService,
        private positionsSlotInputService: PositionsSlotInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    positionInputDefault(
        position: PositionToEditQuery['event']['position'] | null,
        { positionCategoryId, layer, latitude, longitude, startAt }: IPositionInputDefaultOptions
    ): PositionInput {
        let pcId: PositionsCategoryId | null | undefined =
            position?.category.id ?? positionCategoryId;

        if (!Number.isInteger(pcId)) {
            pcId = null;
        }

        return {
            name: position?.name ?? '',
            positionCategoryId: pcId!,
            description: position?.description ?? '',
            acronym: position?.acronym ?? '',
            color: position?.color ?? getRecrewteerPrimaryColors().primary700,
            icon: position?.icon ?? Icon.LocationDot,
            privateNote: position?.privateNote ?? '',
            address: position?.address ?? null,
            latitude: position?.latitude ?? latitude ?? null,
            longitude: position?.longitude ?? longitude ?? null,
            zoom: position?.zoom ?? DEFAULT_ZOOM,
            layer: position?.layer ?? layer,
            tracesIds: position?.tracesIds ?? [],
            slots: position?.slots ?? [
                this.positionsSlotInputService.positionsSlotInputDefault(startAt)
            ],
            conditionsOperator: position?.conditionsOperator ?? ConditionsOperator.And,
            positionsCustomFields: position?.positionsCustomFields ?? [],
            tagsIds: position?.tagsIds ?? [],
            fields: position?.fields ?? {}
        };
    }

    createUpdatePositionSchema() {
        return yup.object().shape({
            position: this.positionInputSchema()
        });
    }

    positionInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.t('le_nom_de_la_mi_74207')),
            positionCategoryId: yup
                .number()
                .required(this.t('la_cat_gorie_es_14008'))
                .typeError(this.t('la_cat_gorie_es_14008'))
                .min(1, this.t('la_cat_gorie_es_14008')),
            acronym: yup.string(),
            color: yup.string().required(this.t('la_couleur_est_95740')),
            icon: yup
                .string()
                .required(this.t('l_ic_ne_est_req_15843'))
                .oneOf(ALL_ICON, this.t('l_ic_ne_est_req_15843')),
            description: yup.string(),
            privateNote: yup.string(),
            address: yup.string().nullable(),
            latitude: yup.number().nullable().typeError(this.t('la_latitude_doi_43118')),
            longitude: yup.number().nullable().typeError(this.t('la_longitude_do_35170')),
            traces: yup.array().of(yup.number()),
            slots: yup
                .array()
                .of(this.positionsSlotInputService.positionsSlotInputSchema())
                .required(this.t('vous_devez_avoi_26563'))
                .min(1, this.t('vous_devez_avoi_26563')),
            positionsCustomFields: yup
                .array()
                .of(this.positionsCustomFieldInputService.positionsCustomFieldInputSchema()),
            tagsIds: yup.array().of(yup.number())
        });
    }
}
