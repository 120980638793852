import { injectable } from 'inversify';
import * as yup from 'yup';
import { CustomDocumentInput, CustomDocumentQuery } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { A4_SIZES } from '../util/pdf';
import { UUID, uuidv4 } from '../util/uuid';
import { DocumentImageElement, DocumentTextElement } from '../vo/documentBuilder';
import { InputService } from './inputService';

@injectable()
export class CustomDocumentInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    customDocumentInputDefault(
        customDocument?: CustomDocumentQuery['event']['customDocument']
    ): CustomDocumentInput {
        return {
            name: customDocument?.name ?? '',
            slug: customDocument?.slug ?? '',
            populationsIds: customDocument?.populationsIds ?? [],
            configuration: customDocument?.configuration ?? this.getConfigurationDefault()
        };
    }

    customDocumentInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.t('le_nom_est_requ_61536')),
            slug: yup
                .string()
                .required(this.t('le_nom_de_la_va_15472'))
                .test(
                    'is-valid',
                    this.t('le_nom_de_la_va_15472'),
                    (slug: string) => slug.match(/^[a-z][a-z0-9]*$/) !== null
                )
        });
    }

    getConfigurationDefault() {
        return {
            height: A4_SIZES['96dpi'].height,
            pages: [
                {
                    id: uuidv4(),
                    elements: []
                }
            ],
            width: A4_SIZES['96dpi'].width
        };
    }

    getImageElementDefault(id: UUID, numberOfElements: number): DocumentImageElement {
        return {
            type: 'image',
            id,
            accreditationsSlotsIds: [],
            borderColor: '#e4e7ec',
            borderRadius: 0,
            borderSize: 0,
            hasConditions: false,
            hasRotate: false,
            height: 100,
            left: 0,
            name: this.t('image_1_30956', numberOfElements + 1),
            positionsSlotsIds: [],
            rotateDegree: 0,
            top: 0,
            width: 100,
            zIndex: 1
        };
    }

    getTextElementDefault(id: UUID, numberOfElements: number): DocumentTextElement {
        return {
            type: 'text',
            id,
            accreditationsSlotsIds: [],
            align: 'start',
            hasBackground: false,
            backgroundColor: '',
            borderColor: '#e4e7ec',
            borderRadius: 0,
            borderSize: 0,
            hasConditions: false,
            hasRotate: false,
            height: 20,
            left: 0,
            name: this.t('texte_1_59540', numberOfElements + 1),
            text: '',
            color: '#1d2939',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 400,
            justify: 'start',
            positionsSlotsIds: [],
            rotateDegree: 0,
            textTransform: 'none',
            top: 0,
            width: 100,
            zIndex: 1
        };
    }
}
