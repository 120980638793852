import { Emptyable } from './util/emptyable';
import { PathComponents } from './util/paths/commonPaths';
import {
    DelegationIdPathParam,
    EventIdPathParam,
    FormIdPathParam,
    OrganizationIdPathParam,
    UserInfoIdPathParam
} from './util/pathsTypes';

/*
 *  Versioning
 *
 *  @todo remove this once the pre-v2 version of the delegation space is removed.
 */
const ORGANIZATION = (id: OrganizationIdPathParam) => `/organization/${id}`;
const ORGANIZATION_V2 = (id: OrganizationIdPathParam) => `/v2/organization/${id}`;

/*
 *  Prefixes
 */
const COMMUNITY_PREFIX = ({ organizationId }: { organizationId: OrganizationIdPathParam }) =>
    `${ORGANIZATION(organizationId)}/community`;
const EVENT_PREFIX = ({
    eventId,
    organizationId
}: {
    organizationId: OrganizationIdPathParam;
    eventId: EventIdPathParam;
}) => `${ORGANIZATION(organizationId)}/event/${eventId}`;

/*
 *  Base Routes
 */
const DELEGATION = ({
    delegationId,
    eventId,
    organizationId
}: {
    organizationId: OrganizationIdPathParam;
    eventId?: Emptyable<EventIdPathParam>;
    delegationId: DelegationIdPathParam;
}) =>
    `${
        eventId ? EVENT_PREFIX({ organizationId, eventId }) : COMMUNITY_PREFIX({ organizationId })
    }/delegation/${delegationId}`;

export const DelegationsPaths = {
    AUTH(id: OrganizationIdPathParam) {
        return `${ORGANIZATION_V2(id)}/delegations/auth`;
    },
    DELEGATIONS_LIST(id: OrganizationIdPathParam) {
        return `${ORGANIZATION_V2(id)}/delegations/list`;
    },
    DELEGATION,
    DELEGATION_EDIT({
        delegationId,
        eventId,
        organizationId
    }: {
        organizationId: OrganizationIdPathParam;
        eventId?: Emptyable<EventIdPathParam>;
        delegationId: DelegationIdPathParam;
    }) {
        return `${DELEGATION({ delegationId, eventId, organizationId })}/${
            PathComponents.ACTIONS.EDIT
        }`;
    },
    MEMBER_CREATE({
        delegationId,
        eventId,
        organizationId,
        formId
    }: {
        organizationId: OrganizationIdPathParam;
        eventId?: Emptyable<EventIdPathParam>;
        delegationId: DelegationIdPathParam;
        formId: FormIdPathParam;
    }) {
        return `${DELEGATION({
            delegationId,
            eventId,
            organizationId
        })}/member/form/${formId}/${PathComponents.ACTIONS.CREATE}`;
    },
    MEMBER_EDIT({
        delegationId,
        eventId,
        organizationId,
        userInfoId,
        formId
    }: {
        organizationId: OrganizationIdPathParam;
        eventId?: Emptyable<EventIdPathParam>;
        delegationId: DelegationIdPathParam;
        userInfoId: UserInfoIdPathParam;
        formId: FormIdPathParam;
    }) {
        return `${DELEGATION({
            delegationId,
            eventId,
            organizationId
        })}/member/${userInfoId}/form/${formId}/edit`;
    },
    DELEGATION_PROFILE({
        delegationId,
        eventId,
        organizationId
    }: {
        organizationId: OrganizationIdPathParam;
        eventId?: Emptyable<EventIdPathParam>;
        delegationId: DelegationIdPathParam;
    }) {
        return `${DELEGATION({ organizationId, eventId, delegationId })}/profile`;
    }
};
