/* eslint-disable react/no-unused-prop-types */
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { Label } from './input/label';

const _Radio = styled('div', {
    background: 'white',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    variants: {
        size: {
            sm: {
                borderRadius: '16px',
                height: '16px',
                width: '16px'
            },
            md: {
                borderRadius: '20px',
                height: '20px',
                width: '20px'
            }
        },
        state: {
            unchecked: {
                border: '1px solid $gray300',
                '&:hover': {
                    border: '1px solid $primary600'
                }
            },
            checked: {
                border: '1px solid $primary600'
            }
        },
        disabled: {
            true: { cursor: 'not-allowed' }
        }
    },
    compoundVariants: [
        {
            size: 'sm',
            state: 'checked',
            css: {
                '& > div': {
                    background: '$primary600',
                    borderRadius: '6px',
                    height: '6px',
                    width: '6px'
                }
            }
        },
        {
            size: 'md',
            state: 'checked',
            css: {
                '& > div': {
                    background: '$primary600',
                    borderRadius: '8px',
                    height: '8px',
                    width: '8px'
                }
            }
        },
        {
            state: 'unchecked',
            disabled: true,
            css: {
                background: '$gray100',
                borderColor: '$gray200',
                '&:hover': {
                    borderColor: '$gray200'
                }
            }
        },
        {
            state: 'checked',
            disabled: true,
            css: {
                background: '$gray100',
                borderColor: '$gray200',
                '& > div': {
                    background: '$gray300 !important'
                }
            }
        }
    ],
    defaultVariants: {
        size: 'sm',
        state: 'unchecked'
    }
});

export type IRadioSize = 'sm' | 'md';

export type IRadioState = 'unchecked' | 'checked';

interface IRadioProps {
    descId?: string;
    labelId?: string;
    inputId?: string;
    tabIndex?: number;
    size?: IRadioSize;
    state?: IRadioState;
    disabled?: boolean;

    onClick(state: IRadioState): void;
}

export const Radio = (props: IRadioProps) => {
    const handler = () => {
        if (props.disabled !== true) {
            if (props.state === 'unchecked' || !props.state) {
                props.onClick('checked');
            } else {
                props.onClick('unchecked');
            }
        }
    };

    return (
        <_Radio
            aria-describedby={props.descId}
            aria-labelledby={props.labelId}
            disabled={props.disabled}
            id={props.inputId}
            role="radio"
            size={props.size}
            state={props.state}
            tabIndex={props.tabIndex ?? 0}
            onClick={handler}
            onKeyDown={(e) => {
                if (e.key === 'Space') {
                    e.preventDefault();
                    handler();
                }
            }}
        >
            {props.state === 'checked' && <div />}
        </_Radio>
    );
};

interface IRadioTextProps extends IRadioProps {
    children: React.ReactNode;
    subtext?: string;
}

export const RadioText = (props: IRadioTextProps) => {
    const { inputId, descId, labelId } = useUniqueIds();
    const hasSubtext = typeof props.subtext === 'string' && props.subtext.length > 0;
    const handler = () => {
        if (props.disabled !== true) {
            if (props.state === 'unchecked' || !props.state) {
                props.onClick('checked');
            } else {
                props.onClick('unchecked');
            }
        }
    };

    return (
        <Flex
            css={{
                cursor: !props.disabled ? 'pointer' : 'not-allowed',
                userSelect: 'none'
            }}
            tabIndex={0}
            onClick={() => {
                handler();
            }}
            onKeyDown={(e) => {
                if (e.key === 'Space') {
                    e.preventDefault();
                    handler();
                }
            }}
        >
            <Flex
                css={{
                    alignItems: 'center',
                    height: props.size === 'md' ? '23px' : '20px'
                }}
            >
                <Radio
                    descId={descId}
                    disabled={props.disabled}
                    inputId={inputId}
                    labelId={labelId}
                    size={props.size}
                    state={props.state}
                    tabIndex={-1}
                    onClick={() => {
                        // do nothing
                    }}
                />
            </Flex>

            <Spacer width="2" />

            <Flex
                css={{
                    flexDirection: 'column',
                    fontSize: props.size === 'md' ? '$3' : '$2',
                    '& div': { cursor: 'pointer !important' }
                }}
            >
                <Label htmlFor={inputId} id={labelId}>
                    <Box
                        color="gray800"
                        css={{
                            color: props.disabled && !hasSubtext ? '$gray300' : '$gray800'
                        }}
                        fontWeight="medium"
                    >
                        {props.children}
                    </Box>
                </Label>

                {props.subtext && (
                    <Box
                        color="gray800"
                        css={{
                            color: '$gray500'
                        }}
                        id={descId}
                    >
                        {props.subtext}
                    </Box>
                )}
            </Flex>
        </Flex>
    );
};
