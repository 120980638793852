import { BaseFlexProps, Flex, pickBaseFlexProps } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FormApi, MutableState, SubmissionErrors, ValidationErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { isEqual, pick } from 'lodash-es';
import * as React from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { FormContext } from './formContext';
import { FormErrors } from './formErrors';

export function forceErrors<FormValues>(_args: any[], state: MutableState<FormValues>) {
    Object.values(state.fields).forEach((field) => {
        field.data = {
            ...field.data,
            forceDisplayError: true
        };
    });
}

const Submit = styled('input', {
    left: '-9999px',
    position: 'absolute',
    top: '-9999px',
    visibility: 'hidden'
});

interface IFormRenderProps<FormValues> extends BaseFlexProps {
    hideErrors?: boolean;
    formRenderProps: FormRenderProps<FormValues>;

    onShowErrors?(): void;
    render(
        props: Pick<
            FormRenderProps<FormValues>,
            | 'dirty'
            | 'errors'
            | 'form'
            | 'handleSubmit'
            | 'invalid'
            | 'submitErrors'
            | 'submitting'
            | 'values'
        >
    ): React.ReactNode;
}

export const FormRender = <FormValues extends {}>(props: IFormRenderProps<FormValues>) => {
    const formRef = React.useRef<HTMLDivElement | null>(null);
    const { setShowErrors } = React.useContext(FormContext);
    const handleSubmit = (
        event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>
    ) => {
        event?.preventDefault?.();

        if (props.formRenderProps.invalid) {
            setShowErrors(true);
            props.formRenderProps.form.mutators.forceErrors();

            setTimeout(() => {
                props.onShowErrors?.();

                if (props.hideErrors !== true && formRef.current) {
                    formRef.current.scrollIntoView();
                    formRef.current.scrollTop = 0;
                }
            }, 100);

            if (
                !props.formRenderProps.hasValidationErrors &&
                props.formRenderProps.hasSubmitErrors
            ) {
                return props.formRenderProps.handleSubmit();
            } else {
                return undefined;
            }
        } else {
            return props.formRenderProps.handleSubmit();
        }
    };

    return (
        <Flex ref={formRef} as="form" onSubmit={handleSubmit} {...pickBaseFlexProps(props)}>
            {props.hideErrors !== true && <FormErrors />}

            <Submit type="submit" value="Submit" />

            {props.render({
                dirty: props.formRenderProps.dirty,
                errors: props.formRenderProps.errors,
                form: props.formRenderProps.form,
                handleSubmit,
                invalid: props.formRenderProps.invalid,
                submitErrors: props.formRenderProps.submitErrors,
                submitting: props.formRenderProps.submitting,
                values: props.formRenderProps.values
            })}
        </Flex>
    );
};

interface IFormProps<FormValues> extends BaseFlexProps {
    hideErrors?: boolean;
    initialValues: FormValues;
    schema?: any;

    initialValuesEqual?(values1: any, values2: any): boolean;
    onShowErrors?(): void;
    validate?(values: FormValues): ValidationErrors | Promise<ValidationErrors> | undefined;
    onSubmit(
        values: FormValues,
        form: FormApi<FormValues>,
        callback?: (errors?: SubmissionErrors) => void
    ): SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined | void;
    render(
        props: Pick<
            FormRenderProps<FormValues>,
            | 'dirty'
            | 'errors'
            | 'form'
            | 'handleSubmit'
            | 'hasValidationErrors'
            | 'invalid'
            | 'submitErrors'
            | 'submitting'
            | 'values'
        >
    ): React.ReactNode;
}

export const Form = <FormValues extends {}>(props: IFormProps<FormValues>) => {
    const validateService = useService(ValidateService);
    const [showErrors, setShowErrors] = React.useState(false);

    return (
        <FinalForm
            initialValues={props.initialValues}
            initialValuesEqual={props.initialValuesEqual ?? isEqual}
            mutators={{
                ...arrayMutators,
                forceErrors
            }}
            render={(formRenderProps) => (
                <FormContext.Provider
                    value={{
                        errors: formRenderProps.errors,
                        showErrors,
                        submitErrors: formRenderProps.submitErrors,
                        setShowErrors
                    }}
                >
                    <FormRender
                        {...pick(props, [
                            'direction',
                            'align',
                            'justify',
                            'wrap',
                            'gap',
                            'css',
                            'height',
                            'width'
                        ])}
                        formRenderProps={formRenderProps}
                        hideErrors={props.hideErrors}
                        render={props.render}
                        onShowErrors={props.onShowErrors}
                    />
                </FormContext.Provider>
            )}
            validate={(values: FormValues) => {
                if (props.schema) {
                    return validateService.validateForForm(props.schema)(values);
                } else if (typeof props.validate === 'function') {
                    return props.validate(values);
                } else {
                    return {};
                }
            }}
            onSubmit={async (
                values: FormValues,
                form: FormApi<FormValues>,
                callback?: (errors?: SubmissionErrors) => void
            ) => {
                const result = await props.onSubmit(values, form, callback);

                if (Object.keys(result || {}).length > 0) {
                    setShowErrors(true);
                }

                return result;
            }}
        />
    );
};
