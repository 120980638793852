import { isNonEmptyString } from './string';

interface IFileInfos {
    path: string;
    name: string;
    extension: string;
}

export function fileInfos(filenameOrUrl: string): IFileInfos {
    let path = filenameOrUrl;

    try {
        path = new URL(filenameOrUrl).pathname;
    } catch {
        // do nothing
    }

    if (isNonEmptyString(path)) {
        const basename = path.split(/[\\/]/).pop()!;
        const lastDotPosition = basename.lastIndexOf('.');
        let name = basename;
        let extension = '';

        if (lastDotPosition >= 0) {
            name = basename.substring(0, lastDotPosition);
            extension = basename.substring(lastDotPosition + 1).toLowerCase();
        }

        return {
            path,
            name,
            extension
        };
    } else {
        return {
            path: '',
            name: '',
            extension: ''
        };
    }
}
