import { injectable } from 'inversify';
import { CustomSlotsPeriodInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { CustomSlotInputService } from './customSlotInput';
import { InputService } from './inputService';

export interface ICreateCustomSlotPeriodValues {
    customSlotPeriod: CustomSlotsPeriodInput;
}

@injectable()
export class CustomSlotPeriodInputService extends InputService {
    constructor(
        translationService: TranslationService,
        private customSlotInputService: CustomSlotInputService
    ) {
        super(translationService);
    }

    customSlotPeriodInputDefault(): CustomSlotsPeriodInput {
        return {
            name: '',
            customSlots: [this.customSlotInputService.customSlotInputDefault()],
            hideNameDates: false,
            displayAsCalendar: false
        };
    }
}
