import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyArray } from 'common/src/util/array';
import { ValidationErrors } from 'final-form';
import { compact, merge, uniq } from 'lodash-es';
import * as React from 'react';
import { Alert } from '../../designSystem/components/alert';
import { FormContext } from './formContext';

function getErrors(errors: ValidationErrors): string[] {
    return uniq(
        Object.values(errors)
            .map((value) => {
                if (typeof value === 'string') {
                    return value;
                } else if (Array.isArray(value)) {
                    return compact(value)
                        .map((v) => getErrors(v))
                        .flat();
                } else {
                    return getErrors(value);
                }
            })
            .flat()
    );
}

export const FormErrors = () => {
    const { errors, submitErrors, showErrors } = React.useContext(FormContext);
    const errorsArray = getErrors(merge({}, errors, submitErrors));

    if (showErrors && isNonEmptyArray(errorsArray)) {
        return (
            <>
                <Alert color="error" leftIcon="circle-exclamation">
                    <Box css={{ paddingLeft: '$6' }} width={1}>
                        {errorsArray.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </Box>
                </Alert>

                <Spacer height="6" />
            </>
        );
    } else {
        return null;
    }
};
