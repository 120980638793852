import { Button } from 'common-front/src/designSystem/components/button';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Form, FormId } from 'common/src/generated/types';
import * as React from 'react';
import { useHeavent } from '../../hooks/useHeavent';

interface IUserUpdateLayoutProps {
    banner?: React.ReactNode;
    children: React.ReactNode;
    extraTabs?: React.ReactNode;
    forms: Array<Pick<Form, 'id' | 'name'>>;
    isLoading: boolean;
    title: string;

    getTabPath(formId: FormId): string;
    onCancel(): void;
    onUpdate(): void;
}

export const UserUpdateLayout = ({
    banner,
    children,
    extraTabs,
    forms,
    getTabPath,
    isLoading,
    onCancel,
    onUpdate,
    title
}: IUserUpdateLayoutProps) => {
    const {
        params: { eventId },
        translate
    } = useHeavent();

    return (
        <Flex css={{ overflow: 'hidden' }} direction="column" height={1} width={1}>
            <Box
                css={{
                    flex: '1',
                    overflow: 'hidden'
                }}
            >
                <Flex
                    align="center"
                    css={{
                        margin: 'auto',
                        overflowY: 'auto'
                    }}
                    direction="column"
                    height={1}
                    width={1}
                >
                    {banner}

                    <Flex
                        css={{
                            padding: '$6 $4',
                            width: '100%',
                            '@tablet': {
                                padding: '$6 $8'
                            },
                            '@desktop': {
                                padding: '$7',
                                width: eventId ? '100%' : '$container'
                            }
                        }}
                        direction="column"
                    >
                        <Box font="gray900 displayXs semiBold">{title}</Box>

                        <Spacer height="6" />

                        <Tabs>
                            <TabList>
                                {forms.map((form) => (
                                    <Tab key={form.id} path={getTabPath(form.id)}>
                                        {form.name}
                                    </Tab>
                                ))}

                                {extraTabs}
                            </TabList>

                            {children}
                        </Tabs>
                    </Flex>
                </Flex>
            </Box>

            <Flex
                css={{
                    boxShadow:
                        '0px 0px 0px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08)',
                    padding: '$4 $7'
                }}
                justify="center"
            >
                <Flex
                    align="center"
                    css={{
                        width: '100%',
                        '@desktop': {
                            width: eventId ? '100%' : '$container'
                        }
                    }}
                    gap="4"
                >
                    <Button isLoading={isLoading} onClick={onUpdate}>
                        {translate('mettre_jour_85141')}
                    </Button>

                    <Box
                        color="gray700"
                        css={{ cursor: 'pointer' }}
                        fontWeight="medium"
                        onClick={onCancel}
                    >
                        {translate('annuler_48254')}
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
