import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { CommonInputProps } from './input/commonInputProps';
import { Description } from './input/description';
import { Hint } from './input/hint';
import { Label } from './input/label';
import { StyledInputContainer } from './input/styledInputContainer';

export interface ITextareaProps
    extends Omit<
            React.TextareaHTMLAttributes<HTMLTextAreaElement>,
            'autoComplete' | 'onBlur' | 'onFocus' | 'onKeyDown' | 'onChange' | 'onRightIconClick'
        >,
        CommonInputProps<HTMLTextAreaElement> {}

export const Textarea = ({
    css,
    label,
    description,
    state,
    icon,
    hint,
    rightIcon,
    rightText,
    onChange,
    value,
    ...rest
}: ITextareaProps) => {
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={css} direction="column" width={1}>
            <Label htmlFor={inputId}>{label}</Label>

            <Description id={descId}>{description}</Description>

            {(label || description) && <Spacer height="1" />}

            <StyledInputContainer
                cursor="text"
                height="fluid"
                icon={icon}
                rightIcon={rightIcon}
                rightText={rightText}
                state={state}
            >
                <Grid
                    css={{
                        flex: '1',
                        minHeight: '100px',
                        py: '$3',
                        '& .replicated-text': {
                            whiteSpace: 'pre-wrap',
                            visibility: 'hidden'
                        },
                        '& textarea': {
                            outline: 'none',
                            overflow: 'hidden',
                            resize: 'none'
                        },
                        '& .replicated-text, & textarea': {
                            background: 'transparent',
                            border: 'none',
                            gridArea: '1 / 1 / 2 / 2',
                            width: '100%'
                        },
                        '& textarea::placeholder': {
                            color: '$gray500',
                            opacity: 1
                        },
                        '& input::-ms-input-placeholder': {
                            color: '$gray500',
                            opacity: 1
                        }
                    }}
                >
                    <textarea
                        aria-describedby={description ? descId : undefined}
                        aria-errormessage={state === 'error' ? errorId : undefined}
                        aria-invalid={state === 'error'}
                        disabled={state === 'disabled'}
                        id={inputId}
                        value={value}
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                        {...rest}
                    />

                    <Box className="replicated-text">{value} </Box>
                </Grid>
            </StyledInputContainer>

            <Hint id={errorId} state={state}>
                {hint}
            </Hint>
        </Flex>
    );
};
