import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { IconBackground } from '../../designSystem/components/iconBackground';
import { Modal } from '../../designSystem/components/modal';

interface IFormMissionDescriptionModalProps {
    description: string;
    name: string;

    onClose(): void;
}

export const FormMissionDescriptionModal = (props: IFormMissionDescriptionModalProps) => {
    const translate = useTranslate();

    return (
        <Modal
            buttons={
                <>
                    <Button onClick={props.onClose}>{translate('fermer_62479')}</Button>
                </>
            }
            size="md"
            onClose={props.onClose}
        >
            <Flex justify="center" width={1}>
                <IconBackground color="primary" icon="circle-exclamation" />
            </Flex>

            <Spacer height="4" />

            <Box font="gray900 textLg medium" textAlign="center" width={1}>
                {translate('informations_su_86120', props.name)}
            </Box>

            <Spacer height="7" />

            {isNonEmptyString(props.description) && (
                <Flex css={{ overflowY: 'auto' }} direction="column" gap="2">
                    <Box font="gray900 textMd semiBold">{translate('description_58935')}</Box>

                    <RichText text={props.description} />
                </Flex>
            )}

            <Spacer height="4" />
        </Modal>
    );
};
