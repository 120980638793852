import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Blank } from '../../../components/blank/blank';
import { Svgs } from '../../../util/assets';

export const DelegationAccreditationsBlank = () => {
    const translate = useTranslate();

    return (
        <Flex
            align="center"
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs'
            }}
            height={1}
            justify="center"
            width={1}
        >
            <Box height={250} width={1}>
                <Blank
                    imageSrc={Svgs.AccreditationsBlank}
                    title={translate('aucune_accr_dit_27643')}
                />
            </Box>
        </Flex>
    );
};
