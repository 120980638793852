import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { Field } from 'react-final-form';
import { CheckboxText as CheckboxTextComponent, ICheckboxSize } from '../components/checkbox';

interface ICheckboxTextProps {
    css?: CSS;
    name: string;
    size?: ICheckboxSize;
    disabled?: boolean;
    children: React.ReactNode;
    subtext?: React.ReactNode;
}

export const CheckboxText = (props: ICheckboxTextProps) => (
    <Field
        name={props.name}
        render={(fieldProps) => {
            const state = fieldProps.input.checked === true ? 'checked' : 'unchecked';

            return (
                <CheckboxTextComponent
                    css={props.css}
                    disabled={props.disabled}
                    size={props.size}
                    state={state}
                    subtext={props.subtext}
                    onClick={() => {
                        fieldProps.input.onChange(!fieldProps.input.checked);
                    }}
                >
                    {props.children}
                </CheckboxTextComponent>
            );
        }}
        type={'checkbox'}
    />
);
