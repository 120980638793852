// see: https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
import { hsl } from 'd3-color';
import { theme } from '../designSystem/components/stitches';

export function getColorByBackgroundColor(backgroundColor: string): 'black' | 'white' {
    try {
        if (backgroundColor[0] !== '#' || backgroundColor.length !== 7) {
            return 'black';
        } else {
            const red = parseInt(backgroundColor.substring(1, 3), 16);
            const green = parseInt(backgroundColor.substring(3, 5), 16);
            const blue = parseInt(backgroundColor.substring(5), 16);

            if (red * 0.299 + green * 0.587 + blue * 0.114 > 150) {
                return 'black';
            } else {
                return 'white';
            }
        }
    } catch {
        return 'black';
    }
}

export function hexToRgba(hex: string, opacity: number): string {
    const offset = hex[0] === '#' ? 1 : 0;
    const red = parseInt(hex.substring(0 + offset, 2 + offset), 16);
    const green = parseInt(hex.substring(2 + offset, 4 + offset), 16);
    const blue = parseInt(hex.substring(4 + offset), 16);
    const finalOpacity = opacity > 1 ? opacity / 100 : opacity;

    return `rgba(${red}, ${green}, ${blue}, ${finalOpacity})`;
}

const STOPS = [0, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950, 1000];
const DEFAULT_STOP = 700;
const DEFAULT_PALETTES = Object.fromEntries(
    Object.keys(theme.colors)
        .filter((color) => color.includes('700'))
        .flatMap((color) => {
            const finalColor = color.substring(0, color.length - 3);
            const palette = {
                '50': (theme.colors as any)[`${finalColor}50`].value,
                '100': (theme.colors as any)[`${finalColor}100`].value,
                '500': (theme.colors as any)[`${finalColor}500`].value,
                '700': (theme.colors as any)[`${finalColor}700`].value
            };

            return [
                [finalColor, palette],
                [palette['700'], palette]
            ];
        })
);

const createDistributionValues = (lightness: number, stop: number = DEFAULT_STOP) =>
    STOPS.map((currentStop) => {
        if (currentStop === 0) {
            return { stop: currentStop, tweak: 100 };
        } else if (currentStop === 1000) {
            return { stop: currentStop, tweak: 0 };
        } else if (currentStop === stop) {
            return { stop: currentStop, tweak: lightness };
        } else {
            const diff = Math.abs((currentStop - stop) / 100);
            const totalDiff =
                currentStop < stop
                    ? Math.abs(STOPS.indexOf(stop) - STOPS.indexOf(STOPS[0])) - 1
                    : Math.abs(STOPS.indexOf(stop) - STOPS.indexOf(STOPS[STOPS.length - 1])) - 1;
            const increment = currentStop < stop ? 100 - lightness : lightness;

            const tweak =
                currentStop < stop
                    ? (increment / totalDiff) * diff + lightness
                    : lightness - (increment / totalDiff) * diff;

            return { stop: currentStop, tweak: Math.round(tweak) };
        }
    });

interface IGetColorPaletteResult {
    '50': string;
    '100': string;
    '500': string;
    '700': string;
}

export function getColorPalette(
    color: string,
    stop: number = DEFAULT_STOP
): IGetColorPaletteResult {
    const defaultPalette = DEFAULT_PALETTES[color.toLowerCase()];

    if (defaultPalette) {
        return defaultPalette;
    } else {
        const hslColor = hsl(color);
        const distributionScale = createDistributionValues(hslColor.l * 100, stop);

        return Object.fromEntries(
            distributionScale.map(({ stop, tweak }) => [
                stop,
                hslColor.copy({ l: tweak / 100 }).formatHex()
            ])
        ) as any;
    }
}
