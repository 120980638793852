import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import {
    ALL_LANGUAGE,
    ALL_SEX,
    CustomFieldWithConditionFragment,
    EventId,
    FieldProperty,
    FieldType,
    FilterType,
    OrganizationId,
    SegmentCampaignFragment,
    SegmentCustomFieldFragment,
    SegmentsFoldersSegmentsFragment
} from '../generated/types';
import { CountriesService } from '../services/countriesService';
import { DateTimeService } from '../services/dateTimeService';
import { TranslationService } from '../services/translationService';
import { WithTranslationService } from '../services/withTranslationService';
import { isNonEmptyArray, toArray } from '../util/array';
import { assertUnreachable } from '../util/assertUnreachable';
import { LocaleFormats } from '../util/luxon';
import { isNonEmptyString } from '../util/string';
import { VolunteerDefaultColumns } from './segments/volunteersSegmentsService';
import { USABLE_LANGUAGES } from './supportedLanguage';

export type SegmentFragment = SegmentsFoldersSegmentsFragment['segments'][0];

export type Operator =
    | 'contains'
    | 'notContains'
    | 'is'
    | 'isNot'
    | 'isEmpty'
    | 'isNotEmpty'
    | 'isValid'
    | 'isNotValid'
    | 'greaterThan'
    | 'greaterThanEqual'
    | 'lessThan'
    | 'lessThanEqual'
    | 'overlaps'
    | 'notOverlaps'
    | 'engulfs'
    | 'notEngulfs'
    | 'engulfedBy'
    | 'notEngulfedBy'
    | 'isBefore'
    | 'isAfter'
    | 'between'
    | 'startsWith'
    | 'endsWith';

export type Predicate = {
    slug: string;
    operator: Operator;
    value: any;
    value2?: any;
    valueDate?: {
        day?: number;
        month?: number;
        year?: number;
    };
};

export type Predicates = Predicate[];

export type Columns = string[];

type FilterValue = {
    id: string | number;
    name: string;
};

type FilterValueGroup = {
    name: string;
    values: FilterValue[];
};

export type Filter = {
    color: 'purple' | 'success' | 'warning';
    slug: string;
    name: string;
    values?: FilterValue[];
    valuesGroups?: FilterValueGroup[];
    fieldType: FilterType;
    hideEmpty?: boolean;
    startAt?: DateTime;
    endAt?: DateTime;
    campaigns?: SegmentCampaignFragment[];
    needLoading?: boolean;
    eventId?: EventId;
    organizationId?: OrganizationId;
};

export type FilterPredicate = {
    filter: Filter;
    predicate?: Predicate;
};

export type PossibleColumn = {
    slug: VolunteerDefaultColumns | string;
    name: string;
    customField?: SegmentCustomFieldFragment | CustomFieldWithConditionFragment;
    isSearchable?: boolean;
};

const SORTABLE_FIELD_TYPES = [FieldType.Date, FieldType.Number, FieldType.Text, FieldType.Textarea];
export const SEARCHABLE_FIELD_TYPES = [
    FieldType.Phone,
    FieldType.Select,
    FieldType.Text,
    FieldType.Textarea
];

export function isSortable(field: PossibleColumn) {
    if (field.customField) {
        const cf = field.customField;

        return SORTABLE_FIELD_TYPES.includes(field.customField.fieldType)
            ? true
            : cf.fieldType === FieldType.Select && cf.isSelectV2 && !cf.canSelectMultiple;
    } else {
        return false;
    }
}

export function partitionFiltersPredicates(
    predicates: Predicates,
    filters: Filter[],
    search?: string
): FilterPredicate[][] {
    const selected: FilterPredicate[] = [];
    const others: FilterPredicate[] = [];

    for (const filter of filters) {
        if (!isNonEmptyString(search) || filter.name.toLowerCase().includes(search.toLowerCase())) {
            const predicate = predicates.find(({ slug }) => slug === filter.slug);

            if (predicate) {
                selected.push({ filter, predicate });
            } else {
                others.push({ filter, predicate });
            }
        }
    }

    return [selected, others];
}

function getValues(filter: Filter): FilterValue[] {
    if (isNonEmptyArray(filter.values)) {
        return filter.values;
    } else if (isNonEmptyArray(filter.valuesGroups)) {
        return filter.valuesGroups.flatMap((vg) => vg.values);
    } else {
        return [];
    }
}

@injectable()
export class SegmentService extends WithTranslationService {
    constructor(
        protected countriesService: CountriesService,
        protected dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    filterPredicateToText(filterPredicate: FilterPredicate): string {
        const { filter, predicate } = filterPredicate;

        if (predicate) {
            const dateTime = DateTime.isDateTime(predicate.value)
                ? predicate.value
                : DateTime.fromISO(predicate.value, { zone: 'utc' });
            const value =
                dateTime.isValid &&
                (filter.fieldType === FilterType.DateTimeRange ||
                    filter.fieldType === FilterType.Datetime)
                    ? this.dateTimeService.toLocaleString(dateTime, LocaleFormats.DateTime)
                    : dateTime.isValid && filter.fieldType === FilterType.Date
                      ? this.dateTimeService.toLocaleString(
                            dateTime,
                            LocaleFormats.DateOnly.MonthLong
                        )
                      : predicate.value;
            const dateTime2 = DateTime.isDateTime(predicate.value2)
                ? predicate.value2
                : DateTime.fromISO(predicate.value2, { zone: 'utc' });
            const value2 =
                dateTime2.isValid &&
                (filter.fieldType === FilterType.DateTimeRange ||
                    filter.fieldType === FilterType.Datetime)
                    ? this.dateTimeService.toLocaleString(dateTime2, LocaleFormats.DateTime)
                    : dateTime2.isValid && filter.fieldType === FilterType.Date
                      ? this.dateTimeService.toLocaleString(
                            dateTime2,
                            LocaleFormats.DateOnly.MonthLong
                        )
                      : predicate.value2;

            switch (predicate.operator) {
                case 'contains':
                    if (filter.slug === 'condition') {
                        return this.conditionFilterToString(filterPredicate);
                    } else if (filter.slug === 'wishedPositions') {
                        return this.t(
                            'mission_souhait_38008',
                            value2,
                            this.valueToString(filter, value)
                        );
                    } else if (filter.slug === 'wishedPositionsCategories') {
                        return this.t(
                            'cat_gorie_souha_24744',
                            value2,
                            this.valueToString(filter, value)
                        );
                    } else {
                        return this.t('_1_contient_2_40552', filter.name, value);
                    }
                case 'notContains':
                    return this.t('_1_ne_contient_14612', filter.name, value);
                case 'is':
                    if (filter.slug === 'isLeader') {
                        return this.t('est_responsable_19679');
                    } else if (filter.slug === 'isPositionLeader') {
                        return this.t('est_responsable_27270');
                    } else if (filter.slug === 'isPositionCategoryLeader') {
                        return this.t('est_responsable_72923');
                    } else if (filter.slug === 'privacy') {
                        return this.t('visibilit_est_12738');
                    } else if (filter.slug === 'campaign') {
                        return this.campaignFilterToString(filterPredicate);
                    } else if (filter.slug === 'condition') {
                        return this.t('champ_dont_un_a_44854');
                    } else if (filter.fieldType === FilterType.Date) {
                        return this.t(
                            '_1_est_gal_43375',
                            filter.name,
                            this.valueDateToString(predicate.valueDate)
                        );
                    } else {
                        return this.t(
                            '_1_est_gal_43375',
                            filter.name,
                            this.valueToString(filter, value)
                        );
                    }
                case 'isNot':
                    if (filter.slug === 'isLeader') {
                        return this.t('n_est_pas_respo_55625');
                    } else if (filter.slug === 'privacy') {
                        return this.t('visibilit_est_55411');
                    } else if (filter.slug === 'condition') {
                        return this.t('champ_d_pendant_33676');
                    } else if (filter.fieldType === FilterType.Date) {
                        return this.t(
                            '_1_n_est_pas_38478',
                            filter.name,
                            this.valueDateToString(predicate.valueDate)
                        );
                    } else {
                        return this.t(
                            '_1_n_est_pas_38478',
                            filter.name,
                            this.valueToString(filter, value)
                        );
                    }
                case 'isEmpty':
                    return this.t('_1_est_vide_08225', filter.name);
                case 'isNotEmpty':
                    return this.t('_1_n_est_pas_v_31530', filter.name);
                case 'isValid':
                    return this.t('_1_est_valide_45209', filter.name);
                case 'isNotValid':
                    return this.t('_1_n_est_pas_v_05077', filter.name);
                case 'greaterThan':
                    return this.t('_1_est_plus_gr_93527', filter.name, predicate.value);
                case 'greaterThanEqual':
                    return this.t('_1_est_gal_ou_95771', filter.name, predicate.value);
                case 'lessThan':
                    return this.t('_1_est_plus_pe_18306', filter.name, predicate.value);
                case 'lessThanEqual':
                    return this.t('_1_est_gal_ou_39305', filter.name, predicate.value);
                case 'overlaps':
                    return this.t('_1_chevauche_45834', filter.name, value, value2);
                case 'notOverlaps':
                    return this.t('_1_ne_chevauch_01846', filter.name, value, value2);
                case 'engulfs':
                    return this.t('_1_engloutis_32228', filter.name, value, value2);
                case 'notEngulfs':
                    return this.t('_1_n_engloutis_52951', filter.name, value, value2);
                case 'engulfedBy':
                    return this.t('_1_est_englout_36064', filter.name, value, value2);
                case 'notEngulfedBy':
                    return this.t('_1_n_est_pas_e_89161', filter.name, value, value2);
                case 'isBefore':
                    return this.t('_1_est_avant_l_83271', filter.name, value);
                case 'isAfter':
                    return this.t('_1_est_apr_s_l_63712', filter.name, value);
                case 'between':
                    return this.t('_1_est_entre_39871', filter.name, value, value2);
                case 'startsWith':
                    return this.t('_1_commence_pa_47720', filter.name, value);
                case 'endsWith':
                    return this.t('_1_finit_par_65840', filter.name, value);
                default:
                    return assertUnreachable(predicate.operator);
            }
        } else {
            return '';
        }
    }

    protected getValues(customField: SegmentCustomFieldFragment) {
        if (customField.fieldType === FieldType.Sex) {
            return ALL_SEX.map((sex) => ({
                id: sex,
                name: this.t(sex)
            }));
        } else if (customField.fieldType === FieldType.Language) {
            if (customField.fieldProperty === FieldProperty.Language) {
                return USABLE_LANGUAGES.map((language) => ({
                    id: language,
                    name: this.t(language)
                }));
            } else {
                return ALL_LANGUAGE.map((language) => ({
                    id: language,
                    name: this.t(language)
                }));
            }
        } else if (customField.fieldType === FieldType.Nationality) {
            return this.countriesService.nationalitiesIdName;
        } else if (customField.fieldType === FieldType.Country) {
            return this.countriesService.countriesIdName;
        } else if (
            customField.fieldType === FieldType.Select &&
            isNonEmptyArray(customField.values)
        ) {
            return customField.values.map(({ id, value }) => ({
                id,
                name: value
            }));
        } else {
            return [];
        }
    }

    protected valueToString(filter: Filter, value: any) {
        const values = getValues(filter);

        if (isNonEmptyArray(values)) {
            return toArray(value)
                .map((v) => this.t(values.find(({ id }) => id === v)?.name ?? v))
                .join(` ${this.t('ou_67404')} `);
        } else {
            return this.t(value);
        }
    }

    protected valueDateToString(value: any) {
        const parts = [];

        if (typeof value?.day === 'number' && value.day !== -1) {
            parts.push(value.day);
        }

        if (typeof value?.month === 'number' && value.month !== -1) {
            parts.push(
                this.dateTimeService.toLocaleString(DateTime.now().set({ month: value.month }), {
                    month: 'long'
                })
            );
        }

        if (typeof value?.year === 'number' && value.year !== -1) {
            parts.push(value.year);
        }

        return parts.join(' ');
    }

    protected conditionFilterToString(filterPredicate: FilterPredicate) {
        if (filterPredicate.predicate) {
            const fieldsNames = filterPredicate.predicate.value.map(
                (id: number) => filterPredicate.filter.values?.find((v) => v.id === id)?.name ?? ''
            );

            if (fieldsNames.length === 1) {
                return this.t('d_pend_du_champ_43516', fieldsNames[0]);
            } else {
                return this.t('d_pend_des_cham_85348', fieldsNames.join(', '));
            }
        } else {
            return '';
        }
    }

    protected campaignFilterToString(filterPredicate: FilterPredicate) {
        if (filterPredicate.predicate) {
            const campaignsNames = filterPredicate.predicate.value
                .map(
                    (id: number) =>
                        filterPredicate.filter.campaigns?.find((c) => c.id === id)?.name ?? ''
                )
                .join(', ');
            const states = filterPredicate.predicate.value2
                .map((state: string) => this.t(state))
                .join(', ');

            return this.t('a_re_u_un_messa_76546', campaignsNames, states);
        } else {
            return '';
        }
    }

    protected customFieldsToFilters(customFields: SegmentCustomFieldFragment[]): Filter[] {
        return customFields
            .filter(({ fieldType }) =>
                [
                    FilterType.Address,
                    FilterType.Checkbox,
                    FilterType.Country,
                    FilterType.Date,
                    FilterType.Datetime,
                    FilterType.File,
                    FilterType.Language,
                    FilterType.Nationality,
                    FilterType.Number,
                    FilterType.Phone,
                    FilterType.Select,
                    FilterType.Sex,
                    FilterType.Text,
                    FilterType.Textarea,
                    FilterType.Validation
                ].includes(fieldType as any)
            )
            .map(
                (customField) =>
                    ({
                        ...customField,
                        fieldType: customField.fieldType as unknown as FilterType,
                        values: this.getValues(customField),
                        color: 'warning'
                    }) as Filter
            );
    }

    protected customFieldsToPossibleColumns(
        customFields: SegmentCustomFieldFragment[] | CustomFieldWithConditionFragment[]
    ): PossibleColumn[] {
        return customFields.map((customField) => ({
            slug: customField.slug as string,
            name: customField.name,
            customField: customField,
            isSearchable: SEARCHABLE_FIELD_TYPES.includes(customField.fieldType)
        }));
    }
}
