import { FieldProperty } from 'common/src/generated/types';
import { identity } from 'lodash-es';
import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../util/isInvalid';
import {
    AutoComplete,
    IFormInputProps,
    ITextInputProps
} from '../components/input/commonInputProps';
import { TextInput as TextInputComponent } from '../components/textInput';

export const TextInput = ({
    autoComplete,
    hint,
    shouldParseAsInt,
    name,
    state,
    ...rest
}: IFormInputProps<ITextInputProps>) => {
    const getAutocompleteAttribute = (property: string): AutoComplete | undefined => {
        switch (property) {
            case FieldProperty.City:
                // TODO - this relates to town for the UK and similar places,
                // but in some locales (e.g. CH) this should be 'address-level2'.
                // Currently I don't know if we have an easy way of detecting
                // the user's locale.
                // @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#administrative_levels_in_addresses
                return 'address-level1';
            case FieldProperty.DateOfBirth:
                return 'bday';
            case FieldProperty.FirstName:
                return 'given-name';
            case FieldProperty.LastName:
                return 'family-name';
            case FieldProperty.PostalCode:
                return 'postal-code';
            case FieldProperty.Street:
                return 'street-address';
            case FieldProperty.Country:
            case FieldProperty.Language:
            case FieldProperty.Nationality:
            case FieldProperty.Phone:
            case FieldProperty.Picture:
            case FieldProperty.Sex:
            default:
                return;
        }
    };

    return (
        <Field
            name={name}
            parse={identity}
            render={(fieldProps) => {
                const invalid = isInvalid(fieldProps.meta);

                return (
                    <TextInputComponent
                        autoComplete={
                            name.startsWith('formRegister.userInfo.fields.')
                                ? (getAutocompleteAttribute(name.split('.').pop()!) ?? autoComplete)
                                : autoComplete
                        }
                        hint={invalid ? fieldProps.meta.error || fieldProps.meta.submitError : hint}
                        shouldParseAsInt={(shouldParseAsInt || false) as any}
                        state={invalid ? 'error' : state}
                        value={fieldProps.input.value!}
                        onBlur={fieldProps.input.onBlur}
                        onChange={(value) => {
                            fieldProps.input.onChange(value);
                        }}
                        onFocus={fieldProps.input.onFocus}
                        {...rest}
                    />
                );
            }}
        />
    );
};
