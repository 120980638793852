import { IIcon } from '../designSystem/components/i';
import { SortDirection } from '../generated/types';

export type Sort<T> = {
    attribute: T;
    direction: SortDirection;
};

export function getSortIcon<T>(sort: Sort<T> | null, attribute: T): IIcon {
    if (sort === null || sort.attribute !== attribute) {
        return 'arrow-down-arrow-up';
    } else if (sort.direction === SortDirection.Asc) {
        return 'arrow-up';
    } else {
        return 'arrow-down';
    }
}

export function onSort<T>(sort: Sort<T> | null, attribute: T): Sort<T> | null {
    if (sort === null || sort.attribute !== attribute) {
        return { attribute, direction: SortDirection.Asc };
    } else if (sort.direction === SortDirection.Asc) {
        return { attribute, direction: SortDirection.Desc };
    } else {
        return null;
    }
}
