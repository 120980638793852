import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Accreditation as AccreditationVO } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useHeavent } from '../../../hooks/useHeavent';

interface IAccreditationProps<T> {
    accreditation: Pick<AccreditationVO, 'id' | 'name' | 'acronym' | 'hasSlots' | 'hiddenSlotId'> &
        T;
    children: React.ReactNode;
    displaySlots?: boolean;
    numberOfSlots: number;

    renderActions?(): React.ReactNode;
}

export const Accreditation = <T extends {}>(props: IAccreditationProps<T>) => {
    const { translate } = useHeavent();
    const name = React.useMemo(
        () =>
            isNonEmptyString(props.accreditation.acronym)
                ? `${props.accreditation.name} (${props.accreditation.acronym})`
                : props.accreditation.name,
        [props.accreditation]
    );
    const [isOpen, setIsOpen] = React.useState(true);
    const displaySlots = props.displaySlots || props.accreditation.hasSlots;

    return (
        <Flex
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                overflow: 'hidden',
                userSelect: 'none'
            }}
            direction="column"
        >
            <Flex
                align="center"
                css={{
                    cursor: displaySlots ? 'pointer' : 'default',
                    padding: '$3 $4'
                }}
                gap="2"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                {displaySlots && (
                    <Flex
                        align="center"
                        css={{ fontSize: '$textXs' }}
                        height={20}
                        justify="center"
                        width={20}
                    >
                        <I icon={isOpen ? 'chevron-down' : 'chevron-right'} />
                    </Flex>
                )}

                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    <Box font="gray800 textSm semiBold">{name}</Box>

                    {displaySlots && (
                        <Box color="gray500">{translate('creneau', props.numberOfSlots)}</Box>
                    )}
                </Flex>

                {props.renderActions?.()}
            </Flex>

            {displaySlots && isOpen && (
                <Flex css={{ borderTop: '1px solid $gray200' }} direction="column">
                    {props.children}
                </Flex>
            )}
        </Flex>
    );
};
