import { injectable } from 'inversify';
import * as yup from 'yup';
import { TranslationService } from '../services/translationService';
import { AuthInputService } from './authInput';

export interface ISignInCodeValues {
    email: string;
    code: string;
}

@injectable()
export class SignInCodeInputService extends AuthInputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    step1Schema() {
        return yup.object().shape({
            email: this.email()
        });
    }

    step2Schema() {
        return yup.object().shape({
            email: this.email(),
            code: this.code()
        });
    }
}
