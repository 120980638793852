import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { AccreditationsSlotFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import {
    AccreditationState,
    useAccreditationsRightPanelContext
} from './accreditationsRightPanelContext';
import { AccreditationsRightPanelSlotActions } from './accreditationsRightPanelSlotActions';

interface IAccreditationsRightPanelSlotProps {
    slot: AccreditationsSlotFragment;
    state: AccreditationState;
}

export const AccreditationsRightPanelSlot = (props: IAccreditationsRightPanelSlotProps) => {
    const dateTimeService = useService(DateTimeService);
    const { showActions } = useAccreditationsRightPanelContext();

    return (
        <Row>
            <Cell css={{ textTransform: 'capitalize' }}>
                {props.slot.date
                    ? dateTimeService.toLocaleString(
                          props.slot.date,
                          LocaleFormats.DateOnly.WeekdayShortMonthShort
                      )
                    : '-'}
            </Cell>

            <Cell>{isNonEmptyString(props.slot.name) ? props.slot.name : '-'}</Cell>

            {showActions && (
                <AccreditationsRightPanelSlotActions
                    accreditationSlotId={props.slot.id}
                    state={props.state}
                />
            )}
        </Row>
    );
};
