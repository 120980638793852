import { pick } from 'lodash-es';
import { Document, DocumentInput } from '../generated/types';
import { Emptyable } from '../util/emptyable';

export function getS3Url(document: Pick<Document, 'key'>, bucket: string): string {
    return `https://${bucket}.s3-eu-west-1.amazonaws.com/${document.key}`;
}

export function toInput(document: Emptyable<Document | DocumentInput>): DocumentInput | null {
    if (document) {
        return pick(document, ['acl', 'key', 'name', 'size']) as DocumentInput;
    } else {
        return null;
    }
}
