import * as React from 'react';
import { Fields } from '../../components/form/fields';
import { isInvalid } from '../../util/isInvalid';
import { IFormInputProps } from '../components/input/commonInputProps';
import { IPhoneInputProps, PhoneInput as PhoneInputComponent } from '../components/phoneInput';

interface IPhoneInputFormProps extends Omit<IFormInputProps<IPhoneInputProps>, 'name'> {
    prefix: string;
}

export const PhoneInput = ({ prefix, hint, state, ...rest }: IPhoneInputFormProps) => {
    const country = `${prefix}country`;
    const code = `${prefix}code`;
    const _number = `${prefix}number`;

    return (
        <Fields
            names={[country, code, _number]}
            render={(fieldsProps) => {
                const invalid =
                    isInvalid(fieldsProps[code].meta) || isInvalid(fieldsProps[_number].meta);

                return (
                    <PhoneInputComponent
                        hint={
                            invalid
                                ? fieldsProps[code].meta.error || fieldsProps[_number].meta.error
                                : hint
                        }
                        state={invalid ? 'error' : state}
                        value={{
                            country: fieldsProps[country].input.value,
                            code: fieldsProps[code].input.value,
                            number: fieldsProps[_number].input.value
                        }}
                        onChange={(newPhone) => {
                            fieldsProps[country].input.onChange(newPhone.country);
                            fieldsProps[code].input.onChange(newPhone.code);
                            fieldsProps[_number].input.onChange(newPhone.number);
                        }}
                        {...rest}
                    />
                );
            }}
        />
    );
};
