import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { BlankRow } from '../../table/blankRow';
import { AccreditationsCategories } from '../v2/accreditationsCategories';
import { AccreditationsRightPanelCategory } from './accreditationsRightPanelCategory';
import {
    AccreditationState,
    useAccreditationsRightPanelContext
} from './accreditationsRightPanelContext';

interface IAccreditationsRightPanelAssignedTabProps {
    state: AccreditationState;
}

export const AccreditationsRightPanelAssignedTab = (
    props: IAccreditationsRightPanelAssignedTabProps
) => {
    const translate = useTranslate();
    const { assignedCategories, wishedCategories } = useAccreditationsRightPanelContext();
    const categories = props.state === 'assigned' ? assignedCategories : wishedCategories;

    return (
        <AccreditationsCategories>
            {isNonEmptyArray(categories) ? (
                categories.map((category) => (
                    <AccreditationsRightPanelCategory
                        key={category.id}
                        category={category}
                        state={props.state}
                    />
                ))
            ) : (
                <BlankRow title={translate('no_accreditations')} />
            )}
        </AccreditationsCategories>
    );
};
