import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { styled } from 'common/src/designSystem/components/stitches';
import { isNonEmptyString } from 'common/src/util/string';
import { getS3Url } from 'common/src/vo/document';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { FileViewer } from '../../../components/fileViewer/fileViewer';
import { FullScreenPopup } from '../../../components/fullScreenPopup/fullScreenPopup';
import { AWS_UPLOADS_BUCKET } from '../../../consts';
import { useMobileQuery } from '../../../hooks/useMobileQuery';
import { Acl, download } from '../../../util/file';
import { FileS3InputValue } from './fileS3InputValue';

const Filename = styled(Box, {
    flex: 1,
    fontWeight: '$regular',
    color: '$gray800',
    minHeight: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@tablet': {
        color: '$gray700'
    }
});

interface IFileProps {
    acl: Acl;
    document: FileS3InputValue;

    onDelete?(): void;
}

export const File = (props: IFileProps) => {
    const [isFileViewOpen, setIsFileViewOpen] = React.useState(false);
    const onMobile = useMobileQuery();
    const canViewDownload = props.acl === 'public-read' || isNonEmptyString(props.document.url);
    const url = isNonEmptyString(props.document.url)
        ? props.document.url
        : getS3Url(props.document, AWS_UPLOADS_BUCKET);

    return (
        <Flex
            align="center"
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs',
                fontSize: '$textSm',
                padding: '$3',
                minHeight: '72px',
                '@tablet': {
                    border: '1px solid $gray300',
                    borderRadius: '$2',
                    fontSize: '$textMd',
                    padding: '$3'
                }
            }}
            gap="4"
            width={1}
            {...(onMobile && {
                onClick: () => {
                    download(url, props.document.name);
                }
            })}
        >
            <Filename>{props.document.name}</Filename>

            {canViewDownload && (
                <>
                    {onMobile === false && (
                        <Box
                            css={{ cursor: 'pointer' }}
                            onClick={() => {
                                setIsFileViewOpen(true);
                            }}
                        >
                            <I icon="eye" />
                        </Box>
                    )}

                    <Box
                        css={{ cursor: 'pointer' }}
                        {...(onMobile === false && {
                            onClick: () => {
                                download(url, props.document.name);
                            }
                        })}
                    >
                        <I icon="download" />
                    </Box>
                </>
            )}

            {props.onDelete && (
                <Box css={{ cursor: 'pointer' }} onClick={props.onDelete}>
                    <I icon="trash-can" />
                </Box>
            )}

            {isFileViewOpen &&
                createPortal(
                    <Flex
                        css={{
                            background: 'white',
                            left: '0',
                            position: 'absolute',
                            top: '0',
                            zIndex: '250'
                        }}
                        height={1}
                        width={1}
                    >
                        <FullScreenPopup
                            title={props.document.name}
                            onClose={() => {
                                setIsFileViewOpen(false);
                            }}
                        >
                            <FileViewer fileUrl={url} />
                        </FullScreenPopup>
                    </Flex>,
                    document.body
                )}
        </Flex>
    );
};
