import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { IInputState } from './state';

interface IHintProps {
    id?: string;
    state?: IInputState;
    children?: React.ReactNode;
}

export const Hint = (props: IHintProps) => {
    if (props.children) {
        return (
            <>
                <Spacer height="1" />

                <Box
                    color={props.state === 'error' ? 'error500' : 'gray500'}
                    fontSize="textXs"
                    id={props.id}
                    role={props.state === 'error' ? 'alert' : undefined}
                >
                    {props.children}
                </Box>
            </>
        );
    } else {
        return null;
    }
};
