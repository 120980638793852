import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

interface IFileProgressProps {
    name: string;
    progress: number;
}

export const FileProgress = (props: IFileProgressProps) => {
    const progress = Math.min(100, Math.max(0, props.progress));

    return (
        <Flex
            css={{
                border: '1px solid $gray300',
                borderRadius: '$2',
                padding: '$4'
            }}
            direction="column"
            width={1}
        >
            <Box color="gray700" fontWeight="regular">
                {props.name}
            </Box>

            <Spacer height="2" />

            <Flex align="center" gap="3" width={1}>
                <Box
                    css={{
                        background: '$gray100',
                        borderRadius: '$1',
                        flex: '1',
                        height: '8px',
                        position: 'relative'
                    }}
                >
                    <Box
                        css={{
                            background: '$success500',
                            borderRadius: '$1',
                            height: '100%',
                            left: 0,
                            position: 'absolute',
                            top: 0,
                            width: `${progress}%`
                        }}
                    />
                </Box>

                <Box color="gray700">{progress}%</Box>
            </Flex>
        </Flex>
    );
};
