import { API_BASE_URL } from '../consts';

export async function reportLog(logs: any) {
    await fetch(`${API_BASE_URL}log/report`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(logs)
    });
}
