import { CommonEnvVars } from 'common/src/envVars';
import { OrganizationId } from 'common/src/generated/types';
import { HeaventEnv } from 'common/src/heaventEnv';
import {
    CLUB_FRANCE_2024,
    STAFF_INSATIABLES_FR,
    thirdPartyDomainToOrganizationId,
    WWW_PARTAGETAPASSION_COM
} from 'common/src/thirdPartyDomains';

export function isPartagetapassionCom(organizationId: OrganizationId) {
    try {
        return (
            CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer &&
            location.host === WWW_PARTAGETAPASSION_COM &&
            organizationId === thirdPartyDomainToOrganizationId[WWW_PARTAGETAPASSION_COM]
        );
    } catch {
        return false;
    }
}

export function isStaffInsatiablesFr(organizationId: OrganizationId) {
    try {
        return (
            CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer &&
            location.host === STAFF_INSATIABLES_FR &&
            organizationId === thirdPartyDomainToOrganizationId[STAFF_INSATIABLES_FR]
        );
    } catch {
        return false;
    }
}

export function isClubFrance2024(organizationId: OrganizationId) {
    try {
        return (
            CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer &&
            location.host === CLUB_FRANCE_2024 &&
            organizationId === thirdPartyDomainToOrganizationId[CLUB_FRANCE_2024]
        );
    } catch {
        return false;
    }
}
