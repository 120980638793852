import * as React from 'react';
import { Box } from '../box';
import { CSS } from '../stitches';

export function getRichTextDefaultStyles(baseSelector: string) {
    return {
        [`${baseSelector} p.h-small`]: {
            fontSize: '12px'
        },
        [`${baseSelector} h2`]: {
            fontSize: '20px',
            fontWeight: '$semiBold'
        },
        [`${baseSelector} h3`]: {
            fontSize: '18px',
            fontWeight: '$semiBold'
        },
        [`${baseSelector} h4`]: {
            fontSize: '16px',
            fontWeight: '$semiBold'
        },
        [`${baseSelector} a`]: {
            color: '$primary700'
        },
        [`${baseSelector} blockquote`]: {
            borderLeft: '3px solid $gray200',
            fontStyle: 'italic',
            marginLeft: 0,
            marginRight: 0,
            px: '$4',
            '& p': {
                margin: 0,
                paddingBottom: '$2',
                paddingTop: '$2'
            }
        }
    };
}

interface IRichTextProps {
    css?: CSS;
    text: string;
}

export const RichText = (props: IRichTextProps) => (
    <Box
        className="h-rich-text"
        css={{
            ...getRichTextDefaultStyles('&'),
            '& figure': {
                margin: 'auto',

                '& img': {
                    width: '100%'
                }
            },
            ...(props.css as any)
        }}
        dangerouslySetInnerHTML={{
            __html: props.text
        }}
        width={1}
        onClick={(e) => {
            const target = e.target as HTMLElement;

            if (target) {
                const closestA = target.closest('a');

                if (closestA) {
                    e.preventDefault();

                    window.open(closestA.getAttribute('href')!, '_blank');
                }
            }
        }}
    />
);
