import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

interface IIconBackgroundProps {
    color: 'error' | 'primary' | 'purple' | 'success' | 'warning';
    css?: CSS;
    icon: IIcon;
}

export const IconBackground = (props: IIconBackgroundProps) => (
    <Flex
        align="center"
        css={{
            background: `$${props.color}50`,
            borderRadius: '48px',
            color: `$${props.color}700`,
            ...((props.css as any) || {})
        }}
        height={48}
        justify="center"
        width={48}
    >
        <Flex
            align="center"
            css={{
                background: `$${props.color}100`,
                borderRadius: '32px'
            }}
            height={32}
            justify="center"
            width={32}
        >
            <I icon={props.icon} />
        </Flex>
    </Flex>
);
