import { Container } from 'inversify';
import * as React from 'react';
import { IUseHistory, IUseLocalStorage, IUseLocation, IUseParams } from '../../hooks/types';
import { IUseExecuteQuery, IUseMutation, IUseQuery } from '../graphql/types';

export interface IEnvVars {
    HEAVENT_EXTERNAL_DOMAIN: string;
}

interface IDependenciesContext {
    container: Container;
    useQuery: IUseQuery;
    useMutation: IUseMutation;
    useExecuteQuery: IUseExecuteQuery;
    useParams: IUseParams;
    useHistory: IUseHistory;
    useLocation: IUseLocation;
    useLocalStorage: IUseLocalStorage;
    envVars: IEnvVars;
}

export const DependenciesContext = React.createContext<IDependenciesContext>(
    {} as IDependenciesContext
);

export const DependenciesContextConsumer = DependenciesContext.Consumer;
