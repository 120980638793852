import { capitalize } from 'lodash-es';
import { SupportedLanguage } from '../generated/types';
import { Emptyable } from '../util/emptyable';
import { isNonEmptyString } from '../util/string';

export const USABLE_LANGUAGES = [
    SupportedLanguage.De,
    SupportedLanguage.En,
    SupportedLanguage.Es,
    SupportedLanguage.Fr,
    SupportedLanguage.Nl
];

export function fromString(language?: Emptyable<string>): SupportedLanguage | null {
    if (isNonEmptyString(language)) {
        const supportedLanguage = capitalize(language.trim()) as SupportedLanguage;

        if (USABLE_LANGUAGES.includes(supportedLanguage)) {
            return supportedLanguage;
        } else {
            return null;
        }
    } else {
        return null;
    }
}
