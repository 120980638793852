import {
    AccreditationId,
    AccreditationsCategoryId,
    DelegationId,
    EventId,
    FormId,
    OrganizationId,
    PositionId,
    PositionsCategoryId,
    SegmentId,
    SegmentsFolderId,
    SortDirection,
    TagId
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { IGoogleCoordinates } from 'common/src/util/map';
import Dexie, { Table } from 'dexie';
import { DateTime } from 'luxon';

export type AcronymIcon = 'acronym' | 'icon';

export interface LocalEvent {
    id: EventId;
    accreditationsLimit?: number;
    accreditationsSegmentId?: Emptyable<SegmentId>;
    areAccreditationsSegmentsOpen?: boolean;
    areDelegationsSegmentsOpen?: boolean;
    arePositionsSegmentsOpen?: boolean;
    areVolunteersSegmentsOpen?: boolean;
    'assignment.isLeftPanelOpen'?: boolean;
    'assignment.isRightPanelOpen'?: boolean;
    'assignment.leftPanelDirection'?: SortDirection;
    'assignment.leftPanelSegmentId'?: SegmentId;
    'assignment.filters.positionsCategoriesIds'?: PositionsCategoryId[] | null;
    'assignment.filters.positionsIds'?: PositionId[] | null;
    'assignment.filters.date'?: DateTime | null;
    'assignment.filters.tagsIds'?: TagId[] | null;
    'assignment.filters.segmentId'?: SegmentId | null;
    'assignment.filters.excludeFull'?: boolean;
    'accredit.isLeftPanelOpen'?: boolean;
    'accredit.isRightPanelOpen'?: boolean;
    'accredit.leftPanelDirection'?: SortDirection;
    'accredit.leftPanelSegmentId'?: SegmentId;
    'accredit.leftPanelDelegationId'?: DelegationId;
    'accredit.filters.accreditationsCategoriesIds'?: AccreditationsCategoryId[] | null;
    'accredit.filters.accreditationsIds'?: AccreditationId[] | null;
    'accredit.filters.date'?: DateTime | null;
    'accredit.filters.tagsIds'?: TagId[] | null;
    'accredit.filters.segmentId'?: SegmentId | null;
    'accredit.filters.excludeFull'?: boolean;
    delegationsLimit?: number;
    delegationsSegmentId?: Emptyable<SegmentId>;
    isPositionsMapAlertVisible?: boolean;
    positionBackPath?: string;
    positionCategoryBackPath?: string;
    'positionsMap.center'?: IGoogleCoordinates;
    'positionsMap.zoom'?: number;
    'positionsMap.acronymIcon'?: AcronymIcon;
    positionsLimit?: number;
    positionsSegmentId?: Emptyable<SegmentId>;
    'positionsSlotsAssignVolunteers.applyConditions'?: boolean;
    'positionsSlotsAssignVolunteers.filterAvailabilities'?: boolean;
    'positionsSlotsAssignVolunteers.filterPositions'?: boolean;
    showNotFilled?: boolean;
    volunteersLimit?: number;
    volunteersSegmentId?: Emptyable<SegmentId>;
}

interface LocalForm {
    id: FormId | 'all';
    isOpenInUserOverlay: boolean;
}

export interface LocalOrganization {
    id: OrganizationId;
    areCampaignsSegmentsOpen?: boolean;
    areCustomFieldsSegmentsOpen?: boolean;
    areDelegationsSegmentsOpen?: boolean;
    areEventsSegmentsOpen?: boolean;
    areUsersSegmentsOpen?: boolean;
    areParametersMenuSectionsOpen?: { [name: string]: boolean };
    campaignsLimit?: number;
    campaignsSegmentId?: Emptyable<SegmentId>;
    customFieldsLimit?: number;
    customFieldsSegmentId?: Emptyable<SegmentId>;
    delegationsLimit?: number;
    delegationsSegmentId?: Emptyable<SegmentId>;
    duplicatesColumns?: string[];
    eventsSegmentId?: Emptyable<SegmentId>;
    eventsLimit?: number;
    showNotFilled?: boolean;
    usersLimit?: number;
    usersSegmentId?: Emptyable<SegmentId>;
}

interface LocalSegmentFolder {
    id: SegmentsFolderId;
    isOpen?: boolean;
}

class DexieRecrewteer extends Dexie {
    events!: Table<LocalEvent>;
    forms!: Table<LocalForm>;
    organizations!: Table<LocalOrganization>;
    segmentsFolders!: Table<LocalSegmentFolder>;

    constructor() {
        super('recrewteer');

        this.version(6).stores({
            events: 'id',
            forms: 'id',
            organizations: 'id',
            segmentsFolders: 'id'
        });
    }
}

export const localDatabase = new DexieRecrewteer();
