import * as React from 'react';

interface ITooltipContext {
    isVisible: boolean;
    referenceElement: HTMLDivElement | null;
    popperElement: HTMLDivElement | null;

    setIsVisible(isVisible: boolean): void;
    setReferenceElement(node: HTMLDivElement): void;
    setPopperElement(node: HTMLDivElement): void;
}

const TooltipContext = React.createContext<ITooltipContext>({} as ITooltipContext);

interface ITooltipProps {
    children: React.ReactNode;
}

export const Tooltip = (props: ITooltipProps) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);

    return (
        <TooltipContext.Provider
            value={{
                isVisible,
                referenceElement,
                popperElement,
                setIsVisible,
                setReferenceElement,
                setPopperElement
            }}
        >
            {props.children}
        </TooltipContext.Provider>
    );
};

export function useTooltipContext() {
    return React.useContext(TooltipContext);
}
