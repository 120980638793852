import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface ILabelOptionalProps {
    children: React.ReactNode;
}

export const LabelOptional = (props: ILabelOptionalProps) => {
    const translate = useTranslate();

    return (
        <Flex align="center" gap="1">
            {props.children}

            <Box color="gray500" fontSize="textXs" fontWeight="regular">
                ({translate('facultatif_76747')})
            </Box>
        </Flex>
    );
};
